import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { createContext } from "react";
import { UserContext } from "./user";
import { api_url } from "../config";
import { RepUserCustomerContext } from "./users_customers_rep";

export const ApplicationsContext = createContext();

const ApplicationsWrapper = props => {
    const { user, token } = useContext(UserContext);
    const { actions: { getRequiredUsers }} = useContext(RepUserCustomerContext);

    const [viewing_applications, set_viewing_applications] = useState(false);
    const [viewing_app, set_viewing_app] = useState(null);
    const [config, set_config] = useState({offset:0, limit: 30, term: null});
    const [listing, set_listing] = useState([]);
    const [count, set_count] = useState(0);
    const [apps, set_apps] = useState({});

    useEffect(() => {
         if (!user?.is_rep) {
            set_listing([])
            set_apps({})
            set_count(0);
            set_config({offset: 0, limit: 30, term: null});
            set_viewing_app(null)
            set_viewing_applications(false);
         }
    }, [user?.is_rep])

    useEffect(() => {
        if (user?.is_rep) {
            listApps();
        }
    }, [user?.is_rep])

    useEffect(() => {
        const ids = Object.values(apps).filter(x => x.user.first_name === undefined).map(x => x.user);
        getRequiredUsers(ids);
    }, [apps])

    useEffect(() => {
        if (user?.is_rep && listing.length > 0) {
            getRequiredApps(listing)
        }
    }, [listing])

    const getRequiredApps = (ids) => {
        const need = ids.filter(x => !(x in apps));
        getApps(need);
    }

    const getApps = async (ids) => {
        if (ids.length === 0) return;

        const url = `${api_url}/r/applications/get?ids=${ids.join('%2B')}`;
        const res = await fetch(url, {
            headers: {
                'authorization': token ? `Bearer ${token}` : undefined
            }
        });
        if (res.ok) {
            const body = await res.json();
            const new_apps = body.data.data;
            set_apps(apps => ({
                ...apps,
                ...new_apps
            }));
            return new_apps

        } else {
            //const text_body = await res.text();
            return {};
        }
    }

    const getApp = async id => {
        if (id in apps) {
            return apps[id];
        }
        const new_apps = await getApps([id]);
        return new_apps[id]
    }

    const process = async(id, accept) => {
        const res = await fetch(`${api_url}/r/applications/process`, {
            method:'POST',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "authorization": `Bearer ${token}`
            },
            body: JSON.stringify({id, accept})
        });
        const body = await res.json();
        const app = body.data;
        if (app.id === viewing_app?.id) {
            set_viewing_app(app);
        }
        set_apps(a => ({
            ...a,
            [app.id]: app
        }));
        return app;
    }

    const listApps = async() => {
        const url = `${api_url}/r/applications/list?offset=${config?.offset || 0}&limit=${config?.limit || 30}${config?.term ? `&term=${config.term}` : ''}`;
        const res = await fetch(url, {
            method: "GET",
            headers: {
                authorization: `Bearer ${token}`
            }
        }).then(data => data.json());
        set_listing(res.data.ids);
        set_count(res.data.count);
    }

    const listMore = async() => {
        const url = `${api_url}/r/applications/list?offset=${listing.length}&limit=${config?.limit || 30}${config?.term ? `&term=${config.term}` : ''}`;
        const res = await fetch(url, {
            method:"GET",
            headers: {
                authorization: `Bearer ${token}`
            }
        }).then(data => data.json());
        set_listing(listing.concat(res.data.ids));
    }


    const setAppsParams = (params) => {
        set_config({
            ...config,
            ...params
        })
    }

    const setViewingApps = open => set_viewing_applications(open);
    const viewApp = app => set_viewing_app(app);

    return (
        <ApplicationsContext.Provider value={{
            listing,
            apps,
            count,
            config,
            viewing_applications,
            viewing_app,
            actions: {
                setAppsParams,
                setViewingApps,
                viewApp,
                listMore,
                listApps,
                getApp,
                getRequiredApps,
                process
            }
        }}>
            {props.children}
        </ApplicationsContext.Provider>
    )
}

export default ApplicationsWrapper;

import { useContext, useEffect, useState } from "react";
import { useWindowDimensions, View } from "react-native";
import { LanguageContext } from "../contexts/language";
import { UserContext } from "../contexts/user";
import ActionSheet from "./action_sheet";
import Button from "./button";
import TxtInput from "./txt_input";
import * as Colours from '../colours';
import Txt from "./txt";
import { AlertContext } from "../contexts/alert";

//LANGUAGE

const ForgotAs = props => {
    const { forgot_as: lang } = useContext(LanguageContext);
    const { height, width } = useWindowDimensions();
    const { actions: { showForgotModal, forgotPassword }, forgot_modal_showing: visible, user } = useContext(UserContext);
    const [email, set_email] = useState('');
    const [loading, set_loading] = useState(false)
    const { actions: { setAlert }} = useContext(AlertContext);

    const close = () => showForgotModal(false);

    const submit = async () => {
        set_loading(true);
        if (email) {
            const ok = await forgotPassword(email);
            if (ok) {
                setAlert({title: lang.thanks, body: lang.youll_receive, onConfirm: close});
            } else {
                setAlert({title: lang.sorry, body: lang.went_wrong, onConfirm: close});
            }
            
        }
        set_loading(false);
    }

    useEffect(() => {
        if (user) {
            close();
        }
    }, [user])

    return (
        <ActionSheet
            height={height * 0.4}
            close={close}
            visible={visible}
            cred="FORGOT"
            title={lang.forgot_your_password}
        >
            <View style={{flex: 1, alignSelf:'stretch', marginTop: 10, alignItems:'center'}}>
                <View style={{flex: 1}}>
                    <View style={{flex: 1, justifyContent:'flex-start', alignItems:'stretch', alignSelf:'stretch'}}>
                        <Txt style={{marginTop: 20, marginBottom: 20}}>{lang.please_enter}</Txt>
                        <View style={{alignSelf:'stretch'}}>
                            <Txt style={{marginBottom: 2}}>{lang.email_address}</Txt>
                            <TxtInput 
                                value={email} 
                                onChange={set_email}
                                can_clear
                                onClear={() => set_email('')}
                                placeholder={lang.email_address}
                                style={{marginBottom: 10, borderWidth: 1, borderColor:Colours.light_grey, height: 40}}
                                inputStyle={{fontSize: 14}}
                                inputProps={{textContentType:'emailAddress', autoComplete:"email", keyboardType:"email-address"}}
                                />

                        </View>
                    </View>
                    <View style={{alignSelf:'stretch', flexDirection:'column', marginBottom: 30}}>
                        <Button loading={loading} onPress={submit}>{lang.submit}</Button>
                    </View>
                </View>

            </View>
        </ActionSheet>
    )
}

export default ForgotAs;
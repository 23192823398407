import { Platform } from "react-native";
import DateTimePicker from '@react-native-community/datetimepicker';
import { format } from 'date-fns'

const DatePicker = props => {
    const { value, onChange } = props;

    if (Platform.OS === 'web') {
        return (
            <input type="date" value={format(value, 'yyyy-MM-dd')} onChange={e => onChange(e.target.value)} min="2020-01-01" max={format(new Date(), "yyyy-MM-dd")} />
        )
    }
    return (
        <DateTimePicker
            value={value}
            onChange={e => onChange(e.nativeEvent.timestamp)}
            mode="date"
            />
    )
}

export default DatePicker;
import { useContext } from "react";
import { TouchableOpacity, TouchableWithoutFeedback } from "react-native";
import { UserContext } from "../contexts/user";


const RepButton = ({children, onPress, no_feedback}) => {
    const { user } = useContext(UserContext);

    if (!user?.is_rep) return children;
    if (no_feedback) {
        return (
            <TouchableWithoutFeedback onPress={onPress}>
                {children}
            </TouchableWithoutFeedback>
        )
    }
    return (
        <TouchableOpacity onPress={onPress}>
            {children}
        </TouchableOpacity>
    )
}
export default RepButton;
import { useContext, useEffect, useRef } from 'react';
import { useWindowDimensions, View, ScrollView, TouchableOpacity, ActivityIndicator, Platform } from 'react-native';
import ProductImg from './product_img';
import ActionSheet from '../components/action_sheet';
import InfoRow from '../components/info_table';
import { FontAwesome, Ionicons, MaterialCommunityIcons } from '@expo/vector-icons';
import { StoreContext } from '../contexts/store';
import SwipeDown from '../components/swipe_down';
import { LanguageContext } from '../contexts/language';
import * as Colours from '../colours';
import Txt from '../components/txt';
import { UserContext } from '../contexts/user';
import { AlertContext } from '../contexts/alert';
import { useState } from 'react';
import {TempImageContext} from '../contexts/temp_image';
import RepButton from '../components/rep_button';
import { uploadImg } from '../image/upload';
import { KbContext } from '../components/keyboard_wrapper';
import SegmentedControl from '@react-native-segmented-control/segmented-control';
import { api_url } from '../config';

const listed_statused = [{label: "Listed", value: "Listed"}, {label: "Not Listed", value: "NotListed"}, {label: "Policy", value: "WithPolicy"}];

const IsListed = props => {
    const { product } = props;
    const { user } = useContext(UserContext);
    const [listed, set_listed] = useState(product?.listed)
    const { actions : { pushProduct }} = useContext(StoreContext);

    useEffect(() => set_listed(product?.listed), [product?.listed]);

    const change = e => {
        const new_label = e.nativeEvent.value
        const new_value = listed_statused.find(x => x.label === new_label).value;
        set_listed(new_value)
        pushProduct({id: product.id, listed: new_value});
    }

    if (!user?.is_rep) return null
    const index = listed_statused.map(x => x.value).indexOf(listed);
    return (
        <SegmentedControl
            values={listed_statused.map(x => x.label)}
            selectedIndex={index}
            onChange={change}
            style={{marginBottom: 30}}
        />
    )
}

export const AmbientButton = props => {
    const { explain_as: lang } = useContext(LanguageContext)
    const { color: ambient, onPress } = props;

    return (
        <RepButton onPress={onPress}>
            <View style={{flexDirection:'row', alignItems:'center'}}>
                <FontAwesome name="shopping-basket" size={15} style={{marginRight: 8, color: ambient}} />
                <Txt style={{fontSize: 12, color:ambient}}>{lang.ambient}</Txt>
            </View>
        </RepButton>
    )
}

export const FrozenButton = props => {
    const { explain_as: lang } = useContext(LanguageContext)
    const { color: frozen, onPress } = props;

    return (
        <RepButton onPress={onPress}>
            <View style={{flexDirection:'row', alignItems:'center'}}>
                <Ionicons name="ios-ice-cream" size={18} style={{color:frozen}} />
                <Txt style={{fontSize: 12, color: frozen}}>{lang.frozen}</Txt>
            </View>
        </RepButton>
    )
}

export const SeasonalButton = props => {
    const { explain_as: lang } = useContext(LanguageContext)
    const { color: seasonal, onPress } = props;

    return (
        <RepButton onPress={onPress}>
            <View style={{flexDirection:'row', alignItems:'center'}}>
                <MaterialCommunityIcons name="pine-tree" size={22} style={{color:seasonal}} />
                <Txt style={{fontSize: 12, color:seasonal}}>{lang.seasonal}</Txt>
            </View>
        </RepButton>
    )
}

export const ChilledButton = props => {
    const { explain_as: lang } = useContext(LanguageContext);
    const { color: chilled, onPress } = props;

    return (
        <RepButton onPress={onPress}>
            <View style={{flexDirection:'row', alignItems:'center'}}>
                <MaterialCommunityIcons name="fridge" size={22} style={{color:chilled}} />
                <Txt style={{fontSize: 12, color:chilled}}>{lang.chilled}</Txt>
            </View>
        </RepButton>
    )
}


export const CateringButton = props => {
    const { explain_as: lang } = useContext(LanguageContext)
    const { color: catering, onPress } = props;

    return (
        <RepButton onPress={onPress}>
            <View style={{flexDirection:'row', alignItems:'center'}}>
                <MaterialCommunityIcons name="food" size={18} style={{color:catering}} />
                <Txt style={{fontSize: 12, color: catering}}>{lang.catering}</Txt>
            </View>
        </RepButton>
    )
}


const StorageRow = props => {
    const { product } = props;
    const { actions: { pushProduct } } = useContext(StoreContext);

    const update = (key, value) => {
        const update = {[key]: value ? 'true' : 'false' };
        const instr = {
            id: product.id,
            custom_attribs: {
                ...(product?.custom_attribs || {}),
                ...update
            }
        };
        pushProduct(instr);
    }

    const is_ambient = product?.custom_attribs?.ambient === 'true';
    const is_frozen = product?.custom_attribs?.frozen === 'true';
    const is_catering = product?.custom_attribs?.catering === 'true';
    const is_seasonal = product?.custom_attribs?.seasonal === 'true';
    const is_chilled = product?.custom_attribs?.chilled === 'true';

    const ambient = is_ambient ? Colours.orange : Colours.grey;
    const frozen = is_frozen ? Colours.orange : Colours.grey;
    const seasonal = is_seasonal ? Colours.orange : Colours.grey;
    const catering = is_catering ? Colours.orange : Colours.grey;
    const chilled = is_chilled ? Colours.orange : Colours.grey;

    return (
        <View style={{alignSelf:'stretch', flexDirection:'row', justifyContent:'space-between', alignItems:'center', paddingTop: 10, paddingBottom: 10}}>
            <AmbientButton onPress={() => update('ambient', !is_ambient)} color={ambient} />
            <FrozenButton onPress={() => update("frozen", !is_frozen)} color={frozen} />
            <SeasonalButton onPress={() => update("seasonal", !is_seasonal)} color={seasonal} />
            <CateringButton onPress={() => update("catering", !is_catering)} color={catering} />
            <ChilledButton onPress={() => update("chilled", !is_chilled)} color={chilled} />
        </View>
    )
}

const ImgScrapeButton = props => {
    const [loading, set_loading] = useState(false);
    const { product, onRefresh } = props;
    const { user } = useContext(UserContext);
    const { actions : { setAlert }} = useContext(AlertContext);
    const { actions: { refreshImage } } = useContext(StoreContext);

    const refresh = async (e) => {
        e.stopPropagation(); 
        e.preventDefault();

        if (product?.id) {
            set_loading(true);
            const found = await refreshImage(product.id)
            if (found) {
                onRefresh();
            } else {
                setAlert({
                    title: "Failed to Find Image",
                    body: "Please find manually and upload"
                });
            }
            set_loading(false);
        }
    }

    if (!user?.is_rep) return null;

    return (
        <View style={{position:'absolute', top: 10, right: 10, zIndex: 1000}}>
            {
                loading ? <View style={{padding: 12}}><ActivityIndicator size={24} color={Colours.purple} /></View>
                :
                <TouchableOpacity style={{padding: 12}} onPress={refresh}>
                    <FontAwesome color={Colours.purple} name="refresh" size={24} />
                </TouchableOpacity>
            }
        </View>
    )
}


const ProductAs = props => {
    const { product_as: lang } = useContext(LanguageContext)
    const {viewing_product: prod, cat_data, actions: { viewProd, pushProduct, viewNext, viewProductOffers }} = useContext(StoreContext);
    const { user, token } = useContext(UserContext);
    const { actions: { addRefreshEan }, refresh_eans } = useContext(TempImageContext);
    const { width, height: window_height } = useWindowDimensions();
    const [loading, set_loading] = useState(false);
    const { kb_height } = useContext(KbContext);
    const current_id = useRef(null);

    useEffect(() => { 
        current_id.current = prod?.id;
    }, [prod?.id])

    const img_size = width > 768 ? 380 : width - 80;

    const listenForTab = e => {
        if (e.key === 'Tab') {
            e.preventDefault();
            e.stopPropagation();
            viewNext(current_id.current);
        }
    }

    useEffect(() => {
        if (Platform.OS === 'web') {
            if (prod !== null) {
                window.addEventListener('keydown', listenForTab);
            } else {
                window.removeEventListener('keydown', listenForTab);
            }
        }
    }, [prod])

    const pick_image = async () => {
        if (!user?.is_rep) return
        const ok = await uploadImg(prod.ean, token, () => set_loading(true), () => set_loading(false));
        if (ok) {
            if (prod.ean) {
                addRefreshEan(prod.ean);
            }
        }
    }

    const pick_image_web = async (e) => {
        if (e.target.files.length > 0) {

            const fd = new FormData();
            fd.append('file', e.target.files[0]);
            const res = await fetch(`${api_url}/r/product/push_image_pass/${prod.ean}`, {
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "authorization": `Bearer ${token}`
                },
                body: fd
            });
            set_loading(false);
            if (!res.ok) {

            }
        }
    }

    const viewOffers = () => {
        const p = prod;
        viewProd(null);
        viewProductOffers(p)
    }

    const updateProduct = (updates) => {
        if (user?.is_rep) {
            let instr = {...prod, ...updates};
            pushProduct(instr);
        }
    }
    const can_edit = user?.is_rep || false;

    return (
        <ActionSheet
            height={window_height * 0.9}
            close={() => viewProd(null)}
            visible={prod !== null}
            cred="PROD"
            >
                {
                    prod && 
                    <View style={{alignSelf:'stretch', flex: 1, alignItems:'center', paddingTop: 10}}>
                        <View style={{alignSelf:'stretch', flex: 1}}>
                            <ScrollView keyboardShouldPersistTaps='handled'>
                                {
                                    kb_height === 0 &&
                                    <>
                                    <View style={{alignSelf:'stretch', alignItems:'center'}}>
                                        <ImgScrapeButton onRefresh={() => addRefreshEan(prod.ean)} product={prod} />
                                        <SwipeDown onSwiper={() => viewProd(null)}>
                                            {
                                                Platform.OS !== 'web' ?
                                                <RepButton no_feedback onPress={pick_image}>
                                                    <View style={{width: img_size, height: img_size, justifyContent:'center', alignItems:'center'}}>
                                                        <ProductImg loading={loading} product={prod} size={img_size} />
                                                    </View>
                                                </RepButton>
                                                :
                                                <>
                                                    {user?.is_rep && <input onChange={pick_image_web} type="file" name="fu" id="fu" style={{display:'none'}}></input>}
                                                    <label htmlFor="fu">
                                                        <View style={{width: img_size, height: img_size, justifyContent:'center', alignItems:'center'}}>
                                                            <ProductImg loading={loading} product={prod} size={img_size} />
                                                        </View>
                                                    </label>
                                                </>
                                            }
                                        </SwipeDown>
                                    </View>
                                    </>
                                }
                                <Txt style={{fontWeight:'bold', fontSize: 18}}>{prod.description} {prod.case_size}x{prod.size}</Txt>
                                <StorageRow product={prod} />
                                {
                                    
                                    can_edit &&
                                    <InfoRow editable={can_edit} value={prod.description} onChange={v => updateProduct({description: v})} label="Description" />

                                }
                                <InfoRow warning={warnOnGrams} editable={can_edit} value={prod.size} onChange={v => updateProduct({size: v})} label={lang.size} />
                                <InfoRow onChange={v => updateProduct({case_size: v})} validate={validateInt} processValue={v => parseInt(v)} editable={can_edit} label={lang.case} value={`${prod.case_size}`} />
                                <InfoRow onChange={v => updateProduct({pallet: v})} validate={validateInt}  editable={can_edit} label={lang.pallet} value={`${prod.pallet}`} />
                                <InfoRow onChange={v => updateProduct({ean: v})} validate={validateInt}  editable={can_edit} label={lang.ean}  warning={validBarcode} value={prod.ean} />
                                <InfoRow onChange={v => updateProduct({occ: v})} validate={validateInt}  editable={can_edit} label={lang.occ} warning={validBarcode} value={prod.occ} />
                                <InfoRow onChange={v => updateProduct({brand: v})} editable={can_edit} label={lang.brand} value={prod.brand} />
                                <InfoRow onChange={v => updateProduct({manufacturer: v})} editable={can_edit} label={lang.manufacturer} value={prod.manufacturer} />
                                <InfoRow editable={false} label={lang.ref} value={prod.human_ref} />
                                <InfoRow last={!can_edit} label={lang.categories}><Txt>{(prod.categories || []).map(id => cat_data[id]?.name || lang.loading).join(", ")}</Txt></InfoRow>
                                {
                                    can_edit &&
                                    <InfoRow last label="Offers"><Txt onPress={viewOffers} style={{fontWeight:'bold', color: Colours.orange}}>Manage Offers</Txt></InfoRow>
                                }
                                <IsListed product={prod} />
                            </ScrollView>
                        </View>
                    </View>
                }
        </ActionSheet>
    )
}

function isValidBarcode(value) {
    // We only allow correct length barcodes
    if (!value.match(/^(\d{8}|\d{12,14})$/)) {
      return false;
    }
  
    const paddedValue = value.padStart(14, '0');
  
    let result = 0;
    for (let i = 0; i < paddedValue.length - 1; i += 1) {
      result += parseInt(paddedValue.charAt(i), 10) * ((i % 2 === 0) ? 3 : 1);
    }
  
    return ((10 - (result % 10)) % 10) === parseInt(paddedValue.charAt(13), 10);
  }

const validBarcode = barcode => {
    if (!isValidBarcode(barcode)) return "Invalid Barcode"
    return null
}

const replacements = [
    ['gm', 'g'],
    ['litre', 'l'],
    ['liter', 'l'],
    ['ltr', 'l'],
    ['gr', 'g'],
    ['lt','l']
]

const warnOnGrams = s => {
    for (var i=0; i < replacements.length; i++) {
        const parts = replacements[i];
        if (parts) {
            if (s.toLowerCase().trim().endsWith(parts[0])) {
                return `Consider using '${parts[1].toUpperCase()}' rather than '${parts[0].toUpperCase()} for consistency`;
            }
        }
    }
    return null
}

const validateInt = v => {
    if (isNaN(v)) return 'Invalid Number'
    return null;
}

export default ProductAs;
import { useContext, useState } from "react";
import { useWindowDimensions, View, FlatList } from "react-native";
import { UserContext } from "../contexts/user";
import ActionSheet from "./action_sheet";
import SwipeDown from "./swipe_down";
import { OrdersContext } from "../contexts/orders";
import { StoreContext } from "../contexts/store";
import InfoRow from './info_table';
import Button from "./button";
import { format } from 'date-fns'
import { LanguageContext } from "../contexts/language";
import ListRow from "./list_row";
import Txt from "./txt";
import { AlertContext } from "../contexts/alert";
import { Ionicons } from '@expo/vector-icons';
import * as Colours from '../colours';
import { TouchableOpacity } from "react-native-web";


const OrderRow = props => {
    const { order_as: lang } = useContext(LanguageContext);
    const { line, last } = props;
    const { products } = useContext(StoreContext);
    const product = products[line?.product_id] || null;

    return (
            <ListRow
                product_for_image={product}
                title={`${(product?.description || lang.loading).toUpperCase()}`}
                subtitle={`${product?.case_size} x ${(product?.size || lang.loading)}`}
                key_values={[{key: line.quantity, value: lang.cases}, {key: line.p_ov.toFixed(2), value: lang.each}, {key: (line.quantity * line.p_ov).toFixed(2), value: lang.total}]}
                last={last}
                >
                </ListRow>
    )
}

const OrderAs = props => {
    const { order_as: lang } = useContext(LanguageContext);
    const { height, width } = useWindowDimensions();
    const { actions: { viewOrder, resendConfirmation, downloadConfirmation, cancelOrder, showOrdersModal }, viewing_order: order, with_thanks, data } = useContext(OrdersContext)
    const { customers, user } = useContext(UserContext);
    const { actions: { setStoreParams }} = useContext(StoreContext);
    const close = () => viewOrder(null);
    const [download_loading, set_download_loading] = useState(false);
    const [resend_loading, set_resend_loading] = useState(false);
    const [cancel_loading, set_cancel_loading] = useState(false);
    const { actions: { setAlert }} = useContext(AlertContext);

    const browse = () => {
        const product_ids = (order?.lines || []).map(x => x.product_id);
        if (product_ids.length > 0) {
            setStoreParams({offset: 0, term: null, categories: [], codes: product_ids});
        }
    }

    const resend = async () => {
        set_resend_loading(true);
        const { ok, wait } = await resendConfirmation(order.id);
        set_resend_loading(false);
        if (!ok) {
            if (wait) {
                setAlert({title: lang.please_wait, body: `${lang.you_recently} ${wait} ${lang.you_recently_two}`})
            } else {
                setAlert({title: lang.sorry, body: lang.something_went_wrong});
            }
        } else {
            setAlert({title: lang.confirmation_resent, body: lang.we_resent});
        }
    }

    const download = async () => {
        set_download_loading(true);
        await downloadConfirmation(order.id);
        set_download_loading(false)
    }

    const cancel = async () => {
        if (!user?.is_rep) return
        set_cancel_loading(true);
        setAlert({
            title: "Cancel Order?", 
            body: "This will cancel the order, notify the customer, and return any stocked products to open stock",
            onCancel: () => set_cancel_loading(false),
            cancel_text:"Back",
            onConfirm: async () => {
                const result = await cancelOrder(order.id);
                if (result) {
                    set_cancel_loading(false);
                } else {
                    setAlert({title: "Something went wrong", body: "Unable to cancel Order"});
                    set_cancel_loading(false);
                }
            }
        })
    }

    const back = () => {
        viewOrder(null);
        showOrdersModal(true)
    }

    return (
        <ActionSheet
            height={height * 0.9}
            close={close}
            visible={order !== null}
            cred="ORDER"
            back={back}
            title={`${lang.order} ${order?.human_ref} ${lang.for} ${(customers || []).find(x => x.id === order?.customer_id)?.name || lang.unknown_company}`}
        >
            <View style={{flex: 1, alignSelf:'stretch', alignItems:'center'}}>
                {
                    order?.cancelled && <Txt style={{color:'red', fontWeight:'bold'}}>CANCELLED</Txt>
                }
                {
                    order && 
                    <View style={{flex: 1, alignSelf:'stretch'}}>
                        {
                            with_thanks && <View style={{alignSelf:'stretch', margin: 10, backgroundColor:'#57F298', padding: 10, justifyContent:'center', alignItems:'center', borderRadius: 3}}>
                                <Txt style={{fontSize: 20, color:'rgba(50, 50, 50, 1)', fontWeight:'bold', textAlign:'center'}}>{lang.thanks}</Txt>
                            </View>
                        }

                        <View style={{marginTop: 10, marginBottom: 10}}>
                            <InfoRow label="Items"><Txt>{(order?.lines || []).length}</Txt></InfoRow>
                            <InfoRow label="Cases"><Txt>{(order?.lines || []).reduce((a,b) => a + b.quantity, 0)}</Txt></InfoRow>
                            <InfoRow last label="Value"><Txt>€{(order?.lines || []).reduce((a, b) => a + (b.quantity * b.p_ov), 0).toFixed(2)}</Txt></InfoRow>
                        </View>

                        <View style={{flex: 1}}>
                            <Txt style={{fontWeight:'bold', marginBottom: 5, marginTop:10}}>{lang.items}</Txt>
                            <FlatList
                                data={order.lines}
                                keyExtractor={(_, i) => i.toString()}
                                renderItem={({item, index}) => <OrderRow line={item} last={index === order.lines.length -1} />}
                                />
                        </View>
                        <View style={{alignSelf:'stretch', marginBottom: 30, marginTop: 10}}>
                            <Button onPress={browse} style={{height: 40}}>Browse Products</Button>
                            <Button loading={download_loading} onPress={download} style={{height: 40, marginTop: 10}}>{lang.download_confirmation}</Button>
                            {
                                !with_thanks && <Button loading={resend_loading} onPress={resend} style={{height: 40, marginTop: 10}}>{lang.resend_confirmation}</Button>
                            }
                            {
                                user?.is_rep && !order?.cancelled &&
                                <Button loading={cancel_loading} onPress={cancel} style={{backgroundColor:'red', marginTop: 10}}>Cancel Order</Button>
                            }

                        </View>
                    </View>
                }

            </View>
        </ActionSheet>
    )
}

export default OrderAs;
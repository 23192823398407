import { createContext, useContext, useEffect, useRef, useState } from "react";
import { api_url, ws_url } from "../config";
import { OrdersContext } from "./orders";
import { PushContext } from "./push";
import { StoreContext } from "./store";
import { UserContext } from "./user";
import { BasketContext } from "./basket";

export const WsContext = createContext({});

const WsWrapper = props => {
    const { actions: { insertProductWithoutTouchingOffers, getProducts, inject_faves, viewProd }, viewing_product } = useContext(StoreContext);
    const { actions : { getOrders, listOrders }} = useContext(OrdersContext);
    const { actions: { getBasket }} = useContext(BasketContext);
    const { actions: { showToast }} = useContext(PushContext);
    const [ws_token, set_ws_token] = useState(null);
    const { user, token, actions: { set_ws_session } } = useContext(UserContext);
    const refresh_wait = useRef(0);

    const getWsToken = async () => {
        const resp = await fetch(`${api_url}/ws/token`, {
            method:"GET",
            headers: {
                authorization: `Bearer ${token}`
            }
        });
        if (resp.ok) {
            console.log("OK")
            const body = await resp.json();
            set_ws_token(body.data);
        } else {
            console.log("FAILED WS")
        }
    }

    useEffect(() => {
        getWsToken();
    }, [user])

    useEffect(() => {
        if (ws_token) {
            init();
        }
    }, [ws_token])

    const ws = useRef(null);

    const handleMessage = object => {
        let ids;
        
        console.log(object);

        switch(object.message_type) {
            case "product":
                const product = object.product;
                insertProductWithoutTouchingOffers(product);
                return
            case "refresh_products":
                ids = object.ids;
                getProducts(ids);
                return
            case "faves":
                const faves = object.faves;
                const customer_id = object.customer_id;
                inject_faves(customer_id, faves);
                return
            case "refresh_order":
                ids = [object.order_id];
                getOrders(ids);
                if (object.is_new) {
                    listOrders();
                }
                return
            case "notification":
                const { title, body, data } = object;
                showToast(title, body, data);
                return
            case "refresh_basket":
                getBasket();
                return 
            case "ws_connected":
                const session_id = object.session_id;
                set_ws_session(session_id)
                return
                
            default:
                return
        }
    }

    const init = () => {
        if (ws.current) {
            ws.current.close();
            ws.current = null;
        }
        const url = `${ws_url}${ws_token ? `?t=${ws_token}` : ''}`;

        ws.current = new WebSocket(url);
        ws.current.onopen = () => console.log("WS CONNECTED!");
        ws.current.onmessage = (event) => {
            try {
                const object = JSON.parse(event.data);
                handleMessage(object);
            } catch(e) {
                console.log("WS ERROR", event.data);
            }
        }
        ws.current.onclose = () => {
            ws.current = null;
            const wait_time = refresh_wait.current * 1000;
            refresh_wait.current = refresh_wait.current + 1;
            setTimeout(init, wait_time);
        }
        ws.current.onerror = () => {
            if (ws.current) {
                ws.current.close();
            }
        }
    }

    return (
        <WsContext.Provider value={{}}>
            {props.children}
        </WsContext.Provider>
    )
}
export default WsWrapper;
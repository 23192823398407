import { useContext } from "react";
import { useState } from "react";
import { createContext } from "react";
import { api_url } from "../config";
import { UserContext } from "./user";

export const RepUserCustomerContext = createContext();

const RucWrapper = props => {
    const { user, token } = useContext(UserContext);

    const [users, set_users] = useState({});
    const [customers, get_customers] = useState({});

    const getRequiredUsers = async (ids) => {
        getUsers(ids.filter(x => !(x in users)));
    }

    const getUsers = async (ids) => {
        if (ids.length === 0) return;
        if (user?.is_rep) {
            const resp = await fetch(`${api_url}/r/users/get?ids=${ids.join("%2B")}`, {
                method:"GET",
                headers: {
                    authorization: `Bearer ${token}`
                }
            });
            const body = await resp.json();
            const nu = body.data.data;
            set_users(u => ({
                ...u,
                ...nu
            }));
        }
    }
    return (
        <RepUserCustomerContext.Provider value={{users, actions: { getRequiredUsers }}}>
            {props.children}
        </RepUserCustomerContext.Provider>
    )
}

export default RucWrapper;
import { useContext } from "react";
import { useWindowDimensions, View, TouchableOpacity, FlatList } from "react-native";
import { UserContext } from "../contexts/user";
import ActionSheet from "./action_sheet";
import SwipeDown from "./swipe_down";
import { OrdersContext } from "../contexts/orders";
import ListRow from "./list_row";
import * as Colours from '../colours';
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import Txt from "./txt";
import { ApplicationsContext } from "../contexts/applications_rep";
import { RepUserCustomerContext } from "../contexts/users_customers_rep";
import { Ionicons } from '@expo/vector-icons'

const AppRow = props => {
    const { id, last } = props;
    const { apps } = useContext(ApplicationsContext);
    const { users } = useContext(RepUserCustomerContext);

    const app = apps[id] || null;
    
    if (!app) return <Txt>{id}</Txt>


    const user_info = typeof app.user === 'string' ? users[app.user] : app.user;
    const existing_user = typeof app.user === 'string';

    return (
        <ListRow
            style={{height: 120}}
            onPress={() => props.view(app)}
            title={`Application for ${app.customer.name}`}
            subtitle={`by ${existing_user ? "EXISTING" : 'NEW'} user ${user_info?.first_name} ${user_info?.last_name}`}
            product_for_image={null}
            key_values={[{key: "REG", value: app.customer.company_no}, {key: "Phone", value: app.customer.phone}]}
            last={last}
            >
                {
                    app?.accepted === null && <Txt style={{color:'blue', fontWeight:'bold'}}>Unprocessed</Txt>
                }
                {
                    app?.accepted === true && <Txt style={{color:'green', fontWeight:'bold'}}>Approved</Txt>
                }
                {
                    app?.accepted === false && <Txt style={{color:'red', fontWeight:'bold'}}>Rejected</Txt>
                }
            </ListRow>
    )
}

const ToggleButton = props => {
    const { selected, onPress, children, extraStyle } = props;

    const style = selected ? {
        backgroundColor: Colours.orange,
        borderWidth: 0,
    } :
    {
        backgroundColor:'rgba(0,0,0,0)',
        borderWidth: 1,
        borderColor: Colours.grey
    };

    const textStyle = selected ? {
        color: "white",
        fontWeight:'bold'
    } : {
        color: Colours.grey,
    }

    return (
        <TouchableOpacity onPress={onPress} style={{padding: 8, borderRadius: 10, ...style, ...(extraStyle || {})}}>
            <Txt style={{fontSize: 12, ...textStyle}}>{children}</Txt>
        </TouchableOpacity>
    )
}

const OrderSelector = props => {
    const [all_stores, set_all_stores] = useState(true);
    const [placed_by_me, set_placed_by_me] = useState(false);
    const { user, customer } = useContext(UserContext);
    const { actions: { setOrderParams } } = useContext(OrdersContext);
    const first_all_stroes = useRef(false);
    const first_by_my = useRef(false);

    useEffect(() => {
        if (!first_all_stroes.current) {
            first_all_stroes.current = true;
            return
        }
        if (all_stores) {
            setOrderParams({customers: null})
        } else {
            setOrderParams({customers: [customer.id], offset: 0})
        }
    }, [all_stores]);

    useEffect(() => {
        if (!first_by_my.current) {
            first_by_my.current = true;
            return
        }
        if (placed_by_me) {
            setOrderParams({user_ids: [user.id], offset: 0})
        } else {
            setOrderParams({user_ids: null, offset: 0})
        }
    }, [placed_by_me])

    return (
        <View style={{alignSelf:'stretch', flexDirection:'row', justifyContent:'space-between', alignItems:'center', padding: 12, paddingLeft: 0, paddingRight: 0}}>
            <View style={{flexDirection:'row'}}>
                <ToggleButton extraStyle={{marginRight: 5}} selected={all_stores} onPress={() => set_all_stores(true)}>All Stores</ToggleButton>
                <ToggleButton selected={!all_stores} onPress={() => set_all_stores(false)}>Current Store</ToggleButton>
            </View>
            <ToggleButton selected={placed_by_me} onPress={() => set_placed_by_me(!placed_by_me)}>Placed by Me</ToggleButton>
        </View>
    )
}

const ApplicationsAs = props => {
    //const { order_as: lang } = useContext(LanguageContext)
    const { height, width } = useWindowDimensions();
    const { user, actions: { showAccountModal } } = useContext(UserContext);
    const { viewing_applications, apps, listing, listMore, actions: { setViewingApps, viewApp } } = useContext(ApplicationsContext);
    const close = () => setViewingApps(false);

    const view = (app) => {
        setViewingApps(false);
        viewApp(app);
    }

    const back = () => {
        setViewingApps(false);
        showAccountModal(true);
    }

    if (!user?.is_rep) return null;
    return (
        <ActionSheet
            height={height * 0.9}
            close={close}
            visible={viewing_applications}
            cred="APPS"
            title="Account Application"
            back={back}
        >
            <View style={{flex: 1, marginTop: 10, alignItems:'center', alignSelf:'stretch'}}>
                <View style={{flex: 1, alignSelf:'stretch'}}>
                    <FlatList
                        data={listing}
                        keyExtractor={(_, i) => i.toString()}
                        renderItem={({item, index}) => <AppRow view={view} last={index === listing.length - 1} id={item} />}
                        ListFooterComponent={<View style={{height: 20}} />}
                        onEndReachedThreshold={3}
                        onEndReached={listMore}
                        />
                </View>

            </View>
        </ActionSheet>
    )
}

export default ApplicationsAs;
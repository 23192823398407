import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { TouchableOpacity, Text, View, Animated, useWindowDimensions, Image } from 'react-native';
import StoreFront from './storefront';
import { FontAwesome, FontAwesome5 } from '@expo/vector-icons';
import { useContext, useEffect, useRef } from 'react';
import { UserContext } from './contexts/user';
import { BasketContext } from './contexts/basket';
import { StoreContext } from './contexts/store';
import * as Colours from './colours';
import Txt from './components/txt';
import RepButton from './components/rep_button';
import { RepFrontContext } from './contexts/rep_front';
import { IfEventContext } from './contexts/if_event';
import { LanguageContext } from './contexts/language';

const StoreStack = createNativeStackNavigator();

const AccountButton = props => {
    const { register } = useContext(IfEventContext);
    const { user, actions: { showAccountModal} } = useContext(UserContext);
    const { width } = useWindowDimensions();
    const is_mobile = width <= 768;
    const { header: lang } = useContext(LanguageContext)

    const open = () => {
        showAccountModal(true);
        register('show_account_modal', {})
    }
    
    if (user) {
        if (!is_mobile) {
            return (
                <TouchableOpacity style={{marginRight: 16, borderWidth: 1, borderColor: Colours.orange, flexDirection:'row', padding: 5, borderRadius: 100, alignItems:'center', paddingLeft: 10, paddingRight: 10}} onPress={open}>
                    <FontAwesome name="user" size={24} color={Colours.orange} />
                    <Txt style={{fontWeight:'bold', color:Colours.orange, marginLeft: 8}}>{lang.account}</Txt>
                </TouchableOpacity>
            )
        }

        return (
        <TouchableOpacity style={{marginRight: 16}} onPress={open}>
            <FontAwesome name='user' size={24} color={Colours.orange} />
        </TouchableOpacity>
        )
    }
    return null;

}

const BasketButton = props => {
    const { basket, actions: { viewBasket }} = useContext(BasketContext);
    const { header: lang } = useContext(LanguageContext)
    const { width } = useWindowDimensions();
    const vBasket = () => viewBasket(true);
    const scale = useRef(new Animated.Value(1.3)).current;
    const is_mobile = width<= 768;

    useEffect(() => {
        pulse();
    }, [basket])

    const pulse = () => {
        Animated.sequence([
            Animated.timing(scale, { toValue: is_mobile ? 1.3 : 1.1, duration: 200, useNativeDriver: true}),
            Animated.timing(scale, { toValue: 1.0, duration: 200, useNativeDriver: true}),
        ]).start();
    }

    if (!is_mobile) {
        return (
        <Animated.View style={{transform: [{scale}]}}>
            <TouchableOpacity onPress={vBasket} style={{padding: 6, backgroundColor:Colours.orange, borderRadius: 100, padding: 5, paddingRight: 10, flexDirection:'row', alignItems:'center', marginRight: 10}}>
                <FontAwesome name="shopping-basket" size={22} color="white" />
                <Txt style={{fontWeight:"bold", colour: 'white', marginLeft: 8, color: 'white'}}>{lang.basket}</Txt>
            </TouchableOpacity>
        </Animated.View>    
        )
    }
    return (
        <Animated.View style={{transform: [{scale}]}}>
            <TouchableOpacity onPress={vBasket} style={{padding: 6}}>
                <FontAwesome name="shopping-basket" size={22} color={Colours.orange} />
            </TouchableOpacity>
        </Animated.View>
    )
}

const LoginButton = props => {
    const { register } = useContext(IfEventContext);
    const { user, actions: { showLoginModal }} = useContext(UserContext);
    const login = () => {
        showLoginModal(true);
        register("show_login_modal", {})
    }
    const { width } = useWindowDimensions();
    const is_mobile = width <= 768;

    if (user) {
        return (
            <View style={{flexDirection:'row', alignItems:'center', justifyContent: 'flex-end', alignSelf:'stretch'}}>
                <AccountButton />
                <BasketButton />
            </View>
        )
    }

    if (!is_mobile) {
        return (
            <View style={{flexDirection:'row', alignItems:'center', justifyContent: 'flex-end', alignSelf:'stretch', marginRight: 10}}>
                <TouchableOpacity onPress={login} style={{padding: 8, alignSelf:'stretch', justifyContent:"center", alignItems:"center", flexDirection:'row', borderRadius: 100, borderWidth: 1, borderColor: Colours.purple}}>
                    <FontAwesome name='user' color={Colours.purple} size={24} />
                    <Txt style={{color: Colours.purple, fontWeight:'bold', marginLeft: 8}}>Login</Txt>
                </TouchableOpacity>
            </View>
        )
    }

    return (
        <View style={{flexDirection:'row', alignItems:'center', justifyContent: 'flex-end', alignSelf:'stretch'}}>
            <TouchableOpacity onPress={login} style={{padding: 8, alignSelf:'stretch', justifyContent:"center", alignItems:"center"}}>
                <FontAwesome name='user' color={Colours.purple} size={24} />
            </TouchableOpacity>
        </View>
    )
}

const SettingsButton = props => {
    const { register } = useContext(IfEventContext);
    const {width} = useWindowDimensions();
    const { actions: { viewFilters } } = useContext(StoreContext);
    
    const show = () => {
        viewFilters(true);
        register('show_filters_modal', {});
    }

    const is_mobile = width <= 768;

    return (
        <View style={{paddingLeft: 10, alignSelf:'stretch', justifyContent:'flex-start', flexDirection:'row'}}>
            {
                is_mobile && 
                <TouchableOpacity onPress={show} style={{padding: 6}}>
                    <FontAwesome5 name="ellipsis-h" color={Colours.orange} size={20} />
                </TouchableOpacity>
            }
        </View>
    )
}


const TitleWithCustomer = props => {
    const { register } = useContext(IfEventContext);
    const { width } = useWindowDimensions();
    const is_mobile = width <= 768;
    const { front } = useContext(StoreContext);
    const { customer, customers, actions: { showCustomerModal } } = useContext(UserContext);
    const { actions: { setViewing } } = useContext(RepFrontContext)

    const selectCustomer = () => {
        showCustomerModal(true);
        register('show_customer_modal', {})
    }

    return (
            <View style={{alignItems:'center', flexDirection:'row', justifyContent:'center', alignSelf:'stretch'}}>
                <View style={{alignItems:'center'}}>
                    <RepButton onPress={() => setViewing(true)}><Txt style={{color: Colours.orange, fontSize: is_mobile ? 20 : 20}}>{front?.name}</Txt></RepButton>
                    {
                        customer &&
                        <Txt onPress={selectCustomer} style={{fontWeight:'bold'}}><Txt style={{color:Colours.purple}}>{shorten(customer.name, is_mobile)}</Txt></Txt>
                    }
                </View>
            </View>


    )
}

const HeaderLeft = props => {
    const { width } = useWindowDimensions();

    if (width > 768) {
        return (
        <View style={{padding: 5}}>
            <AddressAndContact />
        </View>)
    }

    return (
        <SettingsButton />
    )
}

const shorten = (n, is_mobile = false) => {
    if (!is_mobile) return n;

    if (n.length > 20) {
        return `${n.slice(0, 20)}...`
    }
    return n
}

const Header = props => {
    const { width } = useWindowDimensions();
    const { front } = useContext(StoreContext);
    const img_size = width > 768 ? 80 : 50

    return (
        <View style={{alignSelf:'stretch', flexDirection:'row', justifyContent:'center', alignItems:'center', backgroundColor:'white', minHeight: 50}}>
            <View style={{width: 200}}>
                <HeaderLeft />
            </View>
            <View style={{flex: 1, alignItems:'center'}}>
                {
                    front?.logo_url ? <Image source={{uri: "https://alb-product-images.s3.eu-west-1.amazonaws.com/albany_portugal_logo.png"}} style={{width: img_size, height: img_size}} />
                    :
                    <Text style={{fontSize: 28, fontWeight:'bold', color: Colours.orange}}>{front?.name}</Text>
                }
                {
                    width > 768 &&
                    <Text style={{fontSize: 16, marginTop: 8, marginBottom: 8, color: Colours.dark_grey, textAlign: 'center'}}>{front?.tagline}</Text>
                }
            </View>
            <View style={{width: 200}}>
                <LoginButton />
            </View>
        </View>
    )
}

export const AddressAndContact = props => {
    const { front } = useContext(StoreContext);

    return (
        <>
            <Text style={{transform: [{scale: 0.9}], color:'grey', fontWeight: 'bold', fontSize: 12}}>{front?.name?.toUpperCase()}</Text>
            {
                front?.address && front.address.split(",").map((part, i) => <Text style={{transform: [{scale: 0.9}], color:'grey', fontWeight: 'bold', fontSize: 12}} key={i}>{part.trim().toUpperCase()}</Text>)
            }
            <Text style={{color:'grey', fontWeight: 'bold', fontSize: 12, marginTop: 5, transform: [{scale: 0.9}]}}>{front?.phone}</Text>
        </>
    )
}

const Nav = props => {
    return (
        <StoreStack.Navigator>
            <StoreStack.Screen 
                name="Albany Products" 
                component={StoreFront} 
                options={{
                    header: () => <Header />,
                    headerTitle: TitleWithCustomer,
                    headerRight: () => <LoginButton />,
                    headerLeft: () => <HeaderLeft />,
                    headerTitleAlign:'center'
                }}
                />
        </StoreStack.Navigator>
    )
}

export default Nav;
import { useState } from "react";
import { createContext } from "react";

export const TempImageContext = createContext({});

const TempImageWrapper = props => {
    const [refresh_eans, set_refresh_eans] = useState({})

    const addRefreshEan = ean => set_refresh_eans(eans => ({...eans, [ean]: (refresh_eans[ean] || 0) + 1}));
    
    return (
        <TempImageContext.Provider value={{actions: {addRefreshEan}, refresh_eans}}>
            {props.children}
        </TempImageContext.Provider>
    )
}

export default TempImageWrapper;
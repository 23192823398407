import { createContext, useEffect, useState } from "react";
import { english, portuguese } from "../lanuage";
import * as Storage from '../storage';

export const LanguageContext = createContext({});

const init = () => {
    const saved = Storage.get('lang', false);
    console.log('SAVED IS', saved);
    if (saved === 'portuguese') {
        return portuguese;
    } else {
        return english
    }
}

const LanguageWrapper = props => {
    const [language, set_language] = useState(init());

    const setLanguage = l => {
        if (l === 'portuguese') {
            Storage.set('lang', 'portuguese', false);
            set_language(portuguese);
            return
        } else {
            Storage.set('lang', 'english', false);
            set_language(english);
            return
        }
    }

    return (
        <LanguageContext.Provider value={{...language, setLanguage}}>
            {props.children}
        </LanguageContext.Provider>
    )
}

export default LanguageWrapper


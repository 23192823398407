import { TouchableOpacity, TextInput } from "react-native";
import { useState, useEffect } from "react";

const FullScreenNumberInput = (props) => {
    const { value, onChange, visible, close } = props;
    const [temp_value, set_temp_value] = useState(value);

    useEffect(() => {
        set_temp_value(value)
    }, [value])

    const submit = () => {
        const new_number = parseFloat(temp_value);
        if (isNaN(new_number)) return;
        onChange(new_number);
    }

    if(!visible) return null;
    return (
        <TouchableOpacity onPress={close} style={{backgroundColor:"rgba(0,0,0,0.9)", position:'absolute', top: 0, right: 0, left: 0, bottom: 0, justifyContent:'center', alignItems:'center', paddingBottom: 0}}>
               <TextInput 
                onBlur={close} 
                keyboardType="decimal-pad" 
                style={{fontSize: 60, color:'white'}} 
                value={temp_value}
                onChangeText={t => set_temp_value(t)}
                autoFocus
                returnKeyType='done'
                onSubmitEditing={submit}
                />
        </TouchableOpacity>
    )
}
export default FullScreenNumberInput;
import { createContext, useContext } from "react";
import * as Notifications from 'expo-notifications';
import * as Device from 'expo-device';
import { useEffect, useRef, useState } from "react";
import { Alert, Platform, TouchableOpacity } from "react-native";
import { api_url } from "../config";
import { UserContext } from "./user";
import { StoreContext } from "./store";
import { OrdersContext } from "./orders";
import { AlertContext } from "./alert";
import { useToast, ToastProvider } from "react-native-toast-notifications";

export const PushContext = createContext({});


const PushWrapper = props => {
    const { children } = props;
    const { token, user, customer }  = useContext(UserContext);
    const { actions : { setStoreParams }} = useContext(StoreContext);
    const { actions: { getOrder, viewOrder } } = useContext(OrdersContext);
    const { actions: { setAlert }} = useContext(AlertContext);
    const notificationListener = useRef();
    const responseListener = useRef();
    const [to_process, set_to_process] = useState(null);
    const toast = useToast();

    useEffect(() => {
        if (Platform.OS !== 'web') {
            if (token) {
                saveToServer();
            }
        }
    }, [token])

    useEffect(() => {
        if (Platform.OS !== 'web') {
            if (token && to_process && user && customer) {
                actionNotification(to_process)
                set_to_process(null);
            }
        }
    }, [token, to_process, user, customer])

    const actionNotification = async n => {
        const data = n.request.content.data;

        if (data?.type === 'store.list') {
            setStoreParams(data.config);
        } else if (data?.type === 'order.new' || data?.type === 'order.cancel') {
            const order = await getOrder(data.id);
            viewOrder(order);
        }
    }


    const showNotification = async n => {
        if (Platform.OS !== 'web') {
            const data = n.request.content.data;
            if (data?.type === 'order.new' || data?.type === 'order.cancel') {
                const order = await getOrder(data.id);
                if (order.user_id === user.id) {
                    return
                }
            }
            showToast(n.request.content.title, n.request.content.body, n.request.content.data);
        }
    }

    const showToast = (title, body, data=null) => {
        toast.show(JSON.stringify({title, body}), {
            type: 'success',
            placement: 'bottom',
            duration: 5000,
            onPress: () => {
                if (data) {
                    set_to_process({request: { content: { data } } });
                }
            },
            
        });
    }

    useEffect(() => {
        if (Platform.OS !== 'web') {
            notificationListener.current = Notifications.addNotificationReceivedListener(notification => {
                showNotification(notification);
              });
          
              responseListener.current = Notifications.addNotificationResponseReceivedListener(response => {
                const notification = response?.notification;
                if (notification) {
                    set_to_process(notification);
                }
              });
          
              return () => {
                Notifications.removeNotificationSubscription(notificationListener.current);
                Notifications.removeNotificationSubscription(responseListener.current);
              };
        }
    }, [])

    const saveToServer = async () => {
        const push_token = await registerForPushNotificationsAsync();
        if (token && push_token) {
            const res = await fetch(`${api_url}/c/user/push`, {
                method: "POST",
                headers: {
                    "content-type" : "application/json",
                    "authorization": `Bearer ${token}`
                },
                body: JSON.stringify({
                    tokens: [push_token],
                    allow_types: ["all"]
                })
            });
            if (!res.ok) {
                let body = await res.text();
                Alert.alert("Save Push Token", `BODY = ${body}`);
            }
        }
    }

    return (
        <PushContext.Provider value={{actions: { showToast }}}>
            {children}
        </PushContext.Provider>
    )
}

async function registerForPushNotificationsAsync() {
    let token;
    if (Device.isDevice) {
      const { status: existingStatus } = await Notifications.getPermissionsAsync();
      let finalStatus = existingStatus;
      if (existingStatus !== 'granted') {
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
      }
      if (finalStatus !== 'granted') {
        return;
      }
      token = (await Notifications.getExpoPushTokenAsync({projectId: "6e78d3c9-e9a0-434d-876f-8b96c30a1429"})).data;
    } else {
      Alert.alert('Must use physical device for Push Notifications');
    }

  
    if (Platform.OS === 'android') {
      Notifications.setNotificationChannelAsync('default', {
        name: 'default',
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: '#FF231F7C',
      });
    }
  
    return token;
  }


  export default PushWrapper;
import { useContext } from "react"
import { View } from "react-native";
import { StoreContext } from "../contexts/store"
import { AntDesign } from '@expo/vector-icons';
import { LanguageContext } from "../contexts/language";
import Txt from "../components/txt";

const Part = ({children}) => {
    if (children === 'ARROW') return <AntDesign name="arrowright" size={18} style={{marginLeft: 5, marginRight: 5}} />
    return <Txt>{children}</Txt>
}

const ExplainQuery = props => {
    const { explain_as: lang } = useContext(LanguageContext);
    const { cat_data, config, actions: {reset}, defined_sets, count } = useContext(StoreContext);

    const stockPart = () => {
        if (config?.stock) {
            return {
                text: lang.in_stock,
                can_clear: false
            }
        }
        
        return {
            text: null,
            can_clear: false
        }
    }

    const casPart = () => {
        if ((config?.defined_sets || []).length > 0) {
            let ds = config?.defined_sets.map(x => defined_sets[x].name || "Loading").join(", ");
            return {
                text: ds,
                can_clear: true
            }
        }
        let parts = [];

        if (config?.cas?.ambient !== undefined) {
            parts.push(lang.ambient);
        }
        if (config?.cas?.frozen !== undefined) {
            parts.push(lang.frozen);
        }
        if (config?.cas?.seasonal !== undefined) {
            parts.push(lang.seasonal);
        }
        if (config?.cas?.catering !== undefined) {
            parts.push(lang.catering);
        }

        return {
            text: parts.length > 0 ? parts.join(", ") : lang.ambient, can_clear: parts.length > 0 && (!parts.length === 1 && parts[0] === lang.ambient )}
    }

    const catPart =() => {
        if ((config?.categories || []).length > 0 ) {
            const cat_names = config.categories.map(x => cat_data[x]?.name || lang.unknown);
            return {
                text: cat_names.join(", "),
                can_clear: true
            }
        }
        return {
            text: null,
            can_clear: false
        }
    }

    const termPart = () => {
        if (config.term) {
            return {
                text: `"${config.term}"`,
                can_clear: true
            }
        }
        return ({
            text: null,
            can_clear: false
        })
    }

    const codesPart = () => {
        if ((config.codes || []).length > 0) {
            return {
                text: "Specific Products",
                can_clear: true
            }
        }
        return ({
            text: null,
            can_clear: false
        })
    }


    const cas_part = casPart();
    const cat_part = catPart();
    const term_part = termPart();
    const stock_part = stockPart();
    const codes_part = codesPart();

    const can_clear = cas_part.can_clear || cat_part.can_clear || term_part.can_clear || stock_part.can_clear || codes_part.can_clear;
    const text = [codes_part.text, cas_part.text, cat_part.text, term_part.text, stock_part.text].filter(x => x !== null)
        .join("~ARROW~")
        .split("~");

    return (
        <View style={{minHeight: 30, alignItems:'center', flexDirection:'row', alignSelf:'stretch', padding: 8}}>
            <View style={{alignSelf:'stretch', flex: 1, justifyContent:'flex-start', flexDirection:'row', flexWrap:'wrap', alignItems:'center'}}>
                {
                    text.map((x, i) => <Part key={i}>{x}</Part>)
                }
                <Txt style={{marginLeft: 8}}>({count})</Txt>
            </View>
            <View>
                {
                    can_clear && 
                    <Txt onPress={reset} style={{color:'blue', fontWeight:'bold'}}>{lang.clear}</Txt>
                }
            </View>
        </View>
    )
}

export default ExplainQuery;
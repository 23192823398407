import { createContext, useContext, useEffect, useState } from "react"
import { OrdersContext } from "./orders";
import { StoreContext } from "./store";
import { UserContext } from "./user";
import { configFromParams } from './store';
import { ApplicationsContext } from "./applications_rep";

export const LinksContext = createContext({});

const LinksWrapper = props => {
    const { user, actions: { showLoginModal, showCreatePassword, showInvitedAs } } = useContext(UserContext);
    const { actions: { viewProd, getProduct, setStoreParams, set_did_init_from_params } } = useContext(StoreContext);
    const { actions: { viewOrder, getOrder }} = useContext(OrdersContext);
    const { actions: { getApp, viewApp }} = useContext(ApplicationsContext)
    const [params, set_params] = useState(null);

    const setParams = p => set_params({...params, ...p});

    const handleParams = async () => {
        if (params) {
            if (params.welcome) {
                showCreatePassword(params.welcome);
                set_params({
                    ...params,
                    welcome: undefined
                });
            }
            if (params.order) {
                if (user) {
                    const order = await getOrder(params.order);
                    if (order) {
                        viewOrder(order);
                        set_params({
                            ...params,
                            order: undefined
                        })
                    }
                } else {
                    showLoginModal(true);
                }
            }

            if (params.application) {
                if (user) {
                    const app = await getApp(params.application);
                    if (app) {
                        viewApp(app);
                        set_params({
                            ...params,
                            application: undefined
                        })
                    }
                } else {
                    showLoginModal(true);
                }
            }

            if (params.product) {
                const prod = await getProduct(params.product);
                if (prod) {
                    viewProd(prod);
                    setStoreParams({term: prod.ean, offset: 0, categories: null, cas: null});
                    set_params({
                        ...params,
                        product: undefined
                    });
                }
            }

            if (params.invited) {
                const email = params.invite;
                showInvitedAs(email);
                set_params({
                    ...params,
                    invited: undefined
                })
            }

            const as_config = configFromParams(params);
            if (as_config) {
                setStoreParams(as_config);
                set_params({
                    ...params,
                    term: undefined,
                    offset: undefined,
                    limit: undefined,
                    cas: undefined,
                    stock: undefined,
                    defined_sets: undefined,
                    codes: undefined,
                    sort:undefined,
                    reverse: undefined,
                    show_unlisted: undefined,
                    categories: undefined,

                })
            }
        }
        set_did_init_from_params(true);
    }

    useEffect(() => {
        handleParams()
    }, [params, user])

    return (
        <LinksContext.Provider value={{
            actions: {setParams}
        }}>
            {props.children}
        </LinksContext.Provider>
    )
}

export default LinksWrapper;
import { useWindowDimensions, View, FlatList } from "react-native";
import ActionSheet from "../components/action_sheet";
import Button from "../components/button";
import { ProductBasketButtons } from ".";
import { useContext, useEffect } from "react";
import { BasketContext } from "../contexts/basket";
import { UserContext } from "../contexts/user";
import { StoreContext } from "../contexts/store";
import SwipeDown from "../components/swipe_down";
import { OrdersContext } from "../contexts/orders";
import { LanguageContext } from "../contexts/language";
import ListRow from "../components/list_row";
import { AlertContext } from "../contexts/alert";
import AwesomeAlert from 'react-native-awesome-alerts'
import * as Colours from '../colours';
import Txt from "../components/txt";
import RepButton from "../components/rep_button";

const BasketItem = props => {
    const { basket_as: lang } = useContext(LanguageContext);
    const { bi, last, override } = props;
    const { products } = useContext(StoreContext);
    const product = products[bi.product_id];
    const offer = (product?.offers || []).find(x => x.id === bi.offer_id);

    const textProps = offer?.price !== bi?.p_ov ? {fontWeight: 'bold', color:'red'} : {}
    
    return (
        <ListRow 
            product_for_image={product}
            title={`${(product?.description || 'Loading').toUpperCase()} ${product?.case_size} x ${product?.size}`}
            last={last}
            key_values={[
                {key: '', value: <ProductBasketButtons style={{borderRadius: 5, overflow:'hidden'}} offer={offer} />},
                {key: <RepButton onPress={override}><View style={{alignItems:"center"}}>
                    <Txt style={textProps} onPress={override}>€{bi.p_ov.toFixed(2)}</Txt>
                    <Txt style={{fontSize: 10}} onPress={override}>each</Txt>
                </View></RepButton>, value: null},
                {
                    key: <View style={{alignItems:"center"}}>
                    <Txt style={textProps}>€{(bi.p_ov * bi.quantity).toFixed(2)}</Txt>
                    <Txt style={{fontSize: 10}} onPress={override}>{lang.total}</Txt>
                </View>, value: null}
            ]}
            >
                <View style={{height: 20}} />
            </ListRow>
    )
}

const ConfirmAlert = props => {
    const { visible, title, close, confirm } = props;

    return (
        <AwesomeAlert
            show={visible}
            title={title}
            closeOnTouchOutside={true}
            closeOnHardwareBackPress={true}
            showCancelButton={true}
            showConfirmButton={true}
            cancelText="Cancel"
            confirmText="Place Now"
            confirmButtonColor={Colours.orange}
            onCancelPressed={close}
            onConfirmPressed={confirm}
            />
    )
}

const BasketAs = props => {
    const { basket_as: lang } = useContext(LanguageContext);
    const { height, width } = useWindowDimensions();
    const { viewing: visible, basket, actions: { viewBasket, setOverriding } } = useContext(BasketContext)
    const { actions : { placeOrder }, placing_order: loading} = useContext(OrdersContext);
    const { actions : { setAlert }} = useContext(AlertContext)
    const { customer, user } = useContext(UserContext);
    

    const close = () => viewBasket(false);
    const total_qty = basket.map(x => x.quantity).reduce((a,b) => a + b, 0);


    useEffect(() => {
        if (total_qty === 0) {
            close();
        }
    }, [total_qty])

    const place = async () => {
        setAlert({
            title: `Place order for ${customer?.name}`,
            cancel_text: "Cancel",
            confirm_text: "Place Order",
            onCancel: () => setAlert(null),
            onConfirm: () => {
                setAlert(null)
                placeOrder();
            }
        });
    }

    const override = (item) => {
        if (user?.is_rep) {
            setOverriding(item);
        }
    }

    return (
        <ActionSheet
            height={height * 0.8}
            close={close}
            visible={visible}
            cred="BASKWRT"
            title={`${lang.your_basket} ${customer?.name}`}
        >
            <View style={{flex: 1, alignSelf:'stretch', marginTop: 10, alignItems:'center'}}>
                {
                    customer?.name && 
                    <View style={{flex: 1, alignSelf:'stretch'}}>
                        <View style={{flex: 1, justifyContent:'space-evenly', alignItems:'stretch', alignSelf:'stretch'}}>
                            <FlatList
                                data={basket.filter(x => x.quantity > 0)}
                                keyExtractor={(item, i) => i.toString()}
                                renderItem={({item, index}) => <BasketItem override={() => override(item)} bi={item} last={index === basket.length - 1} />}
                                />
                        </View>
                        <View style={{alignSelf:'stretch', flexDirection:'column', marginBottom: 30}}>
                            <View style={{flexDirection:'row', justifyContent:'center', padding: 20}}>
                                <Txt style={{fontSize: 13, fontWeight:'bold', marginRight: 20}}>{lang.total_cases}  {total_qty}</Txt>
                                <Txt style={{fontSize: 13, fontWeight:'bold'}}>{lang.total_value} €{basket.map(x => x.quantity * x.p_ov).reduce((a,b) => a + b, 0).toFixed(2)}</Txt>
                            </View>
                            <Button disabled={total_qty === 0} loading={loading} style={{height: 40}} onPress={place}>{lang.place_order}</Button>
                        </View>
                    </View>
                }
            </View>

        </ActionSheet>
    )
}


export default BasketAs;
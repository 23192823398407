import { View, TextInput, TouchableOpacity } from "react-native"
import { AntDesign } from '@expo/vector-icons'
import { useRef } from "react";

const TxtInput = props => {
    const { value, onChange, placeholder, can_clear, onClear, secureTextEntry, onBlur, height, onFocus, inputProps } = props;
    const i_ref = useRef(null);

    const clear = () => {
        if (can_clear) {
            onClear();
            if (i_ref.current) {
                i_ref.current.focus();
            }
        }
    }

    const ip_height = height || 40;
    const icon_size = ip_height - 16;
    const top_offset = (ip_height - icon_size) / 2;

    return (
        <View style={{position: 'relative', overflow: 'hidden', alignSelf:'stretch', borderRadius: 10, height: ip_height, ...(props.style || {})}}>
            <TextInput
                style={{backgroundColor:'white', flex: 1, alignSelf:'stretch', fontSize: 18, paddingLeft: 10, ...(props.inputStyle || {})}}
                placeholder={placeholder}
                value={value || ''}
                secureTextEntry={secureTextEntry}
                onChangeText={v => onChange(v)}
                onBlur={onBlur}
                onFocus={onFocus}
                ref={i_ref}
                {...(inputProps || {})}
                />
                {
                    (value !== null && value !== '') && can_clear &&
                    <TouchableOpacity onPress={clear} style={{position:'absolute', right: top_offset, top: top_offset}}>
                        <AntDesign name="closecircleo" size={icon_size} color="black" />
                    </TouchableOpacity>
                }
        </View>
    )
}

export default TxtInput;
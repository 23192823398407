import { TouchableWithoutFeedback, useWindowDimensions, View } from "react-native";
import { Animated, TouchableOpacity } from "react-native";
import { useRef, useEffect, useState, useContext } from "react";
import { LanguageContext } from "../contexts/language";
import { KbContext } from "./keyboard_wrapper";
import { Ionicons } from '@expo/vector-icons';
import * as Colours from '../colours';
import Txt from "./txt";
import SwipeDown from './swipe_down'

//LANGUAGE


const Drawer = props => {
    const lang = useContext(LanguageContext);
    const { width, height: window_height } = useWindowDimensions();
    const drawer_width = 400;
    const { visible, close, children, close_button, title, back } = props;
    const translate = useRef(new Animated.Value(1)).current;

    const show = () => Animated.timing(translate, { duration: 200, toValue: 1, useNativeDriver: true }).start();
    const hide = () => Animated.timing(translate, { duration: 200, toValue: 0, useNativeDriver: true }).start();
    
    useEffect(() => {
        if (visible) {
            show();
        } else {
            hide();
        }
    }, [visible])

    const translateX = translate.interpolate({inputRange: [0,1], outputRange: [400, 0]});
    
    return (
        <>
            {
                visible &&
                <TouchableWithoutFeedback onPress={close}>
                    <Animated.View style={{position:'absolute', top: 0, right: 0, left: 0, bottom: 0, backgroundColor: 'rgba(0,0,0,0.7)'}} />
                </TouchableWithoutFeedback>
            }
                <Animated.View style={{
                    height: window_height,
                    position: 'absolute',
                    width: drawer_width,
                    bottom: 0,
                    right:0,
                    top: 0,
                    backgroundColor:'white',
                    transform:[{translateX}],
                    paddingTop: 10
                }}
                    >
                        <View style={{alignSelf:'stretch', alignItems:'center', flex: 1, padding: 10}}>
                            {
                                title && 
                                <View style={{flexDirection:'row', justifyContent:'flex-start', alignSelf:'stretch', alignItems:'center', paddingRight: 20}}>
                                    {
                                        back && <TouchableOpacity style={{padding: 5, marginRight: 10}} onPress={back}>
                                            <Ionicons name="arrow-back" color={Colours.purple} size={22} />
                                        </TouchableOpacity>
                                    }
                                    <Txt style={{fontWeight:'bold', fontSize: 18}}>{title}</Txt>
                                </View>
                            }
                            <View style={{flex: 1, alignSelf:'stretch'}}>
                                {visible && children}
                            </View>
                        </View>
                        {
                            close_button &&
                            <TouchableOpacity onPress={close} style={{alignSelf:'stretch', backgroundColor:'red', height: 80, justifyContent:'center', alignItems:"center"}}>
                                <Txt style={{color:"white", fontWeight:'bold'}}>{lang.action_sheet.close}</Txt>
                            </TouchableOpacity>
                        }
                </Animated.View>
        </>
    )
}
const ActionSheet = props => {
    const lang = useContext(LanguageContext);
    const { width, height: window_height } = useWindowDimensions();
    const { kb_height } = useContext(KbContext);
    const { visible, close, children, height: as_height, close_button, title, back } = props;
    const translate = useRef(new Animated.Value(as_height + 100)).current;
    const [height, set_h] = useState(as_height);

    const show = () => Animated.timing(translate, { duration: 300, toValue: 0, useNativeDriver: true }).start();
    const hide = () => Animated.timing(translate, { duration: 300, toValue: as_height + 100, useNativeDriver: true}).start();
    
    useEffect(() => {
        if (kb_height > 0) {
            const space = window_height - kb_height - 50;
            if (space < as_height) {
                set_h(space);
            } else {
                set_h(as_height)
            }
        } else {
            set_h(as_height)
        }
    }, [kb_height])
    
    useEffect(() => {
        if (visible) {
            show();
        } else {
            hide();
        }
    }, [visible])

    if (width > 768) {
        return <Drawer {...props} />
    }

    return (
        <>
            {
                visible &&
                <TouchableWithoutFeedback onPress={close}>
                    <Animated.View style={{position:'absolute', top: 0, right: 0, left: 0, bottom: 0, backgroundColor:'rgba(0,0,0,0.7)'}} />
                </TouchableWithoutFeedback>
            }
                <Animated.View style={{
                    height,
                    position: 'absolute',
                    bottom: 0,
                    left:0,
                    right: 0,
                    backgroundColor:'white',
                    transform:[{translateY: translate}],
                    borderTopRightRadius: 20,
                    borderTopLeftRadius: 20,
                    paddingTop: 10
                }}
                    >
                        <View style={{alignSelf:'stretch', marginTop: 10, alignItems:'center', flex: 1}}>
                        {
                                title &&
                                <SwipeDown style={{alignSelf:'stretch'}} onSwiper={close ? close : () => {}}>
                                    <View style={{flexDirection:'row', justifyContent:'flex-start', alignSelf:'stretch', paddingLeft: 10, alignItems:'center'}}>
                                        {
                                            back && <TouchableOpacity style={{padding: 5, marginRight: 10}} onPress={back}>
                                                <Ionicons name="arrow-back" color={Colours.purple} size={22} />
                                            </TouchableOpacity>
                                        }
                                        <Txt style={{fontWeight:'bold', fontSize: 18}}>{title}</Txt>
                                    </View>
                                </SwipeDown>
                            }
                            <View style={{width: width - 40, flex: 1}}>
                                {visible && children}
                            </View>
                        </View>
                        {
                            close_button &&
                            <TouchableOpacity onPress={close} style={{alignSelf:'stretch', backgroundColor:'red', height: 80, justifyContent:'center', alignItems:"center"}}>
                                <Txt style={{color:"white", fontWeight:'bold'}}>{lang.action_sheet.close}</Txt>
                            </TouchableOpacity>
                        }
                </Animated.View>
        </>
    )
}

export default ActionSheet;
import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { createContext } from "react";
import { api_url } from "../config";
import { UserContext } from "./user";

export const RepFrontContext = createContext({});

const RepFrontWrapper = props => {
    const { user, token } = useContext(UserContext);
    const [policy, set_policy] = useState(null);
    const [viewing, set_viewing] = useState(false)

    const setViewing = (open) => set_viewing(open);

    useEffect(() => {
        if (user?.is_rep) {
            getPolicy();
        }
    }, [user?.is_rep])

    const getPolicy = async () => {
        if (!user?.is_rep) return
        const resp = await fetch(`${api_url}/r/front/policy`, {
            headers: {
                authorization: `Bearer ${token}`
            }
        });
        if (resp.ok) {
            const body = await resp.json();
            set_policy(body.data);
        }
    }

    const setPolicy = async (instr) => {
        set_policy({
            ...policy,
            ...instr
        });
        if (!user?.is_rep) return
        const resp = await fetch(`${api_url}/r/front/set_policy`, {
            method: "POST",
            headers: {
                'Content-Type' :"application/json",
                authorization: `Bearer ${token}`
            },
            body: JSON.stringify(instr)
        });
        if (resp.ok) {
            const body = await resp.json();
            set_policy(body.data);
        }
    }

    const price = (pallet, cost) => {
        if (policy) {
            let min_mgn = policy?.pricing_policy?.min_mgn_pc || policy?.global_default;
            let cost_plus_mgn = cost / (1.00 - min_mgn);

            let cost_plus_contrib = null;
            let stacked = null;

            if (policy?.pricing_policy.min_per_pallet) {
                if (pallet) {
                    let contrib = policy?.pricing_policy.min_per_pallet / pallet;
                    cost_plus_contrib = cost + contrib;
                    stacked = cost_plus_mgn + contrib;
                }
            }
            if (cost_plus_contrib) {
                switch(policy.pricing_policy.application) {
                    case "Mgn":
                        return cost_plus_mgn
                    case "Pallet":
                        return cost_plus_contrib
                    case "LowerOfTwo":
                        return cost_plus_mgn > cost_plus_contrib ? cost_plus_contrib : cost_plus_mgn
                    case "HigherOfTwo":
                        return cost_plus_mgn > cost_plus_contrib ? cost_plus_mgn : cost_plus_contrib
                    case "Stacked":
                        return stacked
                    default:
                        return cost_plus_mgn
                }
            }
            return cost_plus_mgn
        }
        return null
    }

    return (
        <RepFrontContext.Provider value={{
            actions: {
                setPolicy,
                getPolicy,
                setViewing,
                price
            },
            viewing,
            policy
        }}>
            {props.children}
        </RepFrontContext.Provider>
    )
}

export default RepFrontWrapper;
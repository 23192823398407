import { useContext, useEffect, useState } from "react";
import { ScrollView, TouchableOpacity, useWindowDimensions, View, FlatList, Platform, Switch, Text } from "react-native";
import ActionSheet from "../components/action_sheet"
import Sep from "../components/sep";
import { AmbientButton, CateringButton, FrozenButton, SeasonalButton } from "./product_as";
import { Entypo, FontAwesome5, AntDesign } from '@expo/vector-icons'
import { StoreContext } from "../contexts/store";
import { LanguageContext } from "../contexts/language";
import * as Colours from '../colours';
import Txt from "../components/txt";
import { UserContext } from "../contexts/user";
import { api_url } from "../config";
import { BasketContext } from "../contexts/basket";
import Search from './search';
import { IfEventContext } from "../contexts/if_event";
import { AddressAndContact } from "../navigation";

const descendant_checked = (checked, from_root) => {
    if (from_root) {
        for (var i=0; i < from_root.children.length; i++) {
            const child = from_root.children[i];
            if (checked.indexOf(child.id) !== -1) {
                return true
            }
            if (descendant_checked(checked, child)) {
                return true;
            }
        }
    }
    return false
}

const Input = props => {
    const { checked, onChange } = props;
    if (Platform.OS === 'web') {
        return (
            <input type="checkbox" checked={checked} onChange={onChange} {...props} />
        )
    }
    return (
        <Switch value={checked} onChange={onChange} {...props} />
    )
}

const Category = props => {
    const { register } = useContext(IfEventContext);
    const { parent_checked, category, index } = props;
    const { config, actions: { setStoreParams: params, viewFilters } } = useContext(StoreContext);
    const [open, set_open] = useState(
            (config.categories ||[]).indexOf(category.id) !== -1 ||
            category.children.some(x => (config.categories || []).indexOf(x.id) !== -1)
        );
    const [dc, set_dc] = useState(false);

    useEffect(() => {
        if (category) {
            set_dc(descendant_checked(config.categories || [], category));
        }
    }, [config?.categories, category]);

    const doOpen = () => {
        set_open(true);
        register('open_category', {name: category.name, id: category.id});
    }
    const doClose = () => {
        set_open(false);
        register('close_category', {name: category.name, id: category.id});
    }

    const can_open = (category.children||[]).length > 0;

    const setCategory = () => {
        params({categories: [category.id], offset: 0});
        viewFilters(false);
        register('set_category', {name: category.name, id: category.id});
    }
    const toggleCategory = (checked) => {
        const ids = checked ? (config.categories || []).concat([category.id])
        :
        (config.categories || []).filter(x => x !== category.id);
        params({categories: ids, offset: 0});
        register('toggle_categories', {ids});
    }


    const checked = (config.categories || []).indexOf(category.id) !== -1;

    return (
        <View style={{alignSelf:'stretch', paddingLeft: props.depth * 24}}>
            <View style={{alignSelf:'stretch', flexDirection:'row', justifyContent:'space-between', alignItems:'center', paddingLeft: 0, paddingTop: 5, paddingBottom: 5}}>
                <View style={{flexDirection:'row', alignItems:'center', flex: 1}}>
                    {
                        can_open &&
                        <TouchableOpacity onPress={open ? doClose : doOpen}>
                            {
                                open ? 
                                    <Entypo name="chevron-down" color={Colours.orange} size={20} />
                                :
                                    <Entypo name="chevron-right" color={Colours.orange} size={20} />
                            }
                        </TouchableOpacity>
                    }
                    <Txt style={{fontWeight: dc ? 'bold' : 'normal'}} onPress={setCategory}>{category.name}</Txt>
                </View>
                <Input disabled={parent_checked} checked={checked || parent_checked} onChange={() => toggleCategory(!checked)} />
                {
                    false &&
                    <TouchableOpacity onPress={() => toggleCategory(!checked)} style={{padding: 5}}>
                        <Entypo name="check" size={18} color={checked || parent_checked ? Colours.orange : Colours.grey} />
                    </TouchableOpacity>
                }
            </View>
            {
                open &&
                <View style={{alignSelf:'stretch', flexDirection:'column'}}>
                    {
                        category.children.map((c,i) => <Category depth={props.depth + 1} parent_checked={checked} category={c} key={i} />)
                    }
                </View>
            }
        </View>
    )

}

const DefinedSets = props => {
    const { filters_as: lang } = useContext(LanguageContext)
    const { actions: { viewFilters, listDefinedSets, setStoreParams }, config, viewing_filters: visible, defined_set_list, defined_sets } = useContext(StoreContext);


    const select = (id) => {
        setStoreParams({
            defined_sets: (config?.defined_sets || []).indexOf(id) !== -1 ? (config.defined_sets.filter(x =>  x !== id)) : ((config.defined_sets ||[]).concat(id)),
            offset: 0,
            categories: null,
            cas: null,
            term: null
        })
    }

    const selectOnly = id => {
        setStoreParams({defined_sets: [id], offset: 0, categories: null, cas: null, term: null});
        viewFilters(false);
    }

    return (
        <>
        <View>
            <FlatList
                keyExtractor={(_, i) => i.toString()}
                data={defined_set_list}
                contentContainerStyle={{alignSelf:'stretch'}}
                renderItem={({item, index}) => {
                    const ds = defined_sets[item] || null;
                    const checked = (config?.defined_sets || []).indexOf(item) !== -1;

                    return (
                        <View style={{alignSelf:'stretch', alignItems:'center', flexDirection:'row', justifyContent:'space-between', padding: 6}}>
                            <Txt onPress={() => selectOnly(item)}>{ds?.name || lang.loading}</Txt>

                            <Input checked={checked} onChange={() => select(item)} />
                            {false && <TouchableOpacity onPress={() => select(item)} style={{padding: 5}}>
                                <Entypo name="check" size={18} color={checked ? Colours.orange : Colours.grey} />
                            </TouchableOpacity>}
                        </View>
                    )
                }}
                />
        </View>
            {
                defined_set_list.length > 0 && <Sep height={20} />
            }
        </>
    )
}

const CategoryList = props => {
    const { config, categories, actions: { getCategories } } = useContext(StoreContext);

    useEffect(() => {
        getCategories(config);
    }, [])

    return (
        <ScrollView style={{alignSelf:'stretch'}} contentContainerStyle={{alignSelf:'stretch'}}>
            {
                categories.map((c,i) => <Category index={i} depth={0} key={i} category={c} />)
            }
        </ScrollView>
    )
}

const StorageRow = props => {
    const { register } = useContext(IfEventContext);
    const { filters_as: lang } = useContext(LanguageContext)
    const { config, actions: { setStoreParams: params } } = useContext(StoreContext);

    const doCheck = (key, checked) => {
        register('storage_toggle', {[key]: checked});
        params({cas: {...config.cas, [key]: checked ? {key, comp: '=', val: 'true'} : undefined}, offser: 0})
    };
    const isChecked = key => config?.cas?.[key]?.val === 'true';

    return (
        <View style={{marginTop: 10}}>
            <Txt style={{fontSize: 11}}>{lang.include}</Txt>
            <View style={{paddingTop: 5}}>
                {
                    ['ambient', 'frozen', "seasonal", "catering", "chilled"].map((s, i) => {
                        const checked = isChecked(s.toLowerCase());
                        return (
                        <View style={{alignSelf:'stretch', flexDirection:'row', justifyContent:'space-between', alignItems:'center', padding: 5, paddingLeft: 0, paddingRight:0}}>
                            <Txt>{lang[s]}</Txt>
                            <Input checked={checked} onChange={() => doCheck(s.toLowerCase(), !checked)} />
                        </View>)
                    })
                }
            </View>
        </View>
    )
}

const Stock = props => {
    const { register } = useContext(IfEventContext)
    const { filters_as: lang } = useContext(LanguageContext)
    const { actions: { setStoreParams }, config } = useContext(StoreContext);

    const toggle = () => {
        const new_checked = !checked;
        setStoreParams({stock: new_checked});
        register('toggle_stock', {stock: new_checked});
    }

    const checked = config?.stock;
    return (
        <TouchableOpacity  onPress={toggle} style={{alignSelf:'stretch', alignItems:'center', justifyContent:"space-between", padding: 5, paddingLeft: 0, paddingRight: 0, flexDirection:'row'}}>
            <Txt>{lang.in_stock}</Txt>
            <View>
                <Input checked={checked} onChange={toggle} />
            </View>
        </TouchableOpacity>
    )
}

const Faves = props => {
    const { register } = useContext(IfEventContext)
    const { actions: { setStoreParams }, config, fave_count } = useContext(StoreContext);
    const checked = config?.faves_only;

    const toggle = () => {
        const new_checked = !checked;
        setStoreParams({faves_only: new_checked});
        register('toggle_faves', {faves_only: new_checked});
    }

    return (
        <TouchableOpacity  onPress={toggle} style={{alignSelf:'stretch', alignItems:'center', justifyContent:"space-between", padding: 5, paddingLeft:0, paddingRight:0, flexDirection:'row'}}>
            <Txt>Favourites{fave_count > 0 ? ` (${fave_count})` : ''}</Txt>
            <View>
                <Input type="checkbox" checked={checked} onChange={toggle} />
            </View>
        </TouchableOpacity>
    )
}

const SearchF = props => {
    return (
        <View style={{alignSelf:'stretch', flexDirection:'row'}}>
            <Search sidebar />
        </View>
    )
}

const Unlisted = props => {
    const { user } = useContext(UserContext);
    const { register } = useContext(IfEventContext);
    const { actions: { setStoreParams }, config, fave_count } = useContext(StoreContext);
    const checked = config?.show_unlisted;

    const toggle = () => {
        const new_checked = !checked;
        setStoreParams({show_unlisted: new_checked});
        register('toggle_unlisted', {show_unlisted: new_checked});
    }

    if (!user?.is_rep) return null
    return (
        <TouchableOpacity  onPress={toggle} style={{alignSelf:'stretch', alignItems:'center', justifyContent:"space-between", padding: 5, paddingLeft:0, paddingRight: 0, flexDirection:'row'}}>
            <Txt>Show Unlisted</Txt>
            <View>
                <Input onChange={toggle} checked={checked} />
            </View>
        </TouchableOpacity>
    )
}

const Sort = props => {
    const { actions: { setStoreParams }, config } = useContext(StoreContext);

    const is_sales = config?.sort === 'sales';

    return (
        <View style={{alignSelf:'stretch'}}>
            <Txt style={{fontSize: 11}}>Sort Products</Txt>
            <TouchableOpacity onPress={() => setStoreParams({sort:"sales", reverse: true, offset: 0})} style={{alignItems:'center', flexDirection:'row', alignSelf:'stretch', justifyContent:'space-between'}}>
                <Txt>Bought Frequently</Txt>
                <View style={{padding: 5}}>
                    <Input onChange={toggle} checked={is_sales} />
                </View>
            </TouchableOpacity>

            <TouchableOpacity onPress={() => setStoreParams({sort:"description", reverse: false, offset: 0})} style={{alignItems:'center', flexDirection:'row', justifyContent:'space-between', alignSelf:'stretch'}}>
                <Txt>A-Z</Txt>
                <View style={{padding: 5}}>
                    <Input onChange={toggle} checked={is_sales} />
                </View>
            </TouchableOpacity>
        </View>
    )
}

const LanguageBit = props => {
    const language = useContext(LanguageContext);
    const { front } = useContext(StoreContext);

    const { language_name, setLanguage } = language;
    if (!front) return null;
    if (front.primary_domain !== 'staging.albanyportugal.com' && front.primary_domain !== 'albanyportugal.com') return null;
    
    return (
        <View>
            <Txt style={{fontSize: 18, fontWeight: 'bold', marginBottom: 5}}>Language</Txt>
            <TouchableOpacity onPress={() => setLanguage('english')} style={{alignSelf:'stretch', flexDirection:'row', marginBottom: 5}}>
                <Txt style={{color: language_name === 'English' ? Colours.orange : 'black'}}>🇬🇧 English</Txt>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => setLanguage('portuguese')} style={{alignSelf:'stretch', flexDirection:'row'}}>
                <Txt style={{color: language_name === 'Portuguese' ? Colours.orange : 'black'}}>🇵🇹 Portuguese</Txt>
            </TouchableOpacity>
        </View>
    )
}

export const Filters = props => {
    const { register } = useContext(IfEventContext);
    const { filters_as: lang } = useContext(LanguageContext);
    const { token, customer } = useContext(UserContext);
    const { actions: { getPlof }} = useContext(StoreContext);
    const { actions: { uploadPlof }} = useContext(BasketContext);

    const { user } = useContext(UserContext);
    const { width } = useWindowDimensions();
    const is_mobile = width <= 768;

    return (
        <View style={{alignSelf:'stretch', flex: 1, alignItems:'center', backgroundColor:'white', padding: is_mobile ? 0 : 10}}>
            {
                !is_mobile &&
                <View style={{alignSelf:'stretch'}}>
                    <Txt style={{fontSize: 18, fontWeight: 'bold', marginBottom: 5}}>Search</Txt>
                    <SearchF />
                </View>
            }
            <View style={{flex: 1, alignSelf:'stretch'}}>
                <Txt style={{fontSize: 18, fontWeight: 'bold'}}>{lang.filters}</Txt>
                <StorageRow />
                <Sep height={20} />
                <Stock />
                {
                    user && 
                    <>
                        <>
                            <Sep height={20} />
                            <Faves />
                        </>
                        {
                            user?.is_rep &&
                            <>
                                <Sep height={20} />
                                <Unlisted />
                            </>

                        }
                    </>
                }
                
                <View style={{alignSelf:'stretch', height: 20, justifyContent:'center'}}>
                    <Sep />
                </View>

                <DefinedSets />
                <CategoryList />
                <LanguageBit />

                {
                user && customer && !is_mobile &&
                    <>
                        <View style={{alignSelf:'stretch', padding: 10}}>
                        <a onPress={() => register('get_plof', {})} style={{textDecoration: 'none'}} href={`${api_url}/c/store/plof?t=${token}&c=${customer.id}`}>
                            <Txt style={{color: Colours.orange, fontWeight:'bold'}}>Download Order Form</Txt>
                        </a>
                        </View>
                        <View style={{alignSelf:'stretch', padding: 10}}>
                            <label htmlFor="plof-upload">
                                <Txt style={{color: Colours.orange, fontWeight:'bold', cursor: 'pointer'}}>Upload Order Form</Txt>
                            </label>
                        </View>
                        <input style={{display:'none'}} name="plof-upload" id="plof-upload" type="file" onChange={uploadPlof} />
                    </>
                }
                {
                    width <= 768 &&
                    <AddressAndContact />
                }
            </View>
        </View>
    )
}

const FiltersAs = props => {
    const { height } = useWindowDimensions();
    const { actions: { viewFilters }, viewing_filters: visible } = useContext(StoreContext);
    
    const close = () => viewFilters(false);

    return (
        <ActionSheet
            height={height * 0.9}
            visible={visible}
            close={close}
            cred="FILTS"
            >
            <Filters />
        </ActionSheet>
    )
}

export default FiltersAs;
import { createContext, useContext, useState, useEffect } from "react";
import { api_url } from "../config";
import { UserContext } from "./user";
import FullScreenNumberInput from "../components/full_screen_num_input";
import { StoreContext } from "./store";

export const RepOffersContext = createContext({})


const SetSellprice = (props) => {
    const { setting_sell, actions: {setSell, pushOffer } } = useContext(RepOffersContext);
    const { products } = useContext(StoreContext);
    const { user } = useContext(UserContext);
    const [price, set_price] = useState("");

    useEffect(() => {
        if (setting_sell) {
            const default_sell = products?.[setting_sell.product_id]?.offers?.find(x => x.id === setting_sell.id)?.price;
            if (default_sell) {
                set_price(default_sell.toFixed(2))
                return
            }
            set_price((setting_sell.cost / 0.8).toFixed(2));
        } else {
            set_price("");
        }
    }, [setting_sell])

    const submit = async (price) => {
        const new_price = parseFloat(price);
        if (isNaN(new_price)) return;

        const instr = {
            ...setting_sell,
            sell: new_price
        };

        const ok = await pushOffer(instr);
        if (ok) {
            setSell(null)
        }
    }

    const visible = setting_sell !== null;
    if (!visible || !(user?.is_rep)) return null;

    return (
        <FullScreenNumberInput
            visible={user?.is_rep && visible}
            value={price}
            onChange={p => submit(p)}
            close={() => setSell(null)}
            />
        
    )
}


const RepOffersWrapper = props => {
    const { user, token } = useContext(UserContext);
    const [offers, set_offers] = useState({});
    const [setting_sell, set_setting_sell] = useState(null);

    const addOffers = new_offers => {
        set_offers(offs => ({...offs, ...new_offers}));
    }

    const getOffers = async (ids) => {
        if (!user?.is_rep) return
        if (ids.length === 0) return;
        const resp = await fetch(`${api_url}/r/offers/get?ids=${ids.join("%2B")}`,
        {
            method: "GET",
            headers: {
                "authorization": `Bearer ${token}`
            }
        });
        if (resp.ok) {
            const body = await resp.json();
            const ofs = body.data.data;
            addOffers(ofs)
        }
    }

    const pushOffer = async (instr) => {
        if (!user?.is_rep) return;
        const resp = await fetch(`${api_url}/r/offers/push`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${token}`
            },
            body: JSON.stringify(instr)
        });
        if (resp.ok) {
            const body = await resp.json();
            const new_offer = body.data;
            addOffers({[new_offer.id]: new_offer})
            return new_offer
        }
        return null
    }

    const getRequiredOffers = (ids) => {
        if (!user?.is_rep) return
        const need = ids.filter(x => !(x in offers));
        getOffers(need)
    }

    const setSell = offer => set_setting_sell(offer);

    return (
        <RepOffersContext.Provider value={{
            actions: {
                addOffers,
                getOffers,
                getRequiredOffers,
                setSell,
                pushOffer
            },
            offers,
            setting_sell
        }}>
            {props.children}
            <SetSellprice />
        </RepOffersContext.Provider>
    )
}

export default RepOffersWrapper;
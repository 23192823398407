import { useContext, useEffect, useState } from "react";
import { useWindowDimensions, View, ScrollView } from "react-native";
import { LanguageContext } from "../contexts/language";
import { UserContext } from "../contexts/user";
import ActionSheet from "./action_sheet";
import Button from "./button";
import TxtInput from "./txt_input";
import * as Colours from '../colours';
import Txt from "./txt";
import { AlertContext } from "../contexts/alert";

//LANGUAGE

const fields = ({apply_as: lang}, invited_email) => [
    {
        name: 'first_name',
        label: lang.first_name,
        value: '',
        blurred: false,
        error: null,
        inputProps: {autoCapitalize: 'words', autoComplete: 'name-given', autoFocus: true, maxLength: 100, returnKeyType: 'next', textContentType: "givenName"},
        validate: (v) => {
            if (!v || v.length === 0) {
                return lang.required
            }
            return null
        }
    },
    {
        name: 'last_name',
        label: lang.last_name,
        value: '',
        blurred: false,
        error: null,
        inputProps: {autoCapitalize: 'words', autoComplete: 'name-family', maxLength: 100, returnKeyType: 'next', textContentType: "familyName"},
        validate: (v) => {
            if (!v || v.length === 0) {
                return lang.required
            }
            return null
        }
    },
    {
        name: 'email_address',
        label: lang.email_address,
        value: invited_email,
        blurred: false,
        error: null,
        inputProps: {autoCapitalize: 'none', autoComplete: 'email', maxLength: 100, returnKeyType: 'next', textContentType: "emailAddress", keyboardType:'email-address'},
        validate: (v) => {
            if (!v || v.length === 0) {
                return lang.required
            }
            return null
        }
    }
]

const InvitedAs = props => {
    const lang = useContext(LanguageContext);
    const { height } = useWindowDimensions();
    const { actions: { showInvitedAs, acceptInvite }, invited_as, token, user } = useContext(UserContext);
    const [application, set_application] = useState(fields(lang, invited_as));
    const [loading, set_loading] = useState(false);
    const { actions: { setAlert }} = useContext(AlertContext);

    const update = (i, v) => {
        set_application([
            ...application.slice(0, i),
            {
                ...application[i],
                value: v,
                error: application[i].blurred ? application[i].validate(v) : application[i].error
            },
            ...application.slice(i + 1)
        ]);
    }

    const blur = i => {
        set_application([
            ...application.slice(0, i),
            {
                ...application[i],
                blurred: true,
                error: application[i].validate(application[i].value)
            },
            ...application.slice(i+1)
        ]);
    }

    const close = () => {
        showInvitedAs(null);
        set_application(fields(lang, invited_as));
    }

    const instr = () => {
        if (application.some(x => x.validate(x.value) !== null)) {
            return { instruction: null, ok: false }
        }
        return {
            instruction: {
                first_name: application[0].value,
                last_name: application[1].value,
                email_address: application[2].value
            },
            ok: true
        }
    }

    const submit = async () => {
        const { ok, instruction } = instr();
        if (!ok) return;

        set_loading(true);

        const response = await acceptInvite(instruction);
        if (!response) {
            close();
            setAlert({
                title: "Account Created",
                body: "Your Account has been created. You will receive an email with instructions for how to create a password and log in"
            });

        } else {
           const error_data = response?.error?.data?.data;
           if (error_data?.first_name) {
            set_application([
                {
                    ...application[0],
                    error: error_data.first_name
                },
                ...application.slice(1)
            ])
           }
           if (error_data?.last_name) {
            set_application([
                application[0],
                {
                    ...application[1],
                    error: error_data.last_name
                }
            ])
           }
           if (error_data?.email_address) {
            set_application([
                ...application.slice(0, 2),
                {
                    ...application[2],
                    error: error_data.email_address
                }
            ])
           }
        }

        set_loading(false);
    }

    useEffect(() => {
        if (user) {
            close();
        }
    }, [user])

    return (
        <ActionSheet
            height={height * 0.6}
            close={close}
            visible={invited_as !== null}
            cred="INVITED"
            title={`${lang.apply_as.apply_for_account}`}
        >
            <View style={{flex: 1, alignSelf:'stretch', marginTop: 10, alignItems:'center'}}>
                <View style={{flex: 1, alignSelf:'stretch'}}>
                        <ScrollView>
                            <View style={{flex: 1, justifyContent:'space-evenly', alignItems:'stretch', alignSelf:'stretch'}}>
                                <View style={{alignSelf:'stretch'}}>
                                    {
                                        application.map((field, i) => {
                                            const border_props = field.error ? {borderWidth: 2, borderColor: 'red'} : {};
                                            return (
                                                <View key={i}>
                                                    <View style={{alignSelf:'stretch', justifyContent:'space-between', alignItems:'center', marginBottom: 2, flexDirection:'row'}}>
                                                        <Txt style={{fontSize: 12}}>{field.label}</Txt>
                                                        <Txt style={{fontSize: 12, color: 'red'}}>{field.error}</Txt>
                                                    </View>

                                                    <TxtInput
                                                        value={field.value} 
                                                        onChange={v => update(i, v)}
                                                        can_clear
                                                        height={40}
                                                        onBlur={() => blur(i)}
                                                        onClear={() => update(i, null)}
                                                        style={{marginBottom: 10, borderWidth: 1, borderColor:Colours.light_grey, ...border_props}}
                                                        inputStyle={{fontSize: 11}}
                                                        inputProps={{...field.inputProps, onSubmitEditing: e => console.log(e)}}
                                                        />
                                                </View>
                                            )
                                        })
                                    }
                                </View>
                            </View>
                        </ScrollView>
                    <View style={{alignSelf:'stretch', flexDirection:'column', marginBottom: 30, marginTop: 20}}>
                        <Button loading={loading} onPress={submit}>{lang.apply_as.submit}</Button>
                    </View>
                </View>

            </View>
        </ActionSheet>
    )
}

export default InvitedAs;
import { createContext, useEffect, useState } from "react";
import { FlatList, TouchableOpacity, useWindowDimensions, View } from "react-native";
import ActionSheet from "./action_sheet";
import Sep from "./sep";
import { Entypo } from '@expo/vector-icons';
import Button from "./button";
import * as Colours from '../colours';
import Txt from "./txt";

export const SelectContext = createContext({});

const Option = props => {
    const { label, value } = props.option;
    const { selected, select, last } = props;

    const checked = value === selected;

    return (
        <>
        <TouchableOpacity onPress={() => select(value)} style={{alignSelf:'stretch', flexDirection:"row", justifyContent:"space-between", alignItems:'center'}}>
            <Txt style={{fontWeight:'bold'}}>{label}</Txt>
            <View style={{padding: 5}}>
                <Entypo name="check" size={18} color={checked ? Colours.orange : Colours.grey} />
            </View>
        </TouchableOpacity>
        {
            !last && 
            <View style={{height: 10, justifyContent:'center'}}>
                <Sep />
            </View>
        }
    </>
    )
}

const Selection = props => {
    const { close } = props;
    const [selected, set_selected] = useState(props.selected)

    useEffect(() => {
        set_selected(props.selected);
    }, [props.selected]);

    const doSelect = v => {
        set_selected(v);
        props.selection.callback(v);
    }
    
    if (!props.selection) return null;
    const { title, body, options } = props.selection;
    return (
        <View style={{alignSelf: 'stretch', flex: 1}}>
            {title && <Txt style={{fontSize: 18, fontWeight: 'bold'}}>{title}</Txt>}
            {body && <Txt style={{marginTop: 10, marginBottom: 20}}>{body}</Txt>}

            <FlatList
                data={options}
                keyExtractor={(_, i) => i.toString()}
                renderItem={({item: option, index}) => <Option option={option} last={index === options.length -1} selected={selected} select={doSelect} />}
                />
            <Button onPress={close} style={{marginBottom: 30}}>OK</Button>
        </View>
    )
}

const SelectWrapper = props => {
    const { height } = useWindowDimensions();
    const [selection, set_selection] = useState(null);

    const close = () => set_selection(null);

    return (
        <SelectContext.Provider value={{selection, actions: {setSelection: set_selection}}}>
            {props.children}
            <ActionSheet
                height={height * 0.6}
                close={close}
                visible={selection !== null}
                cred="SELECT"
            >
                {
                    selection && <Selection close={close} selection={selection} />
                }
            </ActionSheet>
        </SelectContext.Provider>
    )
}

export default SelectWrapper
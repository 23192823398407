import { useRef } from "react";
import { useContext } from "react";
import { useEffect, useState } from "react";
import { View, Platform, ActivityIndicator } from "react-native";
import Image from '../../image';
import * as Colours from '../../colours';
import { TempImageContext } from "../../contexts/temp_image";
import { Blurhash } from "react-blurhash";

const pad = ean => {
    while (ean.length < 13) {
        ean = "0" + ean;
    }
    return ean;
}

export const MultiProductImage = props => {
    const {eans, size} = props;
    const [use_eans, set_use_eans] = useState([]);
    const [root, set_root] = useState(null);

    useEffect(() => {
        if (eans.length > 0) {
            if (eans.length > 6) {
                set_use_eans(eans.slice(0, 9));
                set_root(3)
                return
            }
            if (eans.length >= 3) {
                set_use_eans(eans.slice(0, 4))
                set_root(2)
                return
            }
            if (eans.length > 1) {
                set_use_eans([eans[0], null, null, eans[1]]);
                set_root(2)
                return
            }
            set_use_eans([eans[0]])
            set_root(1)
        }
    }, [eans])

    return (
        <View style={{backgroundColor:'white', width: size, height: size}}>
            <View style={{position:'relative', flexDirection:'row', flexWrap:'wrap', width: size, height: size}}>
                {
                    use_eans.map((ean, i) => {
                        if (ean) {
                            return <Image key={i}
                                source={{uri: `https://alb-product-images.s3.eu-west-1.amazonaws.com/${pad(ean)}_large.jpg`}}
                                style={{height: size / root, width: size / root}}
                                />
                        }
                        return (<View key={i} style={{width: size / root, height: size / root}} />)
                    })
                }
            </View>
        </View>
    )
}

const ProductImg = props => {
    const { product, size, loading } = props;
    const [image_url, set_image_url] = useState(null);
    const { refresh_eans } = useContext(TempImageContext);
    const current_refresh_tick = useRef(refresh_eans?.[product?.ean] || null);
    const [image_loaded, set_image_loaded] = useState(false);


    useEffect(() => {
        if (product) {
            if (product.ean) {
                if (product.ean in refresh_eans) {
                    const new_tick = refresh_eans[product.ean];
                    if (new_tick !== current_refresh_tick.current) {
                        current_refresh_tick.current = new_tick;
                        setImageUrl();
                    }
                }
            }
        }
    }, [refresh_eans]);

    const setImageUrl = () => {
        if (product?.ean) {
            set_image_url(`https://alb-product-images.s3.eu-west-1.amazonaws.com/${pad(product.ean)}_large.jpg`);
        }
    }

    useEffect(() => {
        if (!Array.isArray(product)) {
            setImageUrl()
        }
    }, [product]);

    if (Array.isArray(product)) {
        return <MultiProductImage eans={product} size={size} />
    }

    const tick = product?.img_tick || 0;
    
    return (
        <View style={{backgroundColor:image_url ? 'white' : Colours.light_grey, width: size, height: size, justifyContent:'center', alignItems: "center"}}>
            {
                loading? 
                <ActivityIndicator size={30} color={Colours.purple} />
                :

                image_url ?
                <Image
                    onLoadEnd={() => set_image_loaded(true)}
                    source={{uri: `${image_url}?t=${tick}`}}
                    style={{width: size, height: size}}
                    />
                :
                null
            }
            {
                product?.blurhash && !image_loaded &&
                <View style={{position:'absolute', top: 0, right: 0, left: 0, bottom: 0}}>
                    <Blurhash
                        hash={product.blurhash}
                        width={size}
                        height={size}
                        />
                </View>
            }
        </View>
    )
}

export default ProductImg;
import { useContext } from "react";
import { useWindowDimensions, View, FlatList, TouchableOpacity } from "react-native";
import ActionSheet from "./action_sheet";
import Sep from "./sep";
import { Entypo } from '@expo/vector-icons';
import { UserContext } from "../contexts/user";
import { LanguageContext } from "../contexts/language";
import * as Colours from '../colours';
import Button from "./button";
import Txt from "./txt";
//LANGUAGE

const CustomersAs = props => {
    const { height } = useWindowDimensions();
    const { customers_as: lang } = useContext(LanguageContext);
    const { customer_modal_showing: visible, customers, customer, actions: { showCustomerModal, setCustomer, showAddCustomer } } = useContext(UserContext);
    const close = () => showCustomerModal(false);
    const select = (c) => setCustomer(c);

    const add = () => {
        showCustomerModal(false);
        showAddCustomer(true);
    }

    return (
        <ActionSheet
            height={height * 0.7}
            close={close}
            visible={visible}
            cred="CUSTS"
            title={lang.stores}
        >
            <View style={{flex: 1, alignSelf:'stretch', marginTop: 10, alignItems:'center'}}>
                <View style={{flex: 1, alignSelf: 'stretch'}}>
                    <FlatList
                        data={customers}
                        keyExtractor={(_, i) => i.toString()}
                        renderItem={({item, index}) => {
                            const checked = customer?.id === item.id;
                            return (
                                <>
                                    <TouchableOpacity onPress={() => select(item)} style={{alignSelf:'stretch', flexDirection:"row", justifyContent:"space-between", padding: 8}}>
                                        <View style={{flex: 1, flexWrap:'wrap'}}>
                                            <Txt style={{fontWeight:'bold', color:'rgb(80, 80, 80)', flexWrap:'wrap'}}>{item.name}</Txt>
                                            <Txt style={{color:"rgb(100, 100, 100)"}}>{lang.customer} {item.human_ref}</Txt>
                                            <Txt style={{color:'rgb(100, 100, 100)'}}>{lang.reg}{item.reg_no}</Txt>
                                        </View>
                                        <View style={{padding: 5, width: 30, alignSelf:'stretch', justifyContent:'center', alignItems:'center'}}>
                                            <Entypo name="check" size={24} color={checked ? Colours.orange : Colours.grey} />
                                        </View>
                                    </TouchableOpacity>
                                    {
                                        index < customers.length-1 && 
                                        <View style={{height: 10, justifyContent:'center'}}>
                                            <Sep />
                                        </View>
                                    }
                                </>
                            )
                        }}
                        />
                    <View style={{paddingBottom: 30, paddingTop: 10}}>
                        <Button onPress={add}>Add a Store</Button>
                    </View>
                </View>

            </View>
        </ActionSheet>
    )
}

export default CustomersAs;
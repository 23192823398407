import { View, TouchableOpacity } from "react-native";
import ProductImg from "../storefront/product_img";
import Sep from "./sep";
import * as Colours from '../colours';
import Txt from "./txt";

const IfPress = props => {
    if (props.onPress) {
        return <TouchableOpacity onPress={props.onPress}>{props.children}</TouchableOpacity>
    }
    return <View>{props.children}</View>
}

const ListRow = props => {
    const { product_for_image, title, subtitle, key_values, last, children, onPress, style } = props;
    return (
        <>
            <IfPress onPress={onPress}>
                <View style={{alignSelf:'stretch', flexDirection:'row', ...(style || {})}}>
                    {
                        product_for_image && <View style={{marginRight: 5}}><ProductImg size={80} product={product_for_image} /></View>
                    }
                    <View style={{flex: 1}}>
                        <Txt style={{fontWeight:'bold', color: Colours.dark_grey, fontSize: 13}}>{title}</Txt>
                        {subtitle && <Txt style={{fontWeight:'bold', color: Colours.grey, fontSize: 12}}>{subtitle}</Txt>}
                        <View style={{flex: 1}}>{children}</View>
                        {
                            key_values &&
                            <View style={{alignSelf:'stretch', flexDirection:'row', marginBottom: 10, alignItems:'center', justifyContent:'space-between'}}>
                                {
                                    key_values.map((kv, i) => <Txt style={{fontSize: 12, color:Colours.dark_grey}} key={i}>{kv.key} {kv.value}</Txt>)
                                }
                            </View>
                        }
                    </View>
                </View>
            </IfPress>
            {!last && <Sep height={20} />}
        </>
    )
}
export default ListRow;
import { createContext, useContext, useEffect, useState } from "react";
import { api_url } from "../config";

import { Keyboard, Platform } from "react-native";
import { LanguageContext } from "./language";
import { AlertContext } from "./alert";
import * as SS from 'expo-secure-store';

export const UserContext = createContext({});

const UserWrapper = props => {
    const { children } = props;
    const { user_context: lang } = useContext(LanguageContext)

    const [user, set_user] = useState(null);
    const [token, set_token] = useState(null);
    const [logged_in, set_logged_in] = useState(false);
    const [customers, set_customers] = useState(null);
    const [customer, set_customer] = useState(null);
    const [login_modal_showing, set_login_modal_showing] = useState(false);
    const [customer_modal_showing, set_customer_modal_showing] = useState(false);
    const [did_do_init, set_did_do_init] = useState(false);
    const [apply_modal_showing, set_apply_modal_showing] = useState(false);
    const [account_modal_showing, set_account_modal_showing] = useState(false);
    const [forgot_modal_showing, set_forgot_modal_showing] = useState(false);
    const [create_password_showing, set_create_password_showing] = useState(null);
    const [reset_password_showing, set_reset_password_showing] = useState(false);
    const [invited_as, set_invited_as] = useState(null);
    const [add_customer_showing, set_add_customer_showing] = useState(false);
    const { actions: { setAlert }} = useContext(AlertContext);
    const [ws_session, set_ws_session] = useState(null);

    const showAddCustomer = open => set_add_customer_showing(open);

    const showInvitedAs = email => {
        set_invited_as(email);
    }

    const showResetPassword = open => {
        set_reset_password_showing(open);
    }
    const showCreatePassword = create_token => {
        set_create_password_showing(create_token);
    }
    const showLoginModal = open => {
        Keyboard.dismiss();
        set_login_modal_showing(open);
    }
    const showApplyModal = open => {
        Keyboard.dismiss();
        set_apply_modal_showing(open);
    }
    const showCustomerModal = open => {
        Keyboard.dismiss();
        set_customer_modal_showing(open);
    }
    const showAccountModal = open => {
        Keyboard.dismiss();
        set_account_modal_showing(open);
    }
    const showForgotModal = open => {
        Keyboard.dismiss();
        set_forgot_modal_showing(open)
    }

    useEffect(() => {
        getLocalToken();
    }, []);

    useEffect(() => {
        if (token) {
            getCustomers();
            getUser();
        }
    }, [token])

    useEffect(() => {
        if (customer && user) {
            set_did_do_init(true)
        }
    }, [customer, user])

    const getLocalToken = async() => {
        let tkn = null;
        if (Platform.OS === 'web') {
            tkn = localStorage.getItem('token');
        } else {
            tkn = await SS.getItemAsync('token');
        }

        if (tkn) {
            set_token(tkn)
        } else {
            set_did_do_init(true);
        }
    }

    const closeAccount = async () => {
        const resp = await fetch(`${api_url}/c/user/close`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        if (resp.ok) {
            setAlert({title: 'Account Closed', body: "We've Closed your account", onConfirm: logout, onCancel: logout});
        }
    }

    const logout = async () => {
        if (Platform.OS === 'web') {
            localStorage.removeItem('token')
        } else {
            await SS.deleteItemAsync('token');
        }

        set_token(null);
        set_customer(null);
        set_customer_modal_showing(false);
        set_customers(null)
        set_user(null);
        set_account_modal_showing(false);
    }

    const acceptInvite = async (instr) => {
        const res = await fetch(`${api_url}/invited`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(instr)
        });
        if (res.ok) {
            return null;
        }
        const body = await res.json();
        return body
    }

    const createPassword = async (password) => {
        const res = await fetch(`${api_url}/c/set_password`, {
            method:"POST",
            headers: {
                "authorization": `Bearer ${create_password_showing}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({password})
        });

        const body = await res.text();
        if (res.ok) {
            set_create_password_showing(null);
            set_login_modal_showing(true);
            setAlert({title: lang.password_resent, body: lang.password_has_been});
        } else {
            if (body.indexOf("Not Authorized") !== -1) {
                set_create_password_showing(null);
                set_forgot_modal_showing(true);
                setAlert({title: lang.invalid_token, body: lang.restart});

            } else {
                setAlert({title: lang.something_went_wrong, body: lang.try_again});
            }
        }
    }

    const login = async ({email_address, password}) => {
        const res = await fetch(`${api_url}/login`, {
            method: "POST",
            headers: {
                'Content-Type': "application/json"
            },
            body: JSON.stringify({ email_address, password })
        })

        if (res.ok) {
            const tkn = await res.text();
            if (tkn !== '') {
                if (Platform.OS !== 'web') {
                    await SS.setItemAsync('token', tkn)
                } else {
                    localStorage.setItem('token', tkn);
                }
                set_token(tkn);
                getCustomers();
                getUser();
                return true;
            }

        }
        return false
    }

    const forgotPassword = async (email_address) => {
        const res = await fetch(`${api_url}/forgot`, {
            method:"POST",
            headers: {
                "Accept": 'application/json',
                "Content-Type": 'application/json',
            },
            body: JSON.stringify({
                email: email_address
            })
        });
        return res.ok
    }

    const getCustomers = async () => {
        try {
            const res = await fetch(`${api_url}/c/user/customers`, {
                method: 'GET',
                headers: {
                    'Accept': "application/json",
                    "authorization": `Bearer ${token}`
                }
            });
            if (res.status !== 200) return;
            const body = await res.json();
            const customers = body.data;
            if (customers.length > 0) {
                set_customer(customers[0])
                set_customers(customers);
            } else {
                set_did_do_init(true);
            }
        } catch(e) {
            set_did_do_init(true);
        }
    }

    const apply = async (instr) => {
        const res = await fetch(`${api_url}/c/apply`, {
            method: "POST",
            headers: {
                "Accept": 'application/json',
                "Content-Type": "application/json",
            },
            body: JSON.stringify(instr)
        });
        if (res.ok) {
            const body = await res.json();
            return body
        }
        const text = await res.text();
    }

    const getUser = async () => {
        try {
            const res = await fetch(`${api_url}/c/user`, {
                method: 'GET',
                headers: {
                    "Accept": "application/json",
                    'authorization': `Bearer ${token}`
                }
            });
            if (res.status === 200) {
                const body = await res.json();
                set_user(body.data);
            } else {
                set_did_do_init(true);
                const body = await res.text();
            }
        } catch(e) {
            set_did_do_init(true);
        }
    }

    const resetPassword = async (current_password, new_password) => {
        try {
            const res = await fetch(`${api_url}/c/user/reset_password`, {
                method:"POST",
                headers: {
                    "Accept": "application/json",
                    "authorization": `Bearer ${token}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({current_password, new_password})
            });
            if (res.status === 200) {
                return null
            } else {
                const results = await res.json();
                return results
            }
        } catch(e) {
        }
    }

    const setCustomer = async (c) => {
        set_customer(c)
    }

    return (
        <UserContext.Provider value={{
            user,
            token,
            customers,
            customer,
            login_modal_showing,
            customer_modal_showing,
            apply_modal_showing,
            did_do_init,
            forgot_modal_showing,
            account_modal_showing,
            create_password_showing,
            reset_password_showing,
            add_customer_showing,
            ws_session,
            invited_as,
            actions: {
                set_ws_session,
                closeAccount,
                showResetPassword,
                acceptInvite,
                showCreatePassword,
                showForgotModal,
                showAccountModal,
                showApplyModal,
                getCustomers,
                getUser,
                showLoginModal,
                showCustomerModal,
                setCustomer,
                login,
                apply,
                logout,
                forgotPassword,
                createPassword,
                resetPassword,
                showInvitedAs,
                showAddCustomer
            }
        }}>
            {did_do_init ? children : false}
        </UserContext.Provider>
    )
}

export default UserWrapper;
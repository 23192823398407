import { TouchableOpacity, ActivityIndicator } from "react-native";
import * as Colours from '../colours';
import Txt from "./txt";

const Button = ({children, onPress, style, textStyle, loading, disabled}) => {

    const doPress = () => {
        if (!disabled) onPress();
    }
    return (
        <TouchableOpacity onPress={doPress} style={{height: 40, alignSelf:'stretch', borderRadius: 5, backgroundColor:disabled ? Colours.light_grey : Colours.orange, padding: 5, justifyContent:'center', alignItems:"center", ...(style||{})}}>
            {
                loading ? <ActivityIndicator color='white' size={20} />
                :
                <Txt style={{fontSize: 18, color:'white', fontWeight:'bold', ...(textStyle || {})}}>{children}</Txt>
            }
        </TouchableOpacity>
    )
}

export default Button;
import { useContext, useEffect } from "react";
import { useWindowDimensions, View } from "react-native";
import { UserContext } from "../contexts/user";
import ActionSheet from "./action_sheet";
import InfoRow from './info_table';
import Button from "./button";
import { OrdersContext } from "../contexts/orders";
import { LanguageContext } from "../contexts/language";
import * as Colours from '../colours';
import Txt from "./txt";
import { ApplicationsContext } from "../contexts/applications_rep";
import { RepReportsContext } from "../contexts/rep_reports";
import { AlertContext } from "../contexts/alert";
//LANGUAGE

const AccountAs = () => {
    const { height, width } = useWindowDimensions();
    const { actions: { setAlert } } = useContext(AlertContext);
    const lang = useContext(LanguageContext);
    const { actions: { showAccountModal, showResetPassword, logout, closeAccount }, account_modal_showing: visible, token, user } = useContext(UserContext);
    const { actions: { showOrdersModal } } = useContext(OrdersContext)
    const { actions: { setViewingApps }} = useContext(ApplicationsContext)
    const { overview_visible, actions: { showOverview } } = useContext(RepReportsContext)
    const close = () => showAccountModal(false);

    useEffect(() => {
        if (user) {
            close();
        }
    }, [user])

    const viewOrders = () => {
        showAccountModal(false);
        showOrdersModal(true);
    }

    const reset = () => {
        showAccountModal(false);
        showResetPassword(true);
    }

    const viewApps = () => {
        if (user?.is_rep) {
            showAccountModal(false);
            setViewingApps(true);
        }
    }

    const overview = () => {
        if (user?.is_rep) {
            showAccountModal(false);
            showOverview(true);
        }
    }

    const closeAc = () => {
        setAlert({
            title:"Close Your Account?",
            body: "This will permanently close your account. To place further orders with us, you will need to re-apply - continue?",
            onConfirm: closeAccount,
            onCancel: () => {}
        });
    }

    return (
        <ActionSheet
            height={height * 0.6}
            close={close}
            visible={visible}
            cred="ACCT"
            title={lang.account_as.your_account}
        >
            <View style={{flex: 1, alignSelf:'stretch', marginTop: 10, alignItems:'center'}}>
                <View style={{flex: 1, alignSelf:'stretch'}}>                    
                    <View style={{flex: 1}}>
                        <InfoRow label={lang.account_as.first_name}><Txt style={{fontSize: 14}}>{user?.first_name}</Txt></InfoRow>
                        <InfoRow label={lang.account_as.last_name}><Txt style={{fontSize: 14}}>{user?.last_name}</Txt></InfoRow>
                        <InfoRow label={lang.account_as.email_address}><Txt style={{fontSize: 14}}>{user?.email_address}</Txt></InfoRow>
                        <InfoRow label={lang.account_as.reset_password}><Txt onPress={reset} style={{fontSize: 14, fontWeight: 'bold', color:Colours.orange}}>{lang.account_as.reset_password}</Txt></InfoRow>
                        <InfoRow label={lang.account_as.your_orders}><Txt onPress={viewOrders} style={{fontSize: 14, fontWeight: 'bold', color:Colours.orange}}>{lang.account_as.your_orders}</Txt></InfoRow>
                        {
                            user?.is_rep &&
                            <>
                                <InfoRow label="Customer Applications"><Txt style={{fontSize:14, fontWeight:'bold', color: Colours.orange}} onPress={viewApps}>View Applications</Txt></InfoRow>
                                <InfoRow label="Analytics Overview"><Txt onPress={overview} style={{fontSize: 14, fontWeight:'bold', color: Colours.orange}}>Show</Txt></InfoRow>
                            </>
                        }
                        <InfoRow last label="Close Account"><Txt onPress={closeAc} style={{fontSize: 14, fontWeight: 'bold', color:'red'}}>Close Account</Txt></InfoRow>
                    </View>

                    <Button onPress={logout} style={{marginBottom: 30, backgroundColor:'red'}}>{lang.account_as.log_out}</Button>
                </View>

            </View>
        </ActionSheet>
    )
}

export default AccountAs;
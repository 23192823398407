import { useContext } from "react";
import { useWindowDimensions, View, TouchableOpacity, FlatList } from "react-native";
import { UserContext } from "../contexts/user";
import ActionSheet from "./action_sheet";
import SwipeDown from "./swipe_down";
import { OrdersContext } from "../contexts/orders";
import { StoreContext } from "../contexts/store";
import { format } from 'date-fns';
import { LanguageContext } from "../contexts/language";
import ListRow from "./list_row";
import * as Colours from '../colours';
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import Txt from "./txt";
import { Ionicons } from '@expo/vector-icons';

const OrderRow = props => {
    const { order_as: lang } = useContext(LanguageContext)
    const { id, last } = props;
    const { data: orders } = useContext(OrdersContext);
    const { customers } = useContext(UserContext);
    const { products } = useContext(StoreContext);

    const order = orders[id] || null;
    
    if (!order) return <Txt>{id}</Txt>
    const customer = customers.find(x => x.id === order.customer_id);
    const eans = (order?.lines || []).map(x => products[x.product_id]).map(p => p?.ean || null).filter(x => x !== null );

    return (
        <ListRow
            style={{height: 100}}
            onPress={() => props.view(order)}
            title={`${lang.order} ${order.human_ref} for ${customer?.name || 'Unknown Company'}`}
            subtitle={`${lang.placed_by} ${order.user_name} ${format(new Date(order.created), "dd/MM/yyyy, HH:mm")}`}
            product_for_image={eans}
            key_values={[
                {key: order.lines.length, value: lang.items},
                {key: order.lines.reduce((a,b) => a + b.quantity, 0), value: lang.cases},
                {key: `€${order.lines.map(x => x.p_ov * x.quantity).reduce((a,b) => a + b, 0).toFixed(2)}`, value: 'Total'}
            ]}
            last={last}
            >
                {order?.cancelled && <View style={{paddingTop: 10, paddingBottom: 10}}><Txt style={{fontWeight:'bold', color:'red'}}>CANCELLED</Txt></View>}
            </ListRow>
    )
}

const ToggleButton = props => {
    const { selected, onPress, children, extraStyle } = props;

    const style = selected ? {
        backgroundColor: Colours.orange,
        borderWidth: 0,
    } :
    {
        backgroundColor:'rgba(0,0,0,0)',
        borderWidth: 1,
        borderColor: Colours.grey
    };

    const textStyle = selected ? {
        color: "white",
        fontWeight:'bold'
    } : {
        color: Colours.grey,
    }

    return (
        <TouchableOpacity onPress={onPress} style={{padding: 8, borderRadius: 10, ...style, ...(extraStyle || {})}}>
            <Txt style={{fontSize: 12, ...textStyle}}>{children}</Txt>
        </TouchableOpacity>
    )
}

const OrderSelector = props => {
    const [all_stores, set_all_stores] = useState(true);
    const [placed_by_me, set_placed_by_me] = useState(false);
    const { user, customer } = useContext(UserContext);
    const { actions: { setOrderParams } } = useContext(OrdersContext);
    const first_all_stroes = useRef(false);
    const first_by_my = useRef(false);

    useEffect(() => {
        if (!first_all_stroes.current) {
            first_all_stroes.current = true;
            return
        }
        if (all_stores) {
            setOrderParams({customers: null})
        } else {
            setOrderParams({customers: [customer.id], offset: 0})
        }
    }, [all_stores]);

    useEffect(() => {
        if (!first_by_my.current) {
            first_by_my.current = true;
            return
        }
        if (placed_by_me) {
            setOrderParams({user_ids: [user.id], offset: 0})
        } else {
            setOrderParams({user_ids: null, offset: 0})
        }
    }, [placed_by_me])

    return (
        <View style={{alignSelf:'stretch', flexDirection:'row', justifyContent:'space-between', alignItems:'center', padding: 12, paddingLeft: 0, paddingRight: 0}}>
            <View style={{flexDirection:'row'}}>
                <ToggleButton extraStyle={{marginRight: 5}} selected={all_stores} onPress={() => set_all_stores(true)}>All Stores</ToggleButton>
                <ToggleButton selected={!all_stores} onPress={() => set_all_stores(false)}>Current Store</ToggleButton>
            </View>
            <ToggleButton selected={placed_by_me} onPress={() => set_placed_by_me(!placed_by_me)}>Placed by Me</ToggleButton>
        </View>
    )
}

const OrdersAs = props => {
    const { order_as: lang } = useContext(LanguageContext)
    const { height, width } = useWindowDimensions();
    const { actions: { showOrdersModal, viewOrder, listMore }, orders_modal_showing: visible, listing, data } = useContext(OrdersContext)
    const { actions: { showAccountModal }} = useContext(UserContext);
    const close = () => showOrdersModal(false);

    const view = (order) => {
        showOrdersModal(false);
        viewOrder(order);
    }

    const back = () => {
        showOrdersModal(false);
        showAccountModal(true)
    }

    return (
        <ActionSheet
            height={height * 0.9}
            close={close}
            visible={visible}
            cred="ORDERS"
            back={back}
            title={lang.your_orders}
        >
            <View style={{flex: 1, alignSelf:'stretch', marginTop: 10, alignItems:'center'}}>
                <View style={{flex: 1, alignSelf:'stretch'}}>
                    <OrderSelector />

                    <FlatList
                        data={listing}
                        keyExtractor={(_, i) => i.toString()}
                        renderItem={({item, index}) => <OrderRow view={view} last={index === listing.length - 1} id={item} />}
                        ListFooterComponent={<View style={{height: 20}} />}
                        onEndReachedThreshold={3}
                        onEndReached={listMore}
                        />
                </View>

            </View>
        </ActionSheet>
    )
}

export default OrdersAs;
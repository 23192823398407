import { useContext, useEffect } from "react";
import { ScrollView, useWindowDimensions, View } from "react-native";
import { UserContext } from "../contexts/user";
import ActionSheet from "./action_sheet";
import { RepFrontContext } from "../contexts/rep_front";
import { TextInput, TouchableOpacity } from "react-native";
import * as Colours from '../colours';
import { Entypo } from '@expo/vector-icons'
import Txt from "./txt";
import Sep from "./sep";
import { useState } from "react";
import { KbContext } from "./keyboard_wrapper";
//LANGUAGE

const CheckRow = props => {
    const { label, checked, onPress, description } = props;
    return (
    <TouchableOpacity onPress={onPress} style={{alignSelf:'stretch', flexDirection:'row', justifyContent:'space-between', alignItems:'center', paddingLeft: 0, paddingTop: 5, paddingBottom: 5}}>
        <View style={{flexDirection:'row', alignItems:'center', flex: 1}}>
            <View>
                <Txt>{label}</Txt>
                <Txt style={{fontSize: 12, maxWidth: 250}}>{description}</Txt>
            </View>
        </View>

        <View style={{padding: 5}}>
            <Entypo name="check" size={18} color={checked ? Colours.orange : Colours.grey} />
        </View>
    </TouchableOpacity>
    )
}

const NumberInputRow = props => {
    const { value, onChange, label, description } = props;
    const [temp_value, set_temp_value] = useState(value);


    const submit = () => {
        let flt = parseFloat(temp_value);
        if (isNaN(flt)) return
        onChange(flt);
    }

    return (
        <View style={{alignSelf:'stretch', flexDirection:'row', justifyContent:"space-between", alignItems:'center', paddingLeft: 0, paddingTop: 5, paddingBottom: 5}}>
            <View style={{flex: 1}}>
                <Txt>{label}</Txt>
                <Txt style={{fontSize: 12, maxWidth: 250}}>{description}</Txt>
            </View>
            <View style={{flexDirection:'row', alignSelf:'stretch'}}>
                <TextInput
                    keyboardType="decimal-pad"
                    value={temp_value}
                    onChangeText={t => set_temp_value(t.split("").filter(x => ['0','1','2','3','4','5','6','7','8','9','.'].indexOf(x) !== -1).join(''))}
                    onSubmitEditing={submit}
                    style={{padding: 5, width: 80, textAlign:'center', backgroundColor:'black', fontSize: 14, color:'white'}}
                    />
            </View>
        </View>
    )
}

const RepFrontAs = () => {
    const { height, width } = useWindowDimensions();
    const { kb_height } = useContext(KbContext)
    const { user } = useContext(UserContext);
    const { policy, viewing, actions: { setPolicy, setViewing} } = useContext(RepFrontContext)
    const close = () => setViewing(false)

    if (!user?.is_rep) return null;

    return (
        <ActionSheet
            height={height * 0.6}
            close={close}
            visible={viewing}
            cred="ACCT"
            title={"Storefront Policy"}
        >
            {
                policy && 
                <ScrollView style={{paddingBottom: kb_height}}>
                    <View style={{flex: 1, alignSelf:'stretch', marginTop: 10, alignItems:'center', marginBottom: 20}}>
                        <View style={{alignSelf:'stretch', alignItems:'flex-start'}}>
                            <Txt style={{fontWeight: 'bold'}}>Listing Policy</Txt>
                            <CheckRow 
                                label="Default Listed" 
                                description="All Products are listed by default, unless manually delisted" 
                                checked={policy?.listing_policy === 'DefaultListed'} 
                                onPress={() => setPolicy({id: policy.id, listing_policy: 'DefaultListed'})}
                                />
                            <Sep height={20} />
                            <CheckRow 
                                label="Default Not Listed" 
                                description="All Products are un-listed by default, unless manually listed" 
                                checked={policy?.listing_policy === 'DefaultNotListed'} 
                                onPress={() => setPolicy({id: policy.id, listing_policy: 'DefaultNotListed'})}
                                />
                        </View>

                        <View style={{alignSelf:'stretch', alignItems:'flex-start', marginTop: 20}}>
                            <Txt style={{fontWeight: 'bold'}}>Pricing Policy</Txt>
                            <NumberInputRow
                                label="Default Margin"
                                description="Margin applied to offer cost, where no sell price has been specified"
                                value={`${policy.pricing_policy.min_mgn_pc}`}
                                onChange={min_mgn_pc => setPolicy({id: policy.id, pricing_policy: {...policy.pricing_policy, min_mgn_pc: min_mgn_pc > 0 ? min_mgn_pc : null}})}
                                />

                            <NumberInputRow
                                label="Default Pallet Contrib."
                                description="Pallet Contrib. applied to offer cost, where no sell price has been specified"
                                value={`${policy.pricing_policy.min_per_pallet}`}
                                onChange={min_per_pallet => setPolicy({id: policy.id, pricing_policy: {...policy.pricing_policy, min_per_pallet: min_per_pallet > 0 ? min_per_pallet : null}})}
                                />

                            <View style={{marginTop: 20, alignItems:'flex-start', alignSelf:'stretch'}}>

                                <Txt style={{fontWeight:'bold'}}>Policy Application</Txt>
                                <CheckRow 
                                    label="Margin Only" 
                                    description="Calculate prices using only the default margin" 
                                    checked={policy?.pricing_policy.application === 'Mgn'} 
                                    onPress={() => setPolicy({id: policy.id, pricing_policy: {...policy.pricing_policy, application: 'Mgn'}})}
                                    />
                                    <CheckRow 
                                    label="Pallet Contrib" 
                                    description="Calculate prices using only the default pallet contrib." 
                                    checked={policy?.pricing_policy.application === 'Pallet'} 
                                    onPress={() => setPolicy({id: policy.id, pricing_policy: {...policy.pricing_policy, application: 'Pallet'}})}
                                    />
                                    <CheckRow 
                                    label="Lower of Two" 
                                    description="Calculate the price using mgn / pallet, and use the lower of the two" 
                                    checked={policy?.pricing_policy.application === 'LowerOfTwo'} 
                                    onPress={() => setPolicy({id: policy.id, pricing_policy: {...policy.pricing_policy, application: 'LowerOfTwo'}})}
                                    />
                                    <CheckRow 
                                    label="Higher of Two" 
                                    description="Calculate the price using mgn / pallet, and use the higher of the two" 
                                    checked={policy?.pricing_policy.application === 'HigherOfTwo'} 
                                    onPress={() => setPolicy({id: policy.id, pricing_policy: {...policy.pricing_policy, application: 'HigherOfTwo'}})}
                                    />
                                <CheckRow 
                                    label="Stacked" 
                                    description="Calculate price using the pallet contrib. Then add margin on top" 
                                    checked={policy?.pricing_policy.application === 'Stacked'} 
                                    onPress={() => setPolicy({id: policy.id, pricing_policy: {...policy.pricing_policy, application: 'Stacked'}})}
                                    />
                            </View>
                        </View>

                    </View>
                </ScrollView>
            }
        </ActionSheet>
    )
}

export default RepFrontAs;
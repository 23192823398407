const portuguese = {
    language_name: "Portuguese",
    account_as: {
        first_name: 'Primeiro nome',
        last_name: 'Apelido',
        email_address: "Endereço de correio electrónico",
        reset_password: "Repor palavra-passe",
        your_orders: "As suas encomendas",
        log_out: "Sair",
        your_account: "A sua conta"
    },
    action_sheet: {
        close: 'Fechar'
    },
    adjustments_as: {
        loading: 'Carregando',
        weve_reduced_1: "Reduzimos a quantidade no seu cesto de ",
        weve_reduced_2: " caixas para ",
        weve_reduced_3: " caixas.",
        weve_reduced_title: "Tivemos de retirar alguns artigos do seu cesto, porque ficaram indisponíveis",
        sorry: "Desculpe",
        ok: "OK"
    },
    apply_as: {
        required: "Obrigatório",
        first_name: "Primeiro nome",
        last_name: "Apelido",
        email_address: "Endereço de correio electrónico",
        company_name: "Nome da empresa",
        company_no: "Número da empresa",
        website: "Website",
        phone: "Telefone",
        address: "Endereço",
        postcode: "Código postal",
        apply_for_account: "Solicitar uma conta",
        about_you: "Sobre si",
        about_your_company: "Sobre a sua empresa",
        submit: "Submeter"
    },
    create_password_as: {
        create_a_password: "Criar uma palavra-passe",
        please_use: "Utilize uma palavra-passe segura. Utilize uma mistura de caracteres maiúsculos e minúsculos, bem como números. Comprimento mínimo de 8 caracteres",
        password:"Password",
        confirm_password: "Palavra-passe",
        submit: "Submeter",
        current_password: "Palavra-passe actual"
    },
    customers_as: {
        stores: "Lojas",
        reg: "Reg: ",
        customer: "Cliente"
    },
    forgot_as: {
        thanks: "Obrigado",
        sorry: "Desculpe",
        youll_receive: "Receberá instruções sobre como continuar",
        went_wrong: "Algo correu mal - por favor, tente novamente ou contacte-nos",
        ok: "OK",
        forgot_your_password: "Esqueceu-se da sua palavra-passe",
        please_enter: "Introduza o seu endereço de correio electrónico, enviaremos instruções sobre como redefinir a sua palavra-passe",
        email_address: "Endereço de correio electrónico",
        submit: "submeter"
    },
    login_as: {
        login: "Login",
        please_enter: "Por favor, introduza os seus dados abaixo para iniciar sessão",
        email_address: "Endereço de correio electrónico",
        password: "Palavra-passe",
        apply_now: "Registar-se",
        forgot: "Esqueci-me da palavra-passe",
        login: "Login",
        dont: "Não tem uma conta?"
    },
    order_as: {
        loading: "Carregando",
        cases: "caixas",
        each: "cada",
        total: "Total",
        please_wait: "Por favor, aguarde",
        you_recently: "Solicitou recentemente um reenvio, aguarde por favor",
        you_recently_two: "segundos antes de tentar novamente",
        sorry: 'Desculpe',
        something_went_wrong: "Algo correu mal - por favor, tente novamente mais tarde ou contacte-nos",
        confirmation_resent: "Confirmação reenviada",
        we_resent: `Renviámos a sua confirmação de encomenda para esta encomenda`,
        for: 'para',
        unknown_company: "Empresa desconhecida",
        order: "Ordem",
        placed_by: 'Colocado por',
        at: 'em',
        thanks: "Obrigado, recebemos a sua encomenda",
        items: "Artigos",
        resend_confirmation: "Confirmação de reenvio de correio electrónico",
        download_confirmation: "Baixar confirmação de e-mail",
        your_orders: "As suas encomendas",
    },
    orders_context: {
        cant_place_order: "Não é possível efectuar a encomenda",
        basket_empty: "O seu cesto está vazio",
        sorry: "Desculpe",
        something_went_wrong: "Algo correu mal. Tente novamente ou contacte-nos"
    },
    user_context: {
        password_reset: "Reposição da palavra-passe",
        password_has_been: "A sua palavra-passe foi redefinida, pode agora iniciar sessão com a sua nova palavra-passe",
        invalid_token: "Token inválido",
        restart: "Reinicie o processo 'Esqueceu-se da sua palavra-passe'",
        something_went_wrong: "Algo correu mal",
        try_again: "Por favor, tente novamente ou contacte-nos",
    },
    basket_as: {
        your_basket: "O seu cesto para",
        total_cases: "Total de caixas: ",
        total_value: "Valor total: : ",
        place_order: "Efectuar encomenda",
        total: "Total"
    },
    explain_as: {
        ambient: "Ambiente",
        frozen: "Congelado",
        seasonal: "Sazonal",
        catering: "Catering",
        chilled: "Chilled",
        unknown: "Desconhecido",
        in_stock: "Em stock",
        clear: "Limpo"
    },
    filters_as: {
        in_stock: "Apenas em stock",
        filters: "Filtros",
        include: "Incluir tipos de produtos: ",
        loading: "Carregando",
        ambient: "Ambiente",
        frozen: "Congelado",
        seasonal: "Sazonal",
        catering: "Restauração",
        chilled: "Chilled",
        favourites: "Favoritos",
    },
    storefront: {
        stock: "MERCADORIA",
        unavailable: "Indisponível",
        please_login: "Por favor, inicie sessão para ver os preços",
        per_unit: "/ unidade",
        open: "Aberto",
        none: "Nenhum",
        failed:"Falha ao adicionar ao cesto",
        none: "Desculpe - não temos mercadoria disponível",
        only1: "Lamentamos, mas só temos",
        cases_available: "casos disponíveis",
        add_to_basket: "Adicionar ao cesto"
    },
    product_as: {
        size: "Tamanho",
        case: "Caixa",
        pallet: "Palete",
        ean: "EAN",
        occ: "OCC",
        brand: "Marca",
        manufacturer: "Fabricante",
        ref: "Ref",
        categories: "Categorias",
        loading: "Carregando"
    },
    search: {
        search: "Pesquisar"
    },
    header: {
        account: "Conta",
        basket: "Cesto"
    }
}


const english = {
    language_name: 'English',
    account_as: {
        first_name: 'First Name',
        last_name: 'Last Name',
        email_address: "Email Address",
        reset_password: "Reset Password",
        your_orders: "Your Orders",
        log_out: "Log Out",
        your_account: "Your Account"
    },
    action_sheet: {
        close: 'Close'
    },
    adjustments_as: {
        loading: 'Loading',
        weve_reduced_1: "We've reduced the quantity in your basket from ",
        weve_reduced_2: " cases to ",
        weve_reduced_3: " cases.",
        weve_reduced_title: "We've had to remove some items from your basket, because they became unavailable.",
        sorry: "Sorry",
        ok: "OK"
    },
    apply_as: {
        required: "Required",
        first_name: "First Name",
        last_name: "Last Name",
        email_address: "Email Address",
        company_name: "Company Name",
        company_no: "Company No",
        website: "Website",
        phone: "Phone",
        address: "Address",
        postcode: "Postcode",
        apply_for_account: "Apply for an Account",
        about_you: "About You",
        about_your_company: "About Your Company",
        submit: "Submit"
    },
    create_password_as: {
        create_a_password: "Create a Password",
        please_use: "Please use a secure password. Use a mixtues of Upper and Lower case characters, as well as numbers. Minimum length 8 characters",
        password:"Password",
        confirm_password: "Confirm Password",
        submit: "Submit",
        current_password: "Current Password"
    },
    customers_as: {
        stores: "Stores",
        reg: "Reg: ",
        customer: "Customer"
    },
    forgot_as: {
        thanks: "Thanks",
        sorry: "Sorry",
        youll_receive: "You'll receive instructions on how to continue",
        went_wrong: "Something went wrong - please try again or contact us",
        ok: "OK",
        forgot_your_password: "Forgot Your Password",
        please_enter: "Please enter your email address, we'll send instructions for how to reset your password",
        email_address: "Email Address",
        submit: "Submit"
    },
    login_as: {
        login: "Login",
        please_enter: "Please enter your details below to log in",
        email_address: "Email Address",
        password: "Password",
        apply_now: "Apply Now",
        forgot: "Forgot Password",
        login: "Login",
        dont: "Don't have an account?"
    },
    order_as: {
        loading: "Loading",
        cases: "cases",
        each: "each",
        total: "Total",
        please_wait: "Please Wait",
        you_recently: "You recently requested a resend, please wait",
        you_recently_two: "seconds before trying again",
        sorry: 'Sorry',
        something_went_wrong: "Something went wrong - please try again later or contact us",
        confirmation_resent: "Confirmation Resent",
        we_resent: `We resent your order confirmation for this order`,
        for: 'for',
        unknown_company: "Unknown Company",
        order: "Order",
        placed_by: 'Placed by',
        at: 'at',
        thanks: "Thanks, we've received your order",
        items: "Items",
        resend_confirmation: "Re-Email Confirmation",
        download_confirmation: "Download Email Confirmation",
        your_orders: "Your Orders",
    },
    orders_context: {
        cant_place_order: "Can't Place Order",
        basket_empty: "Your Basket is empty",
        sorry: "Sorry",
        something_went_wrong: "Something went wrong. Please try again or contact us"
    },
    user_context: {
        password_reset: "Password Reset",
        password_has_been: "Your password has been reset, you can now log in with your new password",
        invalid_token: "Invalid Token",
        restart: "Please restart the 'Forgot Your Password' process",
        something_went_wrong: "Something went wrong",
        try_again: "Please try again or contact us",
    },
    basket_as: {
        your_basket: "Your Basket for",
        total_cases: "Total Cases: ",
        total_value: "Total Value: ",
        place_order: "Place Order",
        total: "Total"
    },
    explain_as: {
        ambient: "Ambient",
        frozen: "Frozen",
        seasonal: "Seasonal",
        catering: "Catering",
        chilled: "Chilled",
        unknown: "Unknown",
        in_stock: "In Stock",
        clear: "Clear"
    },
    filters_as: {
        in_stock: "In-Stock Only",
        filters: "Filters",
        include: "Include Product Types: ",
        loading: "Loading",
        ambient: "Ambient",
        frozen: "Frozen",
        seasonal: "Seasonal",
        catering: "Catering",
        chilled: "Chilled",
        favourites: "Favourites"
    },
    storefront: {
        stock: "STOCK",
        unavailable: "Unavailable",
        please_login: "Please log in to view pricing",
        per_unit: "/ unit",
        open: "Open",
        none: "None",
        failed:"Failed to Add to Basket",
        none: "Sorry - we have no stock available",
        only1: "Sorry, we only have",
        cases_available: "cases available",
        add_to_basket: "Add to Basket"
    },
    product_as: {
        size: "Size",
        case: "Case",
        pallet: "Pallet",
        ean: "EAN",
        occ: "OCC",
        brand: "Brand",
        manufacturer: "Manufacturer",
        ref: "Ref",
        categories: "Categories",
        loading: "Loading"
    },
    search: {
        search: "Search"
    },
    header: {
        account: "Account",
        basket: "Basket"
    }
}

export {
    english,
    portuguese
}
import Nav from './navigation';
import { NavigationContainer } from '@react-navigation/native';
import ProductAs from './storefront/product_as';
import LoginAs from './components/login_as';
import BasketAs from './storefront/basket_as';
import FiltersAs from './storefront/filters_as';
import CustomersAs from './components/customers_as';
import UserWrapper from './contexts/user';
import StoreWrapper from './contexts/store';
import BasketWrapper from './contexts/basket';
import KbWrapper from './components/keyboard_wrapper';
import ApplyAs from './components/apply_as';
import AccountAs from './components/account_as';
import OrdersWrapper from './contexts/orders';
import OrdersAs from './components/orders_as';
import OrderAs from './components/order_as';
import SelectWrapper from './components/select';
import AdjustmentsAs from './components/adjustments_as';
import * as Linking from 'expo-linking';
import LinksWrapper from './contexts/links';
import ForgotAs from './components/forgot_as';
import CreatePasswordAs from './components/create_password_as';
import LanguageWrapper from './contexts/language';
import ResetAs from './components/reset_as';
import { StatusBar } from 'expo-status-bar';
import InvitedAs from './components/invited_as';
import AddStoreAs from './components/add_store_as';
import { useWindowDimensions, View, TouchableOpacity } from 'react-native';
import AlertWrapper from './contexts/alert';
import ScannerWrapper from './contexts/scanner';
import PushWrapper from './contexts/push';
import { useFonts } from 'expo-font'
import ApplicationsWrapper from './contexts/applications_rep';
import ApplicationsAs from './components/applications_as';
import RucWrapper from './contexts/users_customers_rep';
import ApplicationAs from './components/application_as';
import * as SplashScreen from 'expo-splash-screen';
import { useEffect } from 'react';
import RepReportWrapper from './contexts/rep_reports';
import OverviewAs from './reports_charts/overview_as';
import TempImageWrapper from './contexts/temp_image';
import WsWrapper from './contexts/ws';
import OffersAs from './components/offers_as';
import RepOffersWrapper from './contexts/rep_offers';
import RepFrontWrapper from './contexts/rep_front';
import RepFrontAs from './components/rep_fron_as';
import Txt from './components/txt';
import { ToastProvider } from 'react-native-toast-notifications';
import * as Colours from './colours';
import IfEventWrapper from './contexts/if_event';
SplashScreen.preventAutoHideAsync();

const prefix = Linking.createURL("/");
const Init = props => {
  const { width, height } = useWindowDimensions();
  const [fontsLoaded] = useFonts({
    'Poppins': require("./assets/Poppins/Poppins-Regular.ttf"),
    'Libre-Franklin': require("./assets/Libre_Franklin/LibreFranklin-VariableFont_wght.ttf"),
    'LF-Bold': require("./assets/Libre_Franklin/static/LibreFranklin-Bold.ttf")
  });

  useEffect(() => {
    if (fontsLoaded) {
      SplashScreen.hideAsync();
    }
  }, [fontsLoaded]);
  if (!fontsLoaded) return null;
  return (
    <View style={{width, height, overflow: 'hidden'}}>
      {props.children}
    </View>
  )
}


const Toast = props => {
  const { message, onPress } = props;
  const { title, body } = JSON.parse(message);

  if (onPress) {
      return (
      <TouchableOpacity onPress={onPress} style={{padding: 10, paddingLeft: 20, paddingRight: 20, borderRadius: 10, backgroundColor: "white", borderWidth: 2, borderColor: Colours.purple}}>
          {title && <Txt style={{fontWeight:'bold', color: Colours.purple}}>{title}</Txt>}
          {body && <Txt style={{color: Colours.purple}}>{body}</Txt>}
      </TouchableOpacity>)
  }
  return (
      <View onPress={onPress} style={{padding: 10, paddingLeft: 20, paddingRight: 20, borderRadius: 10, backgroundColor: "white", borderWidth: 2, borderColor: Colours.purple}}>
          {title && <Txt style={{fontWeight:'bold', color: Colours.purple}}>{title}</Txt>}
          {body && <Txt style={{color:'white', color: Colours.purple}}>{body}</Txt>}
      </View>
  )
}

export default function App() {
  const linking = {
    prefixes: [prefix, "albpt://", "https://albanyproducts.eu"],
    config: {
      screens: {
        "Albany Products": ''
      }
    }
  }


  return (
    <Init>
      <ToastProvider
      offset={30}
      renderToast={(toast) => {
        return <Toast {...toast} />
      }}
        >
        <AlertWrapper>
          <KbWrapper>
            <LanguageWrapper>
              <UserWrapper>
                <IfEventWrapper>
                  <StoreWrapper>
                    <BasketWrapper>
                      <ScannerWrapper>
                        <OrdersWrapper>
                          <RucWrapper>
                            <ApplicationsWrapper>
                              <RepReportWrapper>
                                <RepOffersWrapper>
                                  <RepFrontWrapper>
                                    <TempImageWrapper>
                                      <NavigationContainer linking={linking}>
                                          <SelectWrapper>
                                            <PushWrapper>
                                              <WsWrapper>
                                                <LinksWrapper>
                                                  <Nav />
                                                  <ProductAs />
                                                  <CustomersAs />
                                                  <BasketAs />
                                                  <FiltersAs />
                                                  <LoginAs />
                                                  <ForgotAs />
                                                  <CreatePasswordAs />
                                                  <ApplyAs />
                                                  <AccountAs />
                                                  <OrdersAs />
                                                  <OrderAs />
                                                  <AdjustmentsAs />
                                                  <ResetAs />
                                                  <InvitedAs />
                                                  <AddStoreAs />
                                                  <ApplicationsAs />
                                                  <ApplicationAs />
                                                  <OverviewAs />
                                                  <OffersAs />
                                                  <RepFrontAs />
                                                </LinksWrapper>
                                              </WsWrapper>
                                            </PushWrapper>
                                          </SelectWrapper>
                                      </NavigationContainer>
                                    </TempImageWrapper>
                                  </RepFrontWrapper>
                                </RepOffersWrapper>
                              </RepReportWrapper>
                            </ApplicationsWrapper>
                          </RucWrapper>
                        </OrdersWrapper>
                      </ScannerWrapper>
                    </BasketWrapper>
                  </StoreWrapper>
                </IfEventWrapper>
              </UserWrapper>
            </LanguageWrapper>
            </KbWrapper>
        </AlertWrapper>
        </ToastProvider>
        <StatusBar style='dark' />
      </Init>
  );
}

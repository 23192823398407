import { useState } from "react";
import { createContext } from "react";
import { useRef, useEffect } from "react";
import { Animated, Keyboard, Platform } from "react-native";

export const KbContext = createContext({kb_height: 0})

const KbWrapper = props => {
    const kb_height = useRef(new Animated.Value(0)).current;
    const hide_listener = useRef(null);
    const show_listener = useRef(null);
    const [cache_kb_height, set_kb_height] = useState(0);

    useEffect(() => {
        show_listener.current = Keyboard.addListener(Platform.OS === 'ios' ? 'keyboardWillShow' : 'keyboardDidShow', kb_did_show);
        hide_listener.current = Keyboard.addListener(Platform.OS === 'ios' ? 'keyboardWillHide' : 'keyboardDidHide', kb_did_hide);

        return () => {
            if (show_listener.current) {
                show_listener.current.remove();
            }
            if (hide_listener.current) {
                hide_listener.current.remove();
            }
        }
    }, [])

    const kb_did_show = e => {
        set_kb_height(e.endCoordinates.height);
        Animated.timing(kb_height, {
            duration: e.duration,
            toValue: e.endCoordinates.height,
            useNativeDriver: false
        }).start();
    }

    const kb_did_hide = e => {
        set_kb_height(0);
        Animated.timing(kb_height, {
            duration: e.duration,
            toValue: 0,
            useNativeDriver: false
        }).start()
    }

    const color = kb_height.interpolate({outputRange: ['rgba(255, 0, 0, 1)', 'rgba(0, 255, 0, 1)'], inputRange: [0, 500]})

    return (
        <KbContext.Provider value={{kb_height: cache_kb_height}}>
            <Animated.View style={{position:'absolute', top: 0, bottom: 0, left: 0, right: 0, marginBottom: kb_height}}>
                {props.children}
            </Animated.View>
        </KbContext.Provider>
    )
}

export default KbWrapper;
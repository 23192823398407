import { useContext, useEffect, useState } from "react";
import { useWindowDimensions, View, ScrollView, Platform } from "react-native";
import { LanguageContext } from "../contexts/language";
import { UserContext } from "../contexts/user";
import ActionSheet from "./action_sheet";
import Button from "./button";
import TxtInput from "./txt_input";
import * as Colours from '../colours';
import Txt from "./txt";
import { useRef } from "react";

//LANGUAGE

const LoginAs = props => {
    const [loading, set_loading] = useState(false);
    const { login_as: lang } = useContext(LanguageContext);
    const { height } = useWindowDimensions();
    const { actions: { showLoginModal, showApplyModal, login, showForgotModal }, login_modal_showing: visible, user } = useContext(UserContext);
    const [email, set_email] = useState('');
    const [password, set_password] = useState('');
    const [is_err, set_is_err] = useState(false);

    const email_ref = useRef(null);
    const pass_ref = useRef(null);

    const apply = () => {
        showLoginModal(false);
        showApplyModal(true);
    }
    const forgot = () => {
        showLoginModal(false);
        showForgotModal(true);
    }

    useEffect(() => {
        email_ref.current = email;
        pass_ref.current = password;
    }, [email, password]);

    const close = () => showLoginModal(false);

    const submit = async () => {
        set_loading(true);
        if (email && password) {
            const ok = await login({email_address: email_ref.current, password: pass_ref.current});
            if (ok) {
                set_is_err(false);
            } else {
                set_is_err(true);
            }
        }
        set_loading(false);
    }

    useEffect(() => {
        if (user) {
            close();
        }
    }, [user])

    useEffect(() => {
        if (visible) {
            didFocus();
        } else {
            didBlur();
        }
    }, [visible])

    const didFocus = () => {
        if (Platform.OS === 'web') {
            window.addEventListener('keypress', onEnter);
        }
    }
    const didBlur = () => {
        if (Platform.OS === 'web') {
            window.removeEventListener('keypress', onEnter)
        }
    }

    const onEnter = e => {
        if (e.key === 'Enter') {
            submit();
        }
    }

    return (
        <ActionSheet
            height={height * 0.6}
            close={close}
            visible={visible}
            cred="LOGIN"
            title={lang.login}
        >
            <View style={{flex: 1, alignSelf:'stretch', marginTop: 10, alignItems:'center'}}>
                <View style={{flex: 1, alignSelf:'stretch'}}>
                    <View style={{flex: 1, justifyContent:'flex-start', alignItems:'stretch', alignSelf:'stretch'}}>
                        <Txt style={{marginTop: 10, marginBottom: 10}}>{lang.please_enter}</Txt>
                        <ScrollView style={{alignSelf:'stretch'}}>
                            <Txt style={{marginBottom: 2}}>{lang.email_address}</Txt>
                            <TxtInput 
                                value={email} 
                                onChange={set_email}
                                can_clear
                                onClear={() => set_email('')}
                                placeholder={lang.email_address}
                                style={{marginBottom: 10, borderWidth: 1, borderColor:Colours.light_grey, height: 40}}
                                inputStyle={{fontSize: 14}}
                                inputProps={{textContentType: "emailAddress", autoComplete: 'email', keyboardType:'email-address'}}
                                />

                            <Txt style={{marginBottom: 2}}>{lang.password}</Txt>

                            <TxtInput 
                                value={password} 
                                onChange={set_password}
                                can_clear
                                onClear={() => set_password('')}
                                placeholder={lang.password}
                                style={{borderWidth: 1, borderColor:Colours.light_grey, height: 40}}
                                inputProps={{textContentType: "password", autoComplete: 'password', secureTextEntry: true}}
                                />
                        </ScrollView>
                        {
                            is_err && 
                        <Txt style={{fontWeight:'bold', color:'red', marginTop: 10, marginBottom: 10}}>Failed to Log in, please check your credentials and try again</Txt>
                        }
                        <Txt style={{marginTop: 20, marginBottom: 20}}>{lang.dont} <Txt onPress={apply} style={{color:Colours.orange, fontWeight:'bold'}}>{lang.apply_now}</Txt></Txt>
                    </View>
                    <View style={{alignSelf:'stretch', flexDirection:'column', marginBottom: 30}}>
                        <Button loading={loading} onPress={submit}>{lang.login}</Button>
                        <Button onPress={forgot} style={{backgroundColor:Colours.light_grey, marginTop: 10}} textStyle={{color:'black'}}>{lang.forgot}</Button>
                    </View>
                </View>

            </View>
        </ActionSheet>
    )
}

export default LoginAs;
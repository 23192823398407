import { useContext } from "react";
import { useWindowDimensions, View, TouchableOpacity } from "react-native";
import { UserContext } from "../contexts/user";
import ActionSheet from "./action_sheet";
import SwipeDown from "./swipe_down";
import { useState } from "react";
import Txt from "./txt";
import { ApplicationsContext } from "../contexts/applications_rep";
import { RepUserCustomerContext } from "../contexts/users_customers_rep";
import InfoRow from '../components/info_table';
import Button from "./button";
import { AlertContext } from "../contexts/alert";
import { Ionicons } from '@expo/vector-icons';
import * as Colours from '../colours';

const ApplicationAs = props => {
    //const { order_as: lang } = useContext(LanguageContext)
    const { height, width } = useWindowDimensions();
    const { user } = useContext(UserContext);
    const { viewing_applications, viewing_app: app, apps, listing, listMore, actions: { setViewingApps, viewApp, process } } = useContext(ApplicationsContext);
    const close = () => viewApp(null);
    const [approve_loading, set_approve_loading] = useState(false);
    const [reject_loading, set_reject_loading] = useState(false);
    const { users } = useContext(RepUserCustomerContext);
    const { actions: { setAlert } } = useContext(AlertContext)

    const back = () => {
        viewApp(null);
        setViewingApps(true);
    }

    if (!user?.is_rep) return null;

    const user_info = typeof app?.user === 'string' ? users[app?.user] : app?.user;
    const existing_user = typeof app?.user === 'string';

    const approve = () => {
        set_approve_loading(true);
        setAlert({
            title: "Approve Application?",
            body: existing_user ? `This will create company ${app.customer.name}, and assign to the existing user account.` : `This will crate company ${app.customer.name} and assign to new user: ${app.user.first_name} ${app.user.last_name}`,
            onConfirm: async () => {
                await process(app.id, true);
                set_approve_loading(false);
            },
            onCancel: () => set_approve_loading(false)
        });
    }
    const reject = () => {
        set_approve_loading(true);
        setAlert({
            title: "Reject Application?",
            body: `This will reject the application, and notify the applicant`,
            onConfirm: async () => {
                await process(app.id, false);
                set_approve_loading(false);
            },
            onCancel: () => set_approve_loading(false)
        });
    }


    return (
        <ActionSheet
            height={height * 0.9}
            close={close}
            visible={app !== null}
            cred="APP"
            title={`Application ${app?.human_ref}`}
            back={back}
        >
            <View style={{flex: 1, marginTop: 10, alignItems:'center', alignSelf:'stretch'}}>
                <View style={{flex: 1, alignSelf:'stretch'}}>
                    {
                    app?.accepted === null && <Txt style={{color:'blue', fontWeight:'bold'}}>Unprocessed</Txt>
                    }
                    {
                        app?.accepted === true && <Txt style={{color:'green', fontWeight:'bold'}}>Approved</Txt>
                    }
                    {
                        app?.accepted === false && <Txt style={{color:'red', fontWeight:'bold'}}>Rejected</Txt>
                    }
                    
                    <Txt style={{marginTop: 10, fontWeight:'bold'}}>User</Txt>
                    <InfoRow label="First Name"><Txt>{user_info?.first_name}</Txt></InfoRow>
                    <InfoRow label="Last Name"><Txt>{user_info?.last_name}</Txt></InfoRow>
                    <InfoRow label="Email Address"><Txt>{user_info?.email_address}</Txt></InfoRow>
                    <InfoRow label="Exists?"><Txt>{existing_user ? 'Yes' : 'No'}</Txt></InfoRow>

                    <Txt style={{marginTop: 10, fontWeight:'bold'}}>Company</Txt>
                    <InfoRow label="Name"><Txt>{app?.customer?.name}</Txt></InfoRow>
                    <InfoRow label="Reg No"><Txt>{app?.customer?.company_no}</Txt></InfoRow>
                    <InfoRow label="Email Address"><Txt>{app?.customer?.email_address}</Txt></InfoRow>
                    <InfoRow label="Phone"><Txt>{app?.customer?.phone}</Txt></InfoRow>
                    <InfoRow label="Website"><Txt>{app?.customer?.website}</Txt></InfoRow>
                    <InfoRow label="Address"><Txt>{app?.customer?.address}</Txt></InfoRow>

                </View>

                {
                    !app?.accepted &&
                    <View style={{marginBottom: 30, alignSelf:'stretch'}}>
                        <Button onPress={approve}>Approve</Button>
                        {
                            app?.accepted === null &&
                            <Button onPress={reject} style={{backgroundColor:'red', marginTop: 10}}>Reject</Button>
                        }
                    </View>
                }
            </View>
        </ActionSheet>
    )
}

export default ApplicationAs;
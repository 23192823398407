import { useContext, useEffect, useState } from 'react';
import { View, useWindowDimensions, TouchableOpacity, TextInput, PlatformColor, Platform, Text } from 'react-native';
import ProductImg from './product_img';
import { AntDesign } from '@expo/vector-icons'; 
import Search from './search';
import { StoreContext } from '../contexts/store';
import { UserContext } from '../contexts/user';
import { BasketContext } from '../contexts/basket';
import Explainer from './explainer';
import { LinksContext } from '../contexts/links';
import { LanguageContext } from '../contexts/language';
import * as Colours from '../colours';
import { FlashList } from "@shopify/flash-list";
import { Filters } from './filters_as';
import { AlertContext } from '../contexts/alert';
import Txt from '../components/txt';
import { useRef } from 'react';
import Button from '../components/button';
import { IfEventContext } from '../contexts/if_event';
import { add } from 'date-fns';


const Fave = ({product_id}) => {
    const { register } = useContext(IfEventContext);
    const { faves, actions: { setFave } } = useContext(StoreContext);
    const { customer } = useContext(UserContext);

    if (!customer) return null
    const is_fave = faves.indexOf(product_id) !== -1;

    const toggle = () => {
        const new_fave = !is_fave;
        setFave(product_id, new_fave);
        register("toggle_fave", { fave : new_fave });
    }

    return (
        <TouchableOpacity onPress={toggle} style={{position:'absolute', top: 0, right: 0, padding: 10}}>
            <AntDesign name={is_fave ? "heart" : "hearto"} color="red" size={20} />
        </TouchableOpacity>
    )
}

export const ProductBasketButtons = props => {
    const { storefront: lang } = useContext(LanguageContext)
    const { offer } = props;
    const { basket, actions: { setBasket } } = useContext(BasketContext);
    const { customer } = useContext(UserContext);
    const [typing, set_typing] = useState(null);
    const [typing_value, set_typing_value] = useState(null);
    const { actions: { setAlert }} = useContext(AlertContext);

    const p_ov = basket.filter(x => x?.quantity > 0).find(x => x.offer_id === offer?.id && x.quantity > 0)?.p_ov;

    useEffect(() => {
        if (offer) {
            set_typing_value(`${basket.map(x => x.offer_id === offer.id ? x.quantity : 0).reduce((a,b) => a + b, 0)}`)
        }
    }, [basket, offer, typing]);

    const onType = t => {
        set_typing_value(t.split("").filter(x => ['0','1','2','3','4','5','6','7','8','9'].indexOf(x) !== -1).join(""));
    }
    
    if (!offer || !customer) return null;

    const set = (orig_qty) => {
        let adjusted = orig_qty;
          let can_add = true;
          if (offer.available !== lang.open) {
            if (offer.available === lang.none) {
              can_add = false;
              adjusted = 0;
            } else {
              let q = parseInt(offer.available.Qty);
              if (isNaN(q)) {
                can_add = false;
                adjusted = 0
              } else {
                if (adjusted > q) {
                  can_add = false;
                  adjusted = q
                }
              }
            }
          }
  
          if (!can_add) {
            setAlert({title: lang.failed, body: (adjusted === 0) && (orig_qty > 0) ? lang.none : `${lang.only1} ${adjusted} ${lang.cases_available}`});
            return
          }
  
          let instr = {
            product_id: offer.product_id,
            offer_id: offer.id,
            quantity: adjusted,
            p_ov
        }

  
        setBasket(instr);
      }

    const add = () => {
        let current = basket.find(x => x.offer_id === offer.id)?.quantity || 0;
        let new_qty = current + 1;

        let can_add = true;
        if (offer.available !== 'Open') {
          if (offer.available === "None") {
            can_add = false;
          } else {
            let q = parseInt(offer.available.Qty);
            if (isNaN(q)) {
              can_add = false;
            } else {
              if (current >= q) {
                can_add = false;
              }
            }
          }
        }

        if (!can_add) {
            setAlert({title: "Failed to Add to Basket", body: offer.available === "None" ? "Sorry - we have no stock available" : `Sorry, we only have ${offer.available.Qty} cases available`})
          return;
        }

        let instr = {
            product_id: offer.product_id,
            offer_id: offer.id,
            quantity: new_qty,
            p_ov
        }

        setBasket(instr);
    }

    const minus = () => {
        let current = basket.find(x => x.offer_id === offer.id)?.quantity || 0;
        if (current > 0) {
          let instr = {
              product_id: offer.product_id,
              offer_id: offer.id,
              quantity: current - 1,
              p_ov
            }
            setBasket(instr);
        }
      }

      const inputFocus = () => {
        set_typing_value("");
        set_typing(true);
      }

      const inputBlur = () => {
        setTimeout(() => set_typing(false), 300);
      }

      const doSet = () => {
        set(parseInt(typing_value));
      }


      const in_basket = basket.map(x => x.offer_id === offer.id ? x.quantity : 0).reduce((a,b) => a + b, 0);
      const bg = in_basket > 0 ? Colours.orange : 'white';
      const fg = in_basket > 0 ? 'white' : 'black';
      const ic = in_basket > 0 ? 'white' : Colours.orange;

    return (
        <View style={{height: 36, justifyContent:'center', paddingLeft: 0, paddingRight: 0, backgroundColor:'white', ...(props.style || {})}}>
            {
                in_basket === 0 ?
                <TouchableOpacity onPress={add} style={{height: 36, padding: 3, justifyContent:'center', alignItems:'center', flexDirection:'row'}}>
                    <Txt style={{color:Colours.orange, fontWeight:'bold'}}>{lang.add_to_basket}</Txt>
                </TouchableOpacity>
                :

                <View style={{backgroundColor:bg, flexDirection:'row', alignItems:'center', justifyContent:'space-between', height: 36, padding: 3}}>
                    {
                        typing ? 
                        <View style={{width: 30}} />
                        :
                        <TouchableOpacity style={{width: 30, justifyContent:'center', flexDirection:'row', justifyContent:'flex-start', paddingLeft: 2}} onPress={minus}>
                            {
                                in_basket > 0 &&
                                <AntDesign name="minuscircleo" size={26} color={ic} />
                            }
                        </TouchableOpacity>
                    }

                    <TextInput
                        onFocus={inputFocus}
                        keyboardType="number-pad"
                        onBlur={inputBlur}
                        style={{width: 70, alignSelf:'stretch', textAlign:'center', fontWeight:'bold', color:fg}} 
                        value={`${typing_value}${ !typing ? ' Cases' : ''}`} 
                        onChangeText={onType}
                        onSubmitEditing={doSet}
                        returnKeyType='done'
                        />

                    {
                        typing ? <View style={{width: 30}} />
                        /*<TouchableOpacity style={{width: 30, flexDirection:'row', justifyContent:'flex-end', paddingRight: 2}} onPress={() => {
                            set(parseInt(typing_value))
                        }}>
                            <AntDesign name="checkcircleo" size={26} color={ic} />
                        </TouchableOpacity>*/
                        :
                        <TouchableOpacity style={{width: 30, flexDirection:'row', justifyContent:'flex-end', paddingRight: 2}} onPress={add}>
                            <AntDesign name="pluscircleo" size={26} color={ic} />
                        </TouchableOpacity>
                    }
                </View>
            }
        </View>
    )
}


const ProductPrice = props => {
    const { storefront: lang } = useContext(LanguageContext)
    const { token, actions: { showLoginModal } } = useContext(UserContext);
    const { offer, product } = props;

    if (!token) return (
        <View style={{alignSelf:'stretch', backgroundColor:Colours.blue, padding: 5, justifyContent:'center', alignItems:'center', flex: 1, flexDirection:'row', height: 70}}>
            <Txt onPress={() => showLoginModal(true)} style={{fontSize: 12, color:'blue', fontWeight:'bold', flexWrap: 'wrap'}}>{lang.please_login}</Txt>
        </View>
    )
    
    if (!offer) return (
        <View style={{alignSelf:'stretch', backgroundColor:Colours.pale_red, padding: 5, justifyContent:'center', alignItems:'center', flex: 1, minHeight: 70}}>
            <Txt style={{fontSize: 14, color:'red', fontWeight:'bold'}}>{lang.unavailable}</Txt>
        </View>
    )

    return (
        <View style={{alignSelf:'stretch', backgroundColor:offer.offer_type === 'Promotion' ? Colours.green: Colours.horrible_orange, padding: 5, paddingLeft: 3, flex: 1, justifyContent:'space-between', height: 34, minHeight: 34, flexDirection:'row', alignItems:'center'}}>
            <View style={{flexDirection:'row', alignItems:'center', alignSelf:'stretch', justifyContent:'space-between', flex: 1}}>
                <View style={{flexDirection:'row', alignItems:'center', justifyContent:'flex-start'}}>
                    {offer.offer_type === 'Promotion' && <AntDesign size={16} name="star" style={{marginRight: 8, marginLeft: 8}} color={Colours.orange} />}
                    <Txt style={{fontSize: 12, fontWeight:'bold'}}>€ {offer?.price.toFixed(2)}</Txt>
                </View>
                <Txt style={{fontSize: 9, color:Colours.grey}}>€ ({(offer.price / product.case_size).toFixed(2)} {lang.per_unit})</Txt>
            </View>
        </View>
    )
}

const ProductDetails = props => {
    const { product, offer, width } = props;
    return (
        <View style={{flex: 1}}>
            <View style={{flexDirection:'row', flex: 1, minHeight: 30, minHeight: 30, width, justifyContent:'center', alignItems:'center', justifyContent:'flex-start', whiteSpace:"normal"}}>
                <Txt style={{color:Colours.grey, fontWeight:'bold', fontSize: 10, textAlign:"center", flex: 1, whiteSpace: "normal"}}>{`${product.description.toUpperCase()}`} <Txt style={{color:'rgb(70, 70, 70)', marginLeft: 6}}>{product.case_size} x {product.size}</Txt></Txt>
            </View>
            <ProductPrice offer={offer} product={product} />
            <ProductBasketButtons offer={offer} product={product} />
        </View>
    )
}

const CatBit = props => {
    const { categories } = props;
    const { cat_data } = useContext(StoreContext);
    const names = categories.map(x => cat_data[x]?.name || 'Loading');

    return (
        <View style={{position:'absolute', top: 0, left: 0, flexDirection:'row'}}>
            {
                names.map((name, i) => <Txt style={{backgroundColor:'black', padding: 2, marginRight: 4, color:'white', fontSize: 10}} key={i}>{name}</Txt>)
            }
        </View>
    )
}

const Product = props => {
    const { register } = useContext(IfEventContext);
    const { id, size, offer: offer_id } = props;
    const { products, actions: { viewProd } } = useContext(StoreContext);
    const product = products[id] || null;

    const view = () => {
        register('view_product', {product_id: product.id});
        viewProd(product);
    }

    const offers = product?.offers;
    const offer = (offers || []).find(x => x.id === offer_id) || null;
    const product_w = size;

    return (
        <>
            <View style={{
                marginBottom: 10, 
                marginLeft: 5,
                 marginRight: 5, 
                 backgroundColor: 'rgba(0,0,0,0)', 
                 width: product_w, 
                 height: product_w + 100, 
                 justifyContent:"center", 
                 alignItems:'center', 
                 backgroundColor:Colours.light_grey, 
                 borderRadius: 10,
                 }}>
                {
                    product ?
                    <View style={{flex: 1, backgroundColor:'white', position: "relative", borderRadius: 5, overflow: 'hidden', borderColor: "red", borderWidth: product?.listed === 'NotListed' ? 2 : 0}}>
                        <TouchableOpacity onPress={view}>
                            <ProductImg product={product} size={product_w} />
                        </TouchableOpacity>
                        <ProductDetails width={product_w} offer={offer} product={product} />
                        {false && <CatBit categories={product.categories} />}
                        <Fave product_id={product.id} />
                    </View>
                    :
                    null
                }
            </View>
        </>
    )
}

const Prom = props => {
    const { front } = useContext(StoreContext);
    if (front.primary_domain != 'staging.albanyportugal.com') return null;

    return (
        <View style={{width: props.width, height: 200, justifyContent:'center', alignItems:'center', backgroundColor:'rgba(255,0,0,0.5)'}}>
            <Text style={{fontSize: 20}}>A Promotion Banner would go here</Text>
        </View>
    )
}

const ProductRow = props => {
    const { product_width, products, whole_width } = props;
    if (products?.is_prom) return <Prom width={whole_width} />

    return (
        <View style={{width: product_width * products.length, flexDirection:'row', alignItems:'flex-start'}}>
            {
                products.map((p,i) => <Product key={i} size={product_width} id={p[0]} offer={p[1]} />)
            }
        </View>
    )
}


const PROMS_EVERY_X = 10;

const StoreFront = props => {
    const { listing, actions: {listMore}, config, count } = useContext(StoreContext);
    const { width } = useWindowDimensions();
    const { actions: { setParams } } = useContext(LinksContext);
    const [rows_with, set_rows_width] = useState({rows: null, width: null});
    const listRef = useRef(null);
    const [is_resizing, set_is_resizing] = useState(false);
    const resize_timer = useRef(null);
    const [listing_interweave, set_listing_interweave] = useState([]);

    const updateInterweave = () => {
        const num_rows = rows_with?.rows;
        if (!num_rows) {
            set_listing_interweave([]);
            return
        }
        const out = [];
        for (var i=0; i < listing.length; i+=num_rows) {
            const this_row = listing.slice(i, i+num_rows);
            out.push(this_row)
        }

        if (true) { // insert proms every x rows
            let pos = 0;
            let added = 0;
    
            while (pos < out.length) {
                out.splice(pos, 0, {is_prom: true})
                added + 1;
                pos = pos + PROMS_EVERY_X + added;
            }
        }

        set_listing_interweave(out)
    }

    useEffect(() => {
        updateInterweave();
    }, [listing, rows_with?.rows])

    const onResize = () => {
        if (resize_timer.current) {
            clearTimeout(resize_timer.current);
        }
        if (!is_resizing) {
            set_is_resizing(true);
        }
        resize_timer.current = setTimeout(() => set_is_resizing(false), 100);
    }

    useEffect(() => {
        if (Platform.OS === 'web') {
            window.addEventListener('resize', onResize);
            return () => {
                window.removeEventListener('resize', onResize);
            }
        }
    }, [])

    useEffect(() => {
        const is_mobile = width <= 768;

        if (is_mobile) {
            set_rows_width({rows: 2, width: (width-30) /2});
            return
        }
        const available_width = width - 230;
        const rows = parseInt(available_width / 210);
        const w = ((available_width - 10) / rows) - 10;
        set_rows_width({rows, width: w});

    }, [width])

    useEffect(() => {
        if (config?.offset === 0) {
            if (listRef.current) {
                listRef.current.scrollToOffset({ animated: true, offset: 0 });
            }
        }
    }, [config?.offset])
    
    useEffect(() => {
        const params = props.route.params;
        if (params) {
            setParams(params);
        }
    }, [props.route.params]);

    const is_mobile = width <= 786;

    
    return (
        <View style={{flex: 1, backgroundColor:"rgb(240,240,240)", flexDirection:'row', alignSelf:'stretch', position:'absolute', top:0, right:0, bottom: 0,left:0}}>
            {
                !is_mobile && 
                <View style={{width: 230}}>
                    <Filters />
                </View>
            }
            <View style={{flex: 1, flexDirection:'column'}}>
                {
                    is_mobile &&
                    <Search />
                }
                <View style={{margin: 5, flex: 1}}>
                    {
                        is_resizing ? 
                            <View style={{flex: 1}} />
                        :
                        (rows_with?.rows && 
                            <FlashList
                                ref={listRef}
                                data={listing_interweave}
                                alwaysBounceVertical={false}
                                keyExtractor={(item, index) => index.toString()}
                                //renderItem={({item}) => {
                                //    return (<Product size={rows_with?.width} id={item[0]} offer={item[1]} />)
                                //}}
                                //numColumns={rows_with?.rows}
                                renderItem={({item}) => {
                                    return (
                                        <ProductRow whole_width={width - 230} product_width={rows_with?.width} products={item} />
                                    )
                                }}
                                key={rows_with?.rows}
                                onEndReachedThreshold={2}
                                estimatedItemSize={100}
                                onEndReached={listMore}
                                ListHeaderComponent={<Explainer />}
                                ListFooterComponent={
                                    listing.length < count ?
                                    <Button onPress={listMore} style={{marginTop: 10, marginBottom: 20}}>More</Button>
                                    :
                                    <View style={{marginBottom: 20, marginTop: 10}} />
                                }
                                />
                        )
                    }
                </View>
            </View>
        </View>
    )
}

export default StoreFront;

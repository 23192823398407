import { createContext, useContext, useEffect, useState } from "react";
import { api_url } from "../config";
import { StoreContext } from "./store";
import { UserContext } from "./user";
import { differenceInSeconds } from 'date-fns'
import { BasketContext } from "./basket";
import { Keyboard } from "react-native";
import { LanguageContext } from "./language";
import * as Linking from 'expo-linking';
import alert from '../components/alert';
import { AlertContext } from "./alert";

export const OrdersContext = createContext({});

const configIntoParams = c => {
    let parts = [];
    if (c.offset > 0) {
        parts.push(`offset=${c.offset}`);
    }
    if (c.limit) {
        parts.push(`limit=${c.limit}`);
    }
    if (c.customers !== null && c.customers.length > 0) {
        parts.push(`customers=${c.customers.join("%2B")}`)
    }
    if (c.user_ids !== null && c.user_ids.length > 0) {
        parts.push(`user_ids=${c.user_ids.join("%2B")}`)
    }
    if (parts.length === 0) return '';
    return `?${parts.join("&")}`
}


const OrdersWrapper = props => {
    const { orders_context: lang } = useContext(LanguageContext)
    const { children } = props;
    const { customer, token, user, ws_session } = useContext(UserContext);
    const { actions: { getRequiredProducts } } = useContext(StoreContext);
    const { actions: { didPlaceOrder, getBasket, viewBasket } }   = useContext(BasketContext);

    const [config, set_config] = useState({offset: 0, limit: 20, customers: null, user_ids: null});
    const [data, set_data] = useState({});
    const [listing, set_listing] = useState([]);
    const [count, set_count] = useState(0);
    const [orders_modal_showing, set_orders_modal_showing] = useState(false)
    const [viewing_order, set_viewing_order] = useState(null);
    const [resend_map, set_resend_map] = useState({});
    const [with_thanks, set_with_thanks] = useState(false);
    const [placing_order, set_placing] = useState(false);
    const { actions: { setAlert }} = useContext(AlertContext);
    

    const showOrdersModal = open => {
        Keyboard.dismiss();
        set_orders_modal_showing(open);
    }
    const viewOrder = order => {
        set_viewing_order(order);
        set_with_thanks(false);
        Keyboard.dismiss();
    }

    const cancelOrder = async (id) => {
        if (user?.is_rep) {
            const res = await fetch(`${api_url}/r/orders/cancel?ids=${id}`, {
                method:"GET",
                headers: {
                    "Accept": "application/json",
                    "authorization": `Bearer ${token}`
                }
            });
            if (res.ok) {
                const orders = await res.json();
                const as_map = {};

                orders.data.forEach(o => {
                    if (viewing_order?.id === o.id) {
                        set_viewing_order(o)
                    }
                    as_map[o.id] = o
                });
                set_data({
                    ...data,
                    ...as_map
                });
                return true;
            } else {
                return false;
            }
        }
        return false;
    }

    const listMore = async() => {
        const params_text = configIntoParams({...config, offset: listing.length, limit: 20});
        const url = `${api_url}/c/order/list${params_text}`;
        const res = await fetch(url, {method:"GET", headers: {
            "Accept": "application/json",
            "authorization": `Bearer ${token}`
        }}).then(data => data.json());
        set_listing(listing.concat(res.data.ids));
    }

    const listOrders = async () => {
        if (user) {
            const res = await fetch(`${api_url}/c/order/list${configIntoParams(config)}`, {
                method: "GET",
                headers: {
                    "Accept": "application/json",
                    'authorization': `Bearer ${token}`
                }
            });
            if (res.ok) {
                const body = await res.json();
                const { count, ids } = body.data;
                set_listing(ids);
                set_count(count);
            } else {
                const text_body = await res.text();
            }
        }
    }

    useEffect(() => {
        let ids = [];
        Object.values(data).forEach(order => {
            order.lines.forEach(line => {
                ids.push(line.product_id);
            })
        });
        getRequiredProducts(ids);
    }, [data])

    useEffect(() => {
        set_config({
            offset: 0,
            limit: 10,
            customers: null,
            user_ids: null
        })
    }, [user]);

    useEffect(() => {
        if (token) {
            listOrders();
        }
    }, [config]);

    useEffect(() => {
        if (token) {
            getRequiredOrders(listing)
        }
    }, [listing, token, orders_modal_showing])

    const getRequiredOrders = ids => {
        const need = ids.filter(x => !(x in listing));
        getOrders(need);
    }

    const getOrders = async (ids) => {
        if (ids.length === 0) return;
        if (user) {
            const res = await fetch(`${api_url}/c/order/get?ids=${ids.join("%2B")}`, {
                method: "GET",
                headers: {
                    "Accept": 'application/json',
                    'authorization': `Bearer ${token}`
                }
            });
            const body = await res.json();
            const orders = body.data.data;
            let product_ids = Object.values(orders).map(x => x.lines).reduce((a, b) => a.concat(b.map(x => x.product_id)), []);
            getRequiredProducts(product_ids);
            set_data(data => ({
                ...data,
                ...orders
            }));
            return orders
        }
    }

    const resendConfirmation = async (order_id) => {
        if (order_id in resend_map) {
            const last_sent = resend_map[order_id];
            const diff = differenceInSeconds(new Date(), last_sent);

            if (diff < 120) {
                const to_wait = 120 - diff;
                return {ok: false, wait: 120 - diff};
            }
        }

        const res = await fetch(`${api_url}/c/order/resend?ids=${order_id}`, {
            method:"GET",
            headers: {
                "Accept": "application/json",
                "authorization" : `Bearer ${token}`
            }
        });

        if (res.ok) {
            set_resend_map({
                ...resend_map,
                [order_id]: new Date()
            });
            return {ok : true, wait: null}
        }
        return { ok: false, wait: null };
    }

    useEffect(() => {
        if (viewing_order) {
            let product_ids = viewing_order.lines.map(x => x.product_id);
            getRequiredProducts(product_ids);
        }
    }, [viewOrder])

    const placeOrder = async () => {
        if (customer && token) {
            set_placing(true);
            const res = await fetch(`${api_url}/c/basket/place`, {
                method: 'POST',
                headers: {
                    "Accept": "application/json",
                    'Content-Type': 'application/json',
                    'X-CUSTOMER-ID': customer.id,
                    'authorization': `Bearer ${token}`,
                    "X-WS-SESSION": ws_session || undefined
                },
                body: JSON.stringify(({ reference: "TEST REF" }))
            });
            const body = await res.json();
            if (body.ok) {
                const order = body.data;
                didPlaceOrder();
                set_with_thanks(true);
                set_viewing_order(order);
                listOrders();
            } else {
                const data = body.error.data.data;
                if (data?.lines) {
                    if (data.lines === 'Invalid Lines') {
                        //viewBasket(false);
                    } else if (data.lines === 'Basket is Empty') {
                        setAlert({title: lang.cant_place_order, body: lang.basket_empty});
                    }
                    await getBasket();
                } else {
                    setAlert({title: lang.sorry, body: lang.something_went_wrong});
                }
            }
            set_placing(false);
        }
    }

    const downloadConfirmation = async (id) => {
        Linking.openURL(`${api_url}/c/order/confirmation?ids=${id}&t=${token}`);
    }

    const getOrder = async (id) => {
        const orders = await getOrders([id]);
        if (orders[id]) {
            await getRequiredProducts(orders[id].lines.map(x => x.product_id));
        }
        return orders[id];
    }

    const setOrderParams = (updates) => {
        set_config({...config, ...updates})
    }

    return (
        <OrdersContext.Provider value={{
            data,
            listing,
            count,
            config,
            orders_modal_showing,
            with_thanks,
            viewing_order,
            placing_order,
            actions: {
                listMore,
                viewOrder,
                listOrders,
                getOrders,
                showOrdersModal,
                resendConfirmation,
                placeOrder,
                getOrder,
                downloadConfirmation,
                setOrderParams,
                cancelOrder
            }
        }}>
            {children}
        </OrdersContext.Provider>
    )
}

export default OrdersWrapper;
import { useContext, useEffect, useState } from "react";
import { useWindowDimensions, View, ScrollView } from "react-native";
import { LanguageContext } from "../contexts/language";
import { UserContext } from "../contexts/user";
import ActionSheet from "./action_sheet";
import Button from "./button";
import TxtInput from "./txt_input";
import * as Colours from '../colours';
import { AlertContext } from "../contexts/alert";
import Txt from "./txt";

//LANGUAGE

const fields = ({apply_as: lang}) => [
    {
        name: 'user.first_name',
        label: lang.first_name,
        value: '',
        blurred: false,
        error: null,
        validate: (v) => {
            if (!v || v.length === 0) {
                return lang.required
            }
            return null
        }
    },
    {
        name: 'user.last_name',
        label: lang.last_name,
        value: '',
        blurred: false,
        error: null,
        validate: (v) => {
            if (!v || v.length === 0) {
                return lang.required
            }
            return null
        }
    },
    {
        name: 'user.email_address',
        label: lang.email_address,
        value: '',
        blurred: false,
        error: null,
        validate: (v) => {
            if (!v || v.length === 0) {
                return lang.required
            }
            return null
        }
    },
    {
        name: 'customer.name',
        label: lang.company_name,
        value: '',
        blurred: false,
        error: null,
        validate: (v) => {
            if (!v || v.length === 0) {
                return lang.required
            }
            return null
        }
    },
    {
        name: 'customer.company_no',
        label: lang.company_no,
        value: '',
        blurred: false,
        error: null,
        validate: (v) => {
            if (!v || v.length === 0) {
                return lang.required
            }
            return null
        }
    },
    {
        name: 'customer.email_address',
        label: lang.email_address,
        value:'',
        blurred: false,
        error: null,
        validate: (v) => {
            if (!v || v.length === 0) {
                return lang.required
            }
            return null
        }
    },
    {
        name: 'customer.website',
        label: lang.website,
        value: '',
        blurred: false,
        error: null,
        validate: (v) => null
    },
    {
        name: 'customer.phone',
        label: lang.phone,
        value: '',
        blurred: false,
        error: null,

        validate: (v) => {
            if (!v || v.length === 0) {
                return lang.required
            }
            return null
        }
    },
    {
        name: 'customer.address',
        label: lang.address,
        value: '',
        blurred: false,
        error: null,

        validate: (v) => {
            if (!v || v.length === 0) {
                return lang.required
            }
            return null
        }
    },
    {
        name: 'customer.postcode',
        label: lang.postcode,
        value: '',
        blurred: false,
        error: null,
        validate: (v) => {
            if (!v || v.length === 0) {
                return lang.required
            }
            return null
        }
    },
]

const ApplyAs = props => {
    const lang = useContext(LanguageContext);
    const { height, width } = useWindowDimensions();
    const { actions: { showApplyModal, apply }, apply_modal_showing: visible, user } = useContext(UserContext);
    const [application, set_application] = useState(fields(lang));
    const { actions: { setAlert }} = useContext(AlertContext);

    const update = (i, v) => {
        set_application([
            ...application.slice(0, i),
            {
                ...application[i],
                value: v,
                error: application[i].blurred ? application[i].validate(v) : application[i].error
            },
            ...application.slice(i + 1)
        ]);
    }

    const blur = i => {
        set_application([
            ...application.slice(0, i),
            {
                ...application[i],
                blurred: true,
                error: application[i].validate(application[i].value)
            },
            ...application.slice(i+1)
        ]);
    }

    const close = () => {
        showApplyModal(false);
        set_application(fields(lang));
    }

    const instr = () => {
        if (application.some(x => x.validate(x.value) !== null)) {
            return { instruction: null, ok: false }
        }
        return {
            instruction: {
                user: {
                    first_name: application[0].value,
                    last_name: application[1].value,
                    email_address: application[2].value
                },
                customer: {
                    name: application[3].value,
                    company_no: application[4].value,
                    email_address: application[5].value,
                    website: application[6].value,
                    phone: application[7].value,
                    address: application[8].value,
                    postcode: application[9].value,
                    support_email: 'why'
                }
            },
            ok: true
        }
    }

    const submit = async () => {
        const { ok, instruction } = instr();
        if (!ok) return;
        const response = await apply(instruction);
        if (response.ok) {
            const user = response.data.user;
            set_application(application.map(x => ({...x, error: null})));
            setAlert({
                title: 'Application Submitted', 
                body: `Thanks ${user.first_name} ${user.last_name}, we've received your application and will email you at ${user.email_address} once we've reviewed it`,
                onConfirm: close
            })
        } else {
            if (response.error?.message === 'Validation Errors') {
                Object.keys(response.error?.data?.data || {}).forEach(k => {
                    const invalid_index = application.map(x => x.name).indexOf(k);
                    set_application([
                        ...application.slice(0, invalid_index),
                        {
                            ...application[invalid_index],
                            error: response.error.data.data[k]
                        },
                        ...application.slice(invalid_index + 1)
                    ]);
                })
            }
        }
    }

    useEffect(() => {
        if (user) {
            close();
        }
    }, [user])

    return (
        <ActionSheet
            height={height * 0.9}
            close={close}
            visible={visible}
            cred="APPLY"
            title={lang.apply_as.apply_for_account}
        >
            <View style={{flex: 1, alignSelf:'stretch', marginTop: 10, alignItems:'center'}}>
                <View style={{flex: 1, alignSelf:'stretch'}}>
                        <ScrollView>
                            <View style={{flex: 1, justifyContent:'flex-start', alignItems:'stretch', alignSelf:'stretch'}}>
                                <View style={{alignSelf:'stretch', flexDirection:'column'}}>
                                    {
                                        application.map((field, i) => {
                                            const border_props = field.error ? {borderWidth: 2, borderColor: 'red'} : {};
                                            return (
                                                <View style={{alignSelf:'stretch'}} key={i}>
                                                    {
                                                        i === 0 ? 
                                                            <View style={{marginBottom: 5}}>
                                                                <Txt style={{fontWeight:'bold', marginBottom: 10}}>{lang.apply_as.about_you}</Txt>
                                                            </View>
                                                        : i === 3 ?
                                                            <View>
                                                                <Txt style={{fontWeight:'bold', marginBottom: 10}}>{lang.apply_as.about_your_company}</Txt>
                                                            </View>
                                                        : null

                                                    }
                                                    <View style={{alignSelf:'stretch', justifyContent:'space-between', alignItems:'center', marginBottom: 2, flexDirection:'row'}}>
                                                        <Txt style={{fontSize: 12}}>{field.label}</Txt>
                                                        <Txt style={{fontSize: 12, color: 'red'}}>{field.error}</Txt>
                                                    </View>

                                                    <TxtInput
                                                        value={field.value} 
                                                        onChange={v => update(i, v)}
                                                        can_clear
                                                        height={40}
                                                        onBlur={() => blur(i)}
                                                        onClear={() => update(i, null)}
                                                        style={{marginBottom: 10, borderWidth: 1, borderColor:Colours.light_grey, ...border_props}}
                                                        inputStyle={{fontSize: 11}}
                                                        inputProps={{...field.inputProps, onSubmitEditing: e => console.log(e)}}
                                                        />
                                                </View>
                                            )
                                        })
                                    }
                                </View>
                            </View>
                        </ScrollView>

                    <View style={{alignSelf:'stretch', flexDirection:'column', marginBottom: 30, marginTop: 20}}>
                        <Button onPress={submit}>{lang.apply_as.submit}</Button>
                    </View>
                </View>

            </View>
        </ActionSheet>
    )
}

export default ApplyAs;
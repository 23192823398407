import { Platform } from "react-native";
import GestureRecognizer from 'react-native-swipe-gestures';

const SwipeDown = ({onSwiper, children, style}) => {
    if (Platform.OS === 'web') {
        return children
    }
    return (<GestureRecognizer style={style || {}} onSwipeDown={onSwiper}>
        {children}
    </GestureRecognizer>)
}


export default SwipeDown;
import { createContext, useContext } from "react";
import { api_url } from "../config";
import { UserContext } from "./user";

export const IfEventContext =createContext({});

const IfEventWrapper = props => {
    const { token, customer } = useContext(UserContext);

    const register = async (event_type, event_data=null) => {
        fetch(`${api_url}/if_event`, {
            method:"POST",
            headers: {
                'Content-Type': "application/json",
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                event_data: {...event_data, customer: customer?.id},
                event_type
            })
        })
    }

    return (
        <IfEventContext.Provider value={{register}}>
            {props.children}
        </IfEventContext.Provider>
    )
}

export default IfEventWrapper;
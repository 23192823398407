import * as ImagePicker from 'expo-image-picker';
import { api_url } from '../config';

export const uploadImg = async (ean, token, file, onLoadStart=() => {}, onLoadEnd=() => {}) => {    
    onLoadStart();
    const fd = new FormData();
    fd.append('file', file);
    const res = await fetch(`${api_url}/r/product/push_image_pass/${ean}`, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "authorization": `Bearer ${token}`
        },
        body: fd
    });
    if (res.ok) {
        onLoadEnd();
        return true;
    }
    onLoadEnd();
    return false
}

import { createContext, useState, useEffect } from "react";
import { BarCodeScanner } from 'expo-barcode-scanner'
import Button from "../components/button";
import { View, Keyboard, Platform } from 'react-native';

export const ScanContext = createContext({});


const Scanner = props => {
    const { children } = props;
    const [has_permission, set_has_permission] = useState(null);
    const [scanning, set_scanning] = useState(false);
    const [last_value, set_last_value] = useState(null);
    const is_available = Platform.OS !== 'web';

    const getPermissions = async () => {
        if (is_available) {
            const { status } = await BarCodeScanner.requestPermissionsAsync();
            set_has_permission(status === 'granted');
        }
    }

    useEffect(() => {
        if (is_available) {
            getPermissions();
        }
    }, []);

    const handleCode = ({type, data}) => {
        set_last_value({type, data});
        set_scanning(false);
    }

    const setScanning = s => {
        Keyboard.dismiss();
        if (!has_permission) {
            getPermissions();
        } else {
            set_scanning(s);
        }
    }

    return (
        <ScanContext.Provider value={{actions: {setScanning}, last_value, is_available}}>
            {children}
            {
                scanning &&
                <>
                    <BarCodeScanner
                        onBarCodeScanned={handleCode}
                        style={{position: 'absolute', top: 0, right: 0, left: 0, bottom: 0}}
                        />
                        <View style={{position: 'absolute', left: 0, right: 0, bottom: 0, height: 100}}>
                            <Button style={{height: 100}} onPress={() => set_scanning(false)}>Cancel</Button>
                        </View>
                </>
            }
        </ScanContext.Provider>
    )
}

export default Scanner
import { useContext } from "react"
import { Platform, ScrollView, useWindowDimensions, View, Modal, TouchableWithoutFeedback, FlatList, TouchableOpacity } from "react-native"
import ActionSheet from "../components/action_sheet"
import DatePicker from "../components/date_picker"
import Txt from "../components/txt"
import { RepReportsContext } from "../contexts/rep_reports"
import { UserContext } from "../contexts/user"
import SalesYearChart from "./sales_year"
import { parse } from 'date-fns'
import * as Colours from '../colours';
import { Entypo } from '@expo/vector-icons'
import { useState } from "react"
import Button from "../components/button"
import { api_url } from "../config"
import { AlertContext } from "../contexts/alert"


const CustomerPicker = props => {
    const { actions: { setSalesConfig }, sales_config: { customers: selectedCustomers } } = useContext(RepReportsContext);
    const { customers: all_customers } = useContext(UserContext);
    const [visible, set_visible] = useState(false);
    const { width, height } = useWindowDimensions();
    const is_mobile = width <= 768;

    const toggle = id => {
        const sel = selectedCustomers || [];
        if (sel.indexOf(id) === -1) {
            setSalesConfig({customers: sel.concat([id])})
        } else {
            const n = sel.filter(x => x !== id);
            setSalesConfig({customers: n.length === 0 ? null : n});
        }
    }

    return (
        <>
            <View style={{flexDirection:'row', justifyContent:'space-between', alignItems:'center', padding: 8, paddingRight: Platform.OS === 'web' ? 20 : 8}}>
                <Txt>Customers</Txt>
                <Txt onPress={() => set_visible(true)}>{selectedCustomers === null ? 'All' : `${selectedCustomers.length} Selected`}</Txt>
            </View>

            <Modal visible={visible} transparent onRequestClose={() => set_visible(false)}>
                <TouchableWithoutFeedback onPress={() => set_visible(false)}>
                    <View style={{position:'absolute', top: 0, right: 0, left: 0, bottom: 0, backgroundColor:'rgba(0,0,0,0.5)', justifyContent:'center', alignItems:'center'}}>
                        <View style={{padding: 20, backgroundColor:'white', width: is_mobile ? width - 80 : 400, borderRadius: 5, maxHeight: height * 0.7}}>
                            <Txt style={{fontWeight: 'bold'}}>Customers</Txt>
                            <FlatList
                                data={all_customers}
                                keyExtractor={(_, i) => i.toString()}
                                ListHeaderComponent={
                                    <View style={{alignSelf:'stretch', flexDirection:'row', justifyContent:"space-between", alignItems:'center', padding: 3, paddingRight: Platform.OS === 'web' ? 10 : 3}}>
                                        <Txt>All</Txt>
                                        <TouchableOpacity style={{padding: 5}} onPress={() => setSalesConfig({customers: null})}>
                                            <Entypo name="check" size={24} color={selectedCustomers === null ? Colours.orange : Colours.grey} />
                                        </TouchableOpacity>
                                    </View>
                                }
                                renderItem={({item, index}) => {
                                    const checked = (selectedCustomers || []).indexOf(item.id) !== -1;
                                    return (
                                        <TouchableOpacity onPress={() => toggle(item.id)}>
                                            <View style={{alignSelf:'stretch', flexDirection:'row', justifyContent:'space-between', alignItems:"center", padding: 3, paddingRight: Platform.OS === 'web' ? 10 : 3}}>
                                                <Txt>{item.name}</Txt>
                                                <View style={{padding: 5}}>
                                                    <Entypo name="check" size={24} color={checked? Colours.orange : Colours.grey} />
                                                </View>
                                            </View>
                                        </TouchableOpacity>
                                    )
                                }}
                                />
                        </View>
                    </View>
                </TouchableWithoutFeedback>
            </Modal>
        </>
    )
}

const Downloader = props => {
    const { sales_config } = useContext(RepReportsContext);
    const { token } = useContext(UserContext);
    const [loading, set_loading] = useState(false);
    const { actions: { setAlert } } = useContext(AlertContext);

    const download = async () => {
        set_loading(true);
        const resp = await fetch(`${api_url}/r/analytics/charts/sales_data`, {
            method:"POST",
            headers: {
                "Content-Type": 'application/json',
                "authorization": `Bearer ${token}`
            },
            body: JSON.stringify(sales_config)
        });
        if (resp.ok) {
            const blob = await resp.blob();
            const aElement = document.createElement('a');
            aElement.setAttribute('download', 'FILENAME');
            const href = URL.createObjectURL(blob);
            aElement.href = href;
            aElement.setAttribute('target', '_blank');
            aElement.click();
            URL.revokeObjectURL(href);
        }
        set_loading(false);
    }

    const email = async () => {
        set_loading(true);
        const resp = await fetch(`${api_url}/r/analytics/charts/email_data`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "authorization": `Bearer ${token}`
            },
            body: JSON.stringify(sales_config)
        });
        if (resp.ok) {
            setAlert({
                title: "Data Emailed",
                body: "You'll receive an email containing the data you requested"
            });
        } else {
            setAlert({
                title: "Oops",
                body: "Something went wrong"
            });
        }
        set_loading(false);
    }
    if (Platform.OS !== 'web') {
        return (
            <Button loading={loading} style={{marginBottom: 30}} onPress={email}>Email Data to Me</Button>
        )
    }
    return (
        <Button loading={loading} style={{marginBottom: 30}} onPress={download}>Download Data</Button>
    )
}

const OverviewAs = props => {
    const { user } = useContext(UserContext);
    const { sales_year: { from, to }, overview_visible, actions: { showOverview, setSalesConfig } } = useContext(RepReportsContext)
    const { actions: { showAccountModal }} = useContext(UserContext);
    const { width, height } = useWindowDimensions();
    const is_mobile = width <= 768;

    const back = () => {
        showOverview(false);
        showAccountModal(true);
    }
    const updateFrom = f => {
        const frm = new Date(f);
        frm.setHours(12);
        setSalesConfig({start: frm});
    }
    const updateTo = t => {
        const trm = new Date(t);
        trm.setHours(12);
        setSalesConfig({end: trm});
    }

    if (!user?.is_rep) return null;

    const from_date = parse(from, "dd/MM/yyyy", new Date());
    const to_date = parse(to, "dd/MM/yyyy", new Date());

    return (
        <ActionSheet
            height={height * 0.9}
            close={() => showOverview(false)}
            visible={overview_visible}
            cred="OVERVIEW"
            title={"Overview"}
            back={back}
        >
            <View style={{flex: 1}}>
                <ScrollView>
                    <View style={{flexDirection: "row", alignSelf: 'stretch', justifyContent:'space-between', alignItems:'center', marginBottom: 5, padding: 8}}>
                        <Txt>From</Txt>
                        <DatePicker value={from_date} onChange={updateFrom} />
                    </View>
                    <View style={{flexDirection: "row", alignSelf: 'stretch', justifyContent:'space-between', alignItems:'center', padding: 8}}>
                        <Txt>To</Txt>
                        <DatePicker value={to_date} onChange={updateTo} />
                    </View>
                    <CustomerPicker />
                    <SalesYearChart width={is_mobile ? width - 40 : 380} />
                </ScrollView>
                <Downloader />
            </View>
        </ActionSheet>
    )
}

export default OverviewAs
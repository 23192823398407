import { useContext, useEffect, useRef, useState } from "react";
import { useWindowDimensions, View, Animated, TouchableOpacity } from "react-native";
import { LanguageContext } from "../contexts/language";
import { UserContext } from "../contexts/user";
import ActionSheet from "./action_sheet";
import Button from "./button";
import Sep from "./sep";
import TxtInput from "./txt_input";
import * as Colours from '../colours';
import { AlertContext } from "../contexts/alert";
import Txt from "./txt";
import { Ionicons } from '@expo/vector-icons';
//LANGUAGE

const num_re = /\d/;
const lower_case = /[a-z]/;
const upper_case = /[A-Z]/;

const OkBit = props => {
    const { label, ok } = props;
    const driver = useRef(new Animated.Value(0)).current;

    const color = driver.interpolate({inputRange: [0, 1], outputRange: [Colours.grey, 'green']})

    useEffect(() => {
        if (ok) {
            Animated.timing(driver, {toValue: 1, duration: 200, useNativeDriver: false }).start();
        } else {
            Animated.timing(driver, {toValue: 0, duration: 200, useNativeDriver: false }).start();
        }
    }, [ok])

    return (
        <Animated.View style={{borderRadius: 10, borderWidth: 1, borderColor: color, padding: 8, flexDirection: "row", justifyContent:"center", alignItems:'center', margin: 5}}>
            <Animated.View style={{borderRadius: 100, width: 8, height: 8, backgroundColor: color, marginRight: 10}}>

            </Animated.View>
            <Animated.Text style={{fontSize: 12, color: color, fontWeight:'bold'}}>{label}</Animated.Text>
        </Animated.View>
    )
}

const ResetAs = props => {
    const lang = useContext(LanguageContext);
    const { height, width } = useWindowDimensions();
    const { actions: { showResetPassword, resetPassword }, reset_password_showing: visible } = useContext(UserContext);
    const [current, set_current] = useState('');
    const [password, set_password] = useState('');
    const [confirm, set_confirm] = useState('');
    const [loading, set_loading] = useState(false);
    const [match, set_match] = useState(true);
    const [status, set_status] = useState({ok: false, errors: { lower: true, upper: true, num:true, length: true }})
    const [insecure, set_insecure] = useState(false);
    const [wrong_pw, set_wrong_pw] = useState(false)
    const { actions: { setAlert }} = useContext(AlertContext);
    const { actions: { showAccountModal }} = useContext(UserContext);

    const back = () => {
        showResetPassword(false);
        showAccountModal(true);
    }

    const close = () => showResetPassword(false);

    const validatePassword = () => {
        const errors = {
            lower: !lower_case.test(password),
            upper: !upper_case.test(password),
            num: !num_re.test(password),
            length: password.length < 8,
        }
        set_status({
            ok: !errors.lower && !errors.upper && !errors.num && !errors.length,
            errors
        })
    }

    useEffect(() => {
        validatePassword()
    }, [password])

    useEffect(() => {
        set_match(password === confirm);
    }, [password, confirm])


    const submit = async () => {
        set_loading(true);
        const error = await resetPassword(current, password);
        if (error) {
            if (error?.error?.data?.data?.current_password) {
                set_wrong_pw(true);
            } else {
                set_wrong_pw(false);
            }
        } else {
            setAlert({title: "Password Reset", body: "You've changed your password"});
            showResetPassword(false);
        }
        set_loading(false);
    }

    const border_props = wrong_pw ? {borderWidth: 2, borderColor: 'red'} : {};
    return (
        <ActionSheet
            height={height * 0.7}
            close={close}
            visible={visible}
            cred="RESET"
            title={lang.create_password_as.create_a_password}
            back={back}
        >
            <View style={{flex: 1, alignSelf:'stretch', marginTop: 10, alignItems:'center'}}>
                <View style={{flex: 1, alignSelf:'stretch'}}>
                    <View style={{flex: 1, justifyContent:'flex-start', alignItems:'stretch', alignSelf:'stretch'}}>
                        <Txt style={{marginTop: 20, marginBottom: 20}}>{lang.create_password_as.please_use}</Txt>
                        <View style={{alignSelf:'stretch'}}>
                            <View style={{alignSelf:'stretch', flexDirection: 'row', justifyContent:'space-between', marginTop: 10, marginBottom: 2}}>
                                <Txt>{lang.create_password_as.current_password}</Txt>
                                {
                                    wrong_pw ? 
                                    <Txt style={{color:"red"}}>Incorrect Password</Txt>
                                    :
                                    <Txt></Txt>
                                }
                            </View>
                            <TxtInput 
                                value={current} 
                                onChange={set_current}
                                can_clear
                                onClear={() => set_current('')}
                                placeholder={lang.create_password_as.current_password}
                                style={{marginBottom: 10, borderWidth: 1, borderColor:Colours.light_grey, height: 40, ...border_props}}
                                inputStyle={{fontSize: 14}}
                                secureTextEntry
                                />
                            <Sep height={20} />
                            <Txt style={{marginBottom: 2, marginTop: 10}}>{lang.create_password_as.password}</Txt>
                            <TxtInput 
                                value={password} 
                                onChange={set_password}
                                can_clear
                                onClear={() => set_password('')}
                                placeholder={lang.create_password_as.password}
                                style={{marginBottom: 10, borderWidth: 1, borderColor:Colours.light_grey, height: 40}}
                                inputStyle={{fontSize: 14}}
                                secureTextEntry
                                />
                            <Txt style={{marginBottom: 2}}>{lang.create_password_as.confirm_password}</Txt>
                            <TxtInput 
                                value={confirm} 
                                onChange={set_confirm}
                                can_clear
                                onClear={() => set_confirm('')}
                                placeholder={lang.create_password_as.confirm_password}
                                style={{marginBottom: 10, borderWidth: 1, borderColor:Colours.light_grey, height: 40}}
                                inputStyle={{fontSize: 14}}
                                secureTextEntry
                                />

                            <View style={{alignSelf:'stretch', alignItems:'center'}}>
                                <View style={{alignSelf:'stretch'}}>
                                    <View style={{alignSelf:'stretch', flexDirection:'row', justifyContent:'space-between', padding: 8}}>
                                        <OkBit label="A-Z" ok={status.errors.upper === false ? true : false} />
                                        <OkBit label="a-z" ok={status.errors.lower === false ? true : false} />
                                        <OkBit label="123" ok={status.errors.num === false ? true : false} />
                                        <OkBit label="8+" ok={status.errors.length === false ? true : false} />
                                    </View>
                                    <OkBit label="Password Match" ok={password === confirm} />
                                </View>
                            </View>
                        </View>
                    </View>
                    <View style={{alignSelf:'stretch', flexDirection:'column', marginBottom: 30}}>
                        <Button disabled={(status.ok === true && match) ? false : true} loading={loading} onPress={submit}>{lang.create_password_as.submit}</Button>
                    </View>
                </View>

            </View>
        </ActionSheet>
    )
}

export default ResetAs;
import { createContext, useContext, useEffect, useState } from "react";
import { Keyboard, TouchableOpacity } from "react-native";
import { UserContext } from "./user";
import { api_url } from "../config";
import { StoreContext } from "./store";
import { LanguageContext } from "./language";
import FullScreenNumberInput from "../components/full_screen_num_input";
import { AlertContext } from "./alert";
import { IfEventContext } from "./if_event";

export const BasketContext = createContext();


const PriceOveride = (props) => {
    const { overriding_price, actions: {setOverriding, setBasket} } = useContext(BasketContext);
    const { user } = useContext(UserContext);
    const [price, set_price] = useState('');

    useEffect(() => {
        if (overriding_price) {
            set_price(overriding_price.p_ov.toFixed(2));
        } else {
            set_price("");
        }
    }, [overriding_price])

    const submit = async (price) => {
        const new_price = parseFloat(price);
        if (isNaN(new_price)) return;

        const instr = {
            ...overriding_price,
            p_ov: new_price
        };

        await setBasket(instr);
        setOverriding(null);
    }

    const { visible } = props;
    if (!visible || !(user?.is_rep)) return null;

    return (
        <FullScreenNumberInput
            visible={user?.is_rep && props.visible}
            value={price}
            onChange={p => submit(p)}
            close={() => setOverriding(null)}
            />
        
    )
}


const BasketWrapper = props => {
    const { register } = useContext(IfEventContext);
    const { actions: { setAlert }} = useContext(AlertContext);
    const { basket_context: lang } = useContext(LanguageContext);
    const { customer, token, user, ws_session } = useContext(UserContext);
    const { children } = props;

    const { actions: { getRequiredProducts } } = useContext(StoreContext);
    const [basket, set_basket] = useState([]);
    const [adjustments, set_adjustments] = useState(null);
    const [viewing, set_viewing] = useState(false);
    const [overriding_price, set_ov_price] = useState(null);
    const [first_run, set_first_run] = useState(false);

    useEffect(() => {
        if (!first_run) {
            set_first_run(true);
        } else {
            register('toggle_view_basket', {open: viewing});
        }
    }, [viewing])

    const clearAdjustments = () => set_adjustments(null);
    
    const viewBasket = open => {
        Keyboard.dismiss();
        set_viewing(open);
    }

    useEffect(() => {
        const to_load = basket.map(x => x.product_id);
        getRequiredProducts(to_load);
    }, [basket, viewing])

    useEffect(() => {
        const to_load = Object.values(adjustments || {}).map(x => x.product_id);
        getRequiredProducts(to_load);
    }, [adjustments])

    useEffect(() => {
        if (token && customer) {
            getBasket();
        }
        if (!token) {
            set_basket([]);
            set_adjustments(null);
            set_viewing(false);
        }
    }, [token, customer])

    const getBasket = async () => {
        const res = await fetch(`${api_url}/c/basket`, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                'X-CUSTOMER-ID': customer.id,
                'authorization': `Bearer ${token}`
            }
        });
        const body = await res.json();
        console.log("RBASKET", body);
        const { lines, adjustments } = body.data;
        set_basket(lines);
        set_adjustments(adjustments);
    }

    const setBasket = async (instr) => {
        register("set_basket", instr);
        const res = await fetch(`${api_url}/c/basket/set`, {
            method: "POST",
            headers: {
                "Accept": "application/json",
                'X-CUSTOMER-ID': customer.id,
                "Content-Type": "application/json",
                'authorization': `Bearer ${token}`,
                "X-WS-SESSION": ws_session || undefined
            },
            body: JSON.stringify(instr)
        });

        if (res.ok) {
            const body = await res.json();
            const basket_prod = body.data;
            let current_index = basket.map(x => x.id).indexOf(basket_prod.id);
            if (current_index === -1) {
                set_basket(basket.concat([basket_prod]));
            } else {
                set_basket(basket.map(x => x.id === basket_prod.id ? basket_prod : x));
            }
        }
    }

    const didPlaceOrder = () => {
        set_basket([]);
        set_viewing(false);
    }

    const setOverriding = bi => set_ov_price(bi);

    const uploadPlof = async e => {
        register('upload_plof', {})
        const file = e.target.files[0];
        const fd = new FormData();
        fd.append('file', file);
        try {
            const data = await fetch(`${api_url}/c/basket/plof`, {
                method: "POST",
                headers: {
                    authorization: `Bearer ${token}`,
                    'X-CUSTOMER-ID': customer.id,
                    "accept": "application/json"
                },
                body: fd
            });

            const body = await data.json();
            set_basket(body.data);
            setAlert({
                title: "Order form Processed",
                body: "Your basket has been updated"
            });

        } catch(e) {
            setAlert({
                title: "Something went Wrong",
                body: "Unable to process order form"
            });
        }
    }

    return (
        <BasketContext.Provider value={{
            adjustments,
            viewing,
            basket,
            overriding_price,
            actions: {
                uploadPlof,
                setOverriding,
                setBasket,
                getBasket,
                viewBasket,
                clearAdjustments,
                didPlaceOrder
            }
        }}>
            {
                children
            }
            <PriceOveride visible={overriding_price !== null} />
        </BasketContext.Provider>
    )
}

export default BasketWrapper;
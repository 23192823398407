import { useContext } from "react";
import { useWindowDimensions, View, FlatList } from "react-native";
import { BasketContext } from "../contexts/basket";
import { LanguageContext } from "../contexts/language";
import { StoreContext } from "../contexts/store";
import ProductImg from "../storefront/product_img";
import ActionSheet from "./action_sheet";
import Button from "./button";
import Sep from "./sep";
import * as Colours from '../colours';
import Txt from "./txt";

//LANGUAGE

const Adjustment = props => {
    const lang = useContext(LanguageContext);
    const { adjustment, last } = props;
    const { products } = useContext(StoreContext);
    const product = products[adjustment.product_id] || null;

    return (
        <>
            <View style={{alignSelf:'stretch', flexDirection:'row', paddingTop: 8, paddingBottom: 8}}>
                <ProductImg product={product} size={80} />
                <View style={{flex: 1}}>
                    <Txt style={{fontSize: 14, color:Colours.grey, fontWeight:'bold'}}>{product?.description || lang.adjustments_as.loading} {product?.case_size} x {product?.size}</Txt>
                    <View style={{alignSelf:'stretch', flexDirection:'row'}}>
                        <Txt style={{marginTop: 6}}>{lang.adjustments_as.weve_reduced_1}<Txt style={{fontWeight:'bold'}}>{adjustment.was}</Txt>{lang.adjustments_as.weve_reduced_2}<Txt style={{fontWeight:'bold'}}>{adjustment.now}</Txt>{lang.adjustments_as.weve_reduced_3} {adjustment.reason}</Txt>
                    </View>
                </View>
            </View>
            {
                !last && <Sep height={20} />
            }
        </>
    )
}

const AdjustmentsAs = props => {
    const lang = useContext(LanguageContext)
    const { height, width } = useWindowDimensions();
    const { adjustments, actions: { clearAdjustments } } = useContext(BasketContext);

    return (
        <ActionSheet
            height={height * 0.8}
            close={() => {}}
            visible={adjustments !== null}
            cred="ADJUSt"
            title={lang.adjustments_as.sorry}
        >
            <View style={{flex: 1, alignSelf:'stretch', marginTop: 10, alignItems:'center'}}>
                <View style={{flex: 1, alignSelf:'stretch'}}>
                    <View style={{flex: 1, justifyContent:'flex-start', alignItems:'stretch', alignSelf:'stretch'}}>
                        <Txt style={{marginTop: 10, marginBottom: 20}}>{lang.adjustments_as.weve_reduced_title}</Txt>
                        <View style={{alignSelf:'stretch'}}>
                            <FlatList
                                data={Object.keys(adjustments || {})}
                                keyExtractor={(id, _) => id}
                                renderItem={({item, index}) => <Adjustment last={index === Object.keys(adjustments || {}).length - 1} adjustment={adjustments[item]} key={index} />}
                                />
                        </View>
                    </View>
                    <View style={{alignSelf:'stretch', flexDirection:'column', marginBottom: 30}}>
                        <Button onPress={clearAdjustments}>{lang.adjustments_as.ok}</Button>
                    </View>
                </View>

            </View>
        </ActionSheet>
    )
}

export default AdjustmentsAs;
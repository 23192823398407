import { useContext, useEffect, useState } from "react";
import { useWindowDimensions, View, FlatList } from "react-native";
import { UserContext } from "../contexts/user";
import ActionSheet from "./action_sheet";
import { OrdersContext } from "../contexts/orders";
import { LanguageContext } from "../contexts/language";
import Txt from "./txt";
import { ApplicationsContext } from "../contexts/applications_rep";
import { RepReportsContext } from "../contexts/rep_reports";
import { StoreContext } from "../contexts/store";
import { RepOffersContext } from "../contexts/rep_offers";
import ListRow from "./list_row";
import RepButton from "./rep_button";
import FullScreenNumberInput from "./full_screen_num_input";
//LANGUAGE

const HorizDataPoint = ({label, value}) => {
    return (
        <View style={{alignItems:'center', flexDirection:'column'}}>
            <Txt style={{textAlign:'center', fontWeight: 'bold'}}>{value}</Txt>
            <Txt style={{textAlign:'center'}}>{label}</Txt>
        </View>
    )
}

const SellPrice = props => {
    const { actions: { setSell, pushOffer } } = useContext(RepOffersContext);
    const { products } = useContext(StoreContext);
    const { offer } = props;

    const clear = () => {
        pushOffer({
            ...offer,
            sell: false
        })
    }

    const actual_sell = products?.[offer?.product_id].offers.find(x => x.id === offer?.id)?.price?.toFixed(2);

    return (
        <View style={{flexDirection:'row', alignItems:'center'}}>
            <RepButton onPress={() => setSell(offer)}>
                <HorizDataPoint label="Sell" value={offer?.sell ? `${offer.sell.toFixed(2)}` : `On Policy (${actual_sell})`} />
            </RepButton>
            {
                offer.sell && 
                <View style={{alignSelf:'stretch', justifyContent:'center', paddingLeft: 10}}>
                    <RepButton onPress={clear}>
                        <Txt style={{color:'red'}}>Clear</Txt>
                    </RepButton>
                </View>
            }
        </View>
    )
}

const OfferRow = props => {
    const { id, last } = props;
    const { offers, actions: { setSell } } = useContext(RepOffersContext);
    const offer = offers[id];
    if (!offer) return null;

    const available = () => {
        if (!offer) return null;
        if (offer.available.Qty) {
            return `${offer.available.Qty} cases available`
        }
        if (offer.available === 'None') {
            return 'None Available'
        }
        if (offer.available === 'Open') {
            return 'Available'
        }
        return ""
    }

    return (
        <ListRow
        style={{height: 100}}
        //onPress={() => props.view(app)}
        title={`${offer.reference} (${offer.human_ref})`}
        subtitle={available()}
        product_for_image={null}
        key_values={[
            {key: <HorizDataPoint label="Cost" value={offer.cost.toFixed(2)} />}, 
            {key: <SellPrice offer={offer} />}, 
            {key: <HorizDataPoint label="Type" value={offer.offer_type} />}, 
        ]}
        last={last}
        >
            
        </ListRow>
    )
}

const OffersAs = () => {
    const { height, width } = useWindowDimensions();
    const lang = useContext(LanguageContext);
    const { user } = useContext(UserContext);
    const { actions: { viewProd, viewProductOffers }, viewing_product, viewing_product_offers: product } = useContext(StoreContext);
    const { actions: { getRequiredOffers }, offers} = useContext(RepOffersContext);
    const close = () => viewProductOffers(null);

    useEffect(() => {
        if (product) {
            const ids = (product?.offers || []).map(x => x.id);
            getRequiredOffers(ids);
        }
    }, [product])

    useEffect(() => {
        if (!user) {
            close();
        }
    }, [user])

    const back = () => {
        const prod = product;
        viewProductOffers(null);
        viewProd(prod);
    }
    const visible = product !== null;
    return (
        <ActionSheet
            height={height * 0.8}
            close={close}
            visible={visible}
            cred="ACCT"
            title={`Current Offers for ${product?.description}`}
            back={back}
        >
            <View style={{flex: 1, alignSelf:'stretch', marginTop: 10, alignItems:'center'}}>
                <View style={{flex: 1, alignSelf:'stretch'}}>                    
                    <View style={{flex: 1}}>
                        <FlatList
                            data={product?.offers?.map(x => x.id)}
                            renderItem={({item, index}) => <OfferRow id={item} />}
                            keyExtractor={(item, _) => item}
                            />
                    </View>
                </View>
            </View>
        </ActionSheet>
    )
}

export default OffersAs;
import { useContext, useState } from "react";
import { useWindowDimensions, View, ScrollView } from "react-native";
import { LanguageContext } from "../contexts/language";
import { UserContext } from "../contexts/user";
import ActionSheet from "./action_sheet";
import Button from "./button";
import TxtInput from "./txt_input";
import * as Colours from '../colours';
import Txt from "./txt";
import { AlertContext } from "../contexts/alert";

//LANGUAGE

const fields = ({apply_as: lang}) => [
    {
        name: 'customer.name',
        label: lang.company_name,
        value: 'TEST',
        blurred: false,
        error: null,
        inputProps: {autoCapitalize: 'words', maxLength: 200, returnKeyType: 'next', textContentType:'organizationName'},
        validate: (v) => {
            if (!v || v.length === 0) {
                return lang.required
            }
            return null
        }
    },
    {
        name: 'customer.company_no',
        label: lang.company_no,
        value: '999111',
        blurred: false,
        error: null,
        inputProps: {autoCapitalize: 'characters', maxLength: 100, returnKeyType: 'next'},
        validate: (v) => {
            if (!v || v.length === 0) {
                return lang.required
            }
            return null
        }
    },
    {
        name: 'customer.email_address',
        label: lang.email_address,
        value:'abc@zy.com',
        blurred: false,
        error: null,
        inputProps: {autoCapitalize: 'none', autoComplete: 'email', maxLength: 100, returnKeyType: 'next', textContentType: "emailAddress", keyboardType:'email-address'},
        validate: (v) => {
            if (!v || v.length === 0) {
                return lang.required
            }
            return null
        }
    },
    {
        name: 'customer.website',
        label: lang.website,
        value: '',
        blurred: false,
        error: null,
        validate: (v) => null
    },
    {
        name: 'customer.phone',
        label: lang.phone,
        value: '07939898343',
        blurred: false,
        error: null,
        inputProps: {autoCapitalize: 'none', autoComplete: 'tel-country-code', maxLength: 100, returnKeyType: 'next', textContentType: "telephoneNumber", keyboardType:'phone-pad'},
        validate: (v) => {
            if (!v || v.length === 0) {
                return lang.required
            }
            return null
        }
    },
    {
        name: 'customer.address',
        label: lang.address,
        value: '23 Royle Barn Road, Rochdale',
        blurred: false,
        error: null,
        inputProps: {autoCapitalize: 'words', autoComplete: 'street-address', maxLength: 300, returnKeyType: 'next', textContentType: "fullStreetAddress"},
        validate: (v) => {
            if (!v || v.length === 0) {
                return lang.required
            }
            return null
        }
    },
    {
        name: 'customer.postcode',
        label: lang.postcode,
        value: 'OL126HP',
        blurred: false,
        error: null,
        inputProps: {autoCapitalize: 'characters', autoComplete: 'postal-code', maxLength: 300, returnKeyType: 'next', textContentType: "postalCode"},
        validate: (v) => {
            if (!v || v.length === 0) {
                return lang.required
            }
            return null
        }
    },
]

const AddStoreAs = props => {
    const lang = useContext(LanguageContext);
    const { height, width } = useWindowDimensions();
    const { actions: { showAddCustomer, apply }, add_customer_showing: visible, token, user } = useContext(UserContext);
    const [application, set_application] = useState(fields(lang));
    const [loading, set_loading] = useState(false);
    const { actions: { setAlert }} = useContext(AlertContext);

    const update = (i, v) => {
        set_application([
            ...application.slice(0, i),
            {
                ...application[i],
                value: v,
                error: application[i].blurred ? application[i].validate(v) : application[i].error
            },
            ...application.slice(i + 1)
        ]);
    }

    const blur = i => {
        set_application([
            ...application.slice(0, i),
            {
                ...application[i],
                blurred: true,
                error: application[i].validate(application[i].value)
            },
            ...application.slice(i+1)
        ]);
    }

    const close = () => {
        showAddCustomer(false);
        set_application(fields(lang));
    }

    const instr = () => {
        if (application.some(x => x.validate(x.value) !== null)) {
            return { instruction: null, ok: false }
        }
        return {
            instruction: {
                customer: {
                    name: application[0].value,
                    company_no: application[1].value,
                    email_address: application[2].value,
                    website: application[3].value,
                    phone: application[4].value,
                    address: application[5].value,
                    postcode: application[6].value,
                    support_email: 'why'
                },
                user: user.id
            },
            ok: true
        }
    }

    const submit = async () => {
        const { ok, instruction } = instr();
        if (!ok) return;
        set_loading(true);
        const response = await apply(instruction);
        if (response.ok) {
            set_application(application.map(x => ({...x, error: null})));
            setAlert({
                title: "Application Submitted",
                body: `Thanks ${user.first_name} ${user.last_name}, we've received your application and will email you at ${user.email_address} once we've reviewed it`,
                onConfirm: close
            });

        } else {
            if (response.error?.message === 'Validation Errors') {
                Object.keys(response.error?.data?.data || {}).forEach(k => {
                    const invalid_index = application.map(x => x.name).indexOf(k);
                    set_application([
                        ...application.slice(0, invalid_index),
                        {
                            ...application[invalid_index],
                            error: response.error.data.data[k]
                        },
                        ...application.slice(invalid_index + 1)
                    ]);
                })
            }
        }
        set_loading(false);
    }

    return (
        <ActionSheet
            height={height * 0.7}
            close={close}
            visible={visible}
            cred="ADDSTORE"
            title="Add a Store"
        >
            <View style={{flex: 1, alignSelf:'stretch', marginTop: 10, alignItems:'center', alignSelf:'stretch'}}>
                <View style={{flex: 1, alignSelf:'stretch'}}>
                        <ScrollView>
                            <View style={{flex: 1, justifyContent:'space-evenly', alignItems:'stretch', alignSelf:'stretch'}}>
                                <View style={{alignSelf:'stretch'}}>
                                    {
                                        application.map((field, i) => {
                                            const border_props = field.error ? {borderWidth: 2, borderColor: 'red'} : {};
                                            return (
                                                <View key={i}>
                                                    <View style={{alignSelf:'stretch', justifyContent:'space-between', alignItems:'center', marginBottom: 2, flexDirection:'row'}}>
                                                        <Txt style={{fontSize: 12}}>{field.label}</Txt>
                                                        <Txt style={{fontSize: 12, color: 'red'}}>{field.error}</Txt>
                                                    </View>

                                                    <TxtInput
                                                        value={field.value} 
                                                        onChange={v => update(i, v)}
                                                        can_clear
                                                        height={40}
                                                        onBlur={() => blur(i)}
                                                        onClear={() => update(i, null)}
                                                        style={{marginBottom: 10, borderWidth: 1, borderColor:Colours.light_grey, ...border_props}}
                                                        inputStyle={{fontSize: 11}}
                                                        inputProps={{...field.inputProps, onSubmitEditing: e => console.log(e)}}
                                                        />
                                                </View>
                                            )
                                        })
                                    }
                                </View>
                            </View>
                        </ScrollView>
                    <View style={{alignSelf:'stretch', flexDirection:'column', marginBottom: 30, marginTop: 20}}>
                        <Button loading={loading} onPress={submit}>{lang.apply_as.submit}</Button>
                    </View>
                </View>

            </View>
        </ActionSheet>
    )
}

export default AddStoreAs;
import { useState } from "react";
import { createContext } from "react";
import AwesomeAlert from 'react-native-awesome-alerts';
import * as Colours from '../colours';

export const AlertContext = createContext({});

const AlertWrapper = props => {
    const [alert, set_alert] = useState(null);
    
    const setAlert = a => set_alert(a);

    const doConfirm = () => {
        if (alert?.onConfirm) {
            alert.onConfirm();
        }
        set_alert(null)
    }
    const doCancel = () => {
        if (alert?.onCancel) {
            alert.onCancel();
        }
        set_alert(null)
    }

    return (
        <AlertContext.Provider value={{actions: { setAlert }}}>
            {props.children}
            <AwesomeAlert
                show={alert !== null}
                title={alert?.title}
                message={alert?.body}
                closeOnHardwareBackPress={true}
                closeOnTouchOutside={true}
                showCancelButton={alert?.onCancel ? true : false}
                showConfirmButton={true}
                cancelText={alert?.cancel_text || 'Cancel'}
                confirmText={alert?.confirm_text || 'OK'}
                onCancelPressed={doCancel}
                onConfirmPressed={doConfirm}
                confirmButtonColor={Colours.orange}
                overlayStyle={{
                    position:'absolute',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    backgroundColor:'rgba(0,0,0,0.7)'
                }}
                />
        </AlertContext.Provider>
    )
}
export default AlertWrapper;
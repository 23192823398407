
export const get = (key, parse=true) => {
    const value = window.localStorage.getItem(key);
    if (value) {
        return parse ? JSON.parse(value) : value
    }
    return null
}

export const set = (key, value, stringiy=true) => {
    const v = stringiy ? JSON.stringify(value) : value;
    window.localStorage.setItem(key, v)
}

export const del = (key) => {
    window.localStorage.removeItem(key)
}

export const exists = key => {
    const value = window.localStorage.getItem(key)
    if (value) return true
    return false
}

export const getToken = () => {
    const tkn = get('token', false);
    return tkn || null;
}
export const setToken = async(tkn) => {
    set('token', tkn, false)
}
export const delToken = async() => {
    del('token');
}
import { RepReportsContext } from "../contexts/rep_reports";
import Txt from '../components/txt';
import { useContext } from "react";
import { LineChart, PieChart } from "react-native-chart-kit";
import * as Colours from '../colours';
import { View } from "react-native";
import InfoRow from '../components/info_table';

export const CustomerPie = props => {
    const { sales_year: { customer_pie }} = useContext(RepReportsContext);
    if (!customer_pie) return null;
    const { width } = props;

    return (
        <View style={{alignSelf:'stretch'}}>
            <Txt style={{fontWeight:'bold', marginBottom: 10, marginTop: 10, alignSelf:'flex-start'}}>Sales by Customer</Txt>
            <PieChart
                data={customer_pie.map((x, i) => ({...x, sales: parseInt(x.sales), color: `rgba(255, ${100 - (i * 8)}, ${i * 10}, ${1-(0.08 * i)})`, legendFontSize: 10, legendFontColor: 'black'}))}
                width={width}
                height={220}
                chartConfig={{
                    backgroundGradientFrom: Colours.orange,
                    backgroundGradientTo: Colours.purple,
                    decimalPlaces: 0,
                    color: () => 'white',
                    style: {borderRadius: 17, paddingL: 20}
                }}
                backgroundColor="transparent"
                accessor="sales"
                label="name"
                absolute
                />
        </View>
    )
}

const SalesYearChart = props => {
    const { sales_year: { chart, sales_val, cost_val, from, to } } = useContext(RepReportsContext);
    if (!chart) return null;
    const { width } = props;

    return (
        <View style={{alignSelf:'stretch', alignItems:'center'}}>
            <Txt style={{fontWeight: 'bold', marginBottom: 10, marginTop: 10, alignSelf:'flex-start'}}>Sales from {from} to {to}</Txt>
            <LineChart
                data={{
                    ...(chart || {}),
                    labels: chart.labels.map(x => x.split("-")[1])
                }}
                width={width}
                height={300}
                yAxisLabel='€'
                chartConfig={{
                    backgroundColor:Colours.purple,
                    backgroundGradientFrom:Colours.orange,
                    backgroundGradientTo:Colours.green,
                    decimalPlaces: 0,
                    color:() => `white`,
                    style:{borderRadius: 17, padding: 20}
                }}
                bezier
                style={{borderRadius: 16, marginVertical: 8}}
                />
                <View style={{alignSelf:'stretch'}}>
                    <InfoRow label="Total Sales"><Txt>€ {(sales_val || 0.00).toFixed(2)}</Txt></InfoRow>
                    <InfoRow label="Total Cost"><Txt>€ {(cost_val || 0.00).toFixed(2)}</Txt></InfoRow>
                    <InfoRow label="Gross Profit"><Txt>€ {((sales_val || 0.00) - (cost_val || 0.00)).toFixed(2)}</Txt></InfoRow>
                    <InfoRow last label="Gross Mgn"><Txt>{((((sales_val || 0.00) - (cost_val || 0.00)) / (sales_val || 0.00))*100).toFixed(2)}%</Txt></InfoRow>
                </View>
                {sales_val > 0 &&
                    <CustomerPie width={width} />
                }
        </View>
    )
}


export default SalesYearChart
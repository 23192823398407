import { View } from "react-native"
import Sep from './sep';
import * as Colours from '../colours';
import Txt from "./txt";
import TxtInput from './txt_input';
import { useEffect, useState } from "react";
import { TouchableOpacity } from "react-native";
import { Entypo, AntDesign } from '@expo/vector-icons';

const EditableRow = props => {
    const { label, children, onChange, last, value, processValue, validate, warning: warn } = props;
    const [error, set_error] = useState(null);
    const [warning, set_warning] = useState(null);
    const [editing, set_editing] = useState(false);
    const [temp_value, set_temp_value] = useState(value);

    const set = t => {
        if (warn) {
            set_warning(warn(t))
        }
        set_temp_value(t)
    }

    const cancel = () => {
        set_temp_value(value);
        set_editing(false)
    }

    const submit = () => {
        let v = temp_value;
        if (processValue) {
            v = processValue(v);
        }

        if (validate) {
            const e = validate(v);
            if (e) {
                set_error(e);
                return
            } else {
                set_error(null);
            }
        }

        onChange(v);
        set_editing(false);
    }

    if (editing) {
        return (
            <>
                <View style={{alignSelf:'stretch', flexDirection:'row', justifyContent:'space-between', alignItems:'center', paddingTop: 12, paddingBottom: 12}}>
                    <View style={{flexDirection:'row', justifyContent:'flex-start', alignItems:'flex-start'}}>
                        <Txt style={{fontSize:14, color: Colours.grey, fontWeight: 'bold'}}>{label}</Txt>
                    </View>
                    <View style={{flexDirection:'column', alignItems:'flex-end'}}>
                        <View style={{flexDirection:'row', alignItems:"center"}}>
                            <TxtInput
                                height={20}
                                style={{height: 20, backgroundColor:"lightgrey"}}
                                value={temp_value}
                                inputStyle={{fontSize: 14, textAlign:'right', paddingRight: 10}}
                                inputProps={{autoFocus:true, returnKeyType: 'done', onSubmitEditing: submit}}
                                onChange={t => set(t)}
                                />

                                <TouchableOpacity onPress={cancel} style={{marginRight: 8, marginLeft: 8}}>
                                    <AntDesign name="close" size={20} color={'red'} />
                                </TouchableOpacity>

                                <TouchableOpacity onPress={submit}>
                                    <Entypo name="check" size={20} color={Colours.orange} />
                                </TouchableOpacity>
                        </View>
                        {
                            error ? <Txt style={{fontSize: 12, color:'red'}}>{error}</Txt>
                            :
                            warning ? <Txt style={{fontSize: 12, color:'orange'}}>{warning}</Txt>
                            :
                            null
                        }
                    </View>
                </View>
                {
                    !last && <Sep />
                }
            </>

        )
    }

    return (
        <>
            <View style={{alignSelf:'stretch', flexDirection:'row', justifyContent: 'space-between', alignItems:'center', paddingTop: 12, paddingBottom: 12}}>
                <Txt style={{fontSize: 14, color:Colours.grey, fontWeight:'bold'}}>{label}</Txt>
                <TouchableOpacity onPress={() => set_editing(true)}>
                    <Txt>{value}</Txt>
                </TouchableOpacity>
            </View>
            {
                !last && <Sep />
            }
        </>
    )
}

const Row = props => {
    const { label, children, last, value, editable } = props;
    if (value && editable) {
        return <EditableRow {...props} />
    };

    const display = value || children;
    return (
        <>
            <View style={{alignSelf:'stretch', flexDirection:'row', justifyContent: 'space-between', alignItems:'center', paddingTop: 12, paddingBottom: 12}}>
                <Txt style={{fontSize: 14, color:Colours.grey, fontWeight:'bold'}}>{label}</Txt>
                {typeof display === 'string' ? <Txt>{display}</Txt> : display}
            </View>
            {
                !last && <Sep />
            }
        </>
    )
}

export default Row;
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { createContext, useContext } from "react";
import { api_url } from "../config";
import { UserContext } from './user';

export const RepReportsContext = createContext({});

const RepReportWrapper = props => {
    const { user, token } = useContext(UserContext);
    const [sales_config, set_sales_config] = useState({
        start: null,
        end: null,
        interval: 'month',
        customers: null
    })
    const [sales_year, set_sales_year] = useState({
        chart: null,
        sales_val: null,
        cost_val: null,
        customer_pie: null,
        from: null,
        to: null
    });
    const [overview_visible, set_overview_visible] = useState(false);

    const showOverview = (open) => set_overview_visible(open)

    const getYearSales = async () => {
        if (!user?.is_rep) return

        const resp = await fetch(`${api_url}/r/analytics/charts/sales_year`, {
            method:"POST",
            headers: {
                "Accept": "application/json",
                authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(sales_config)
        })
        if (resp.ok) {
            const body = await resp.json();
            set_sales_year(body.data);
        }
    }

    const setSalesConfig = (updates) => {
        set_sales_config({
            ...sales_config,
            ...updates
        })
    }

    useEffect(() => {
        if (user?.is_rep) {
            getYearSales();
        }
    }, [user?.is_rep, sales_config]);

    return (
        <RepReportsContext.Provider value={{
            sales_year,
            overview_visible,
            sales_config,
            actions: {
                getYearSales,
                showOverview,
                setSalesConfig
            }
        }}>
            {props.children}
        </RepReportsContext.Provider>
    )
}

export default RepReportWrapper
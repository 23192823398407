import { useContext, useEffect, useRef, useState } from "react"
import { TouchableOpacity, View } from "react-native"
import TxtInput from "../components/txt_input";
import { StoreContext } from "../contexts/store";
import { Ionicons } from '@expo/vector-icons';
import { LanguageContext } from "../contexts/language";
import { ScanContext } from "../contexts/scanner";
import { IfEventContext } from "../contexts/if_event";


const Search = props => {
    const { register } = useContext(IfEventContext);
    const { sidebar } = props;
    const { search: lang } = useContext(LanguageContext);
    const { config, actions: { setStoreParams: params} } = useContext(StoreContext);
    const { actions: { setScanning }, last_value, is_available } = useContext(ScanContext);
    const [term, set_term] = useState('');
    const timer = useRef(null);

    useEffect(() => {
        if (last_value) {
            if (timer.current) {
                clearTimeout(timer.current);
            }
            params({term: last_value?.data, offset:0, categories: null, cas: null})
        }
    }, [last_value])

    useEffect(() => {
        if (config.term) {
            set_term(config.term);
        } else {
            set_term(null);
        }
    }, [config.term])

    const setTerm = term => {
        if (timer.current) {
            clearTimeout(timer.current);
        }

        set_term(term);
        timer.current = setTimeout(() => {
            params({term, offset: 0, categories: null})
            register('do_search', { term });
        }, 300)
    }

    const clear = () => {
        set_term(null);
        params({term: null, offset: 0})
        register("clear_search", {})
    }

    const ip_height = 40;
    const icon_size = 30;
    const top_offset = (ip_height - icon_size) / 2;

    if (sidebar) {
        return (
            <View style={{alignSelf:'stretch', height: 30, marginBottom: 20}}>
                <TxtInput
                    placeholder={'eg "Heinz"'}
                    value={term}
                    onChange={setTerm}
                    onClear={clear}
                    can_clear
                    height={30}
                    inputStyle={{width: 200, backgroundColor:'rgb(240, 240, 240)'}}
                    inputProps={{placeholderTextColor:'grey', onFocus: () => register('search_focus', {sidebar})}}
                    />
            </View>
        )
    }

    return (
        <View style={{alignSelf:'stretch', backgroundColor:'rgb(240, 240, 240)', flexDirection:'row', padding: 5, paddingBottom: 0, paddingLeft: 10, paddingRight: 10}}>
            <View style={{overflow:'hidden', flex: 1, alignSelf:'stretch', borderRadius: 10, height: ip_height}}>
                <TxtInput
                    placeholder={lang.search}
                    value={term}
                    onChange={setTerm}
                    onClear={clear}
                    can_clear
                    height={ip_height}
                    inputProps={{onFocus: () => register('search_focus', {sidebar})}}
                    />
                {
                    !term && is_available && 
                    <TouchableOpacity onPress={() => setScanning(true)} style={{position:'absolute', right: top_offset, top: top_offset}}>
                        <Ionicons name="barcode-sharp" size={30} color='black' />
                    </TouchableOpacity>
                }
            </View>
        </View>
    )
}

export default Search
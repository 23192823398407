import { createContext, useContext, useEffect, useReducer, useRef, useState } from "react";
import {UserContext} from "./user";
import { api_url } from "../config";
import { Keyboard } from "react-native";
import { IfEventContext } from "./if_event";

export const StoreContext = createContext({});
const product_chunk_size = 100;

const cas_into_args = cas => {
    if (!cas) return ''
    let res = Object.values(cas).filter(x => x !== undefined).map(c => into_cas_arg(c)).join("|");
    return res
}

const args_into_cas = args => {
    const cas = {};
    let parts = args.split("|").map(x => from_cas_arg(x)).filter(x => x !== null).forEach(a => cas[a.key] = a);
    return cas;
}

const into_cas_arg = ({ key, comp, val }) => {
    return `${key}.${comp}.${val}`
}
const from_cas_arg = (str) => {
    let parts = str.split(".");
    if (parts.length === 3) {
        return {
            key: parts[0],
            comp: parts[1],
            val: parts[2]
        }
    }
    return null;
}

export const configFromParams = p => {
    const config = {};
    if (p.offset) {
        const o = parseInt(p.offset);
        if (!isNaN(o)) {
            config.offset = o;
        }
    }
    if (p.limit) {
        const l = parseInt(p.limit);
        if (!isNaN(l)) {
            config.limit = l
        }
    }
    if (p.term) {
        config.term = p.term;
    }
    if (p.stock) {
        config.stock = p.stock;
    }
    if (p.defined_sets) {
        config.defined_sets = p.defined_sets.split('+');
    }
    if (p.cas) {
        config.cas = args_into_cas(p.cas);
    }
    if (p.faves_only) {
        config.faves_only = true
    }
    if (p.sort) {
        config.sort = p.sort;
    }
    if (p.show_unlisted) {
        config.show_unlisted = 'yes'
    }
    if (p.reverse) {
        config.reverse = true
    }
    if (p.codes) {
        const split_codes = p.codes.split("+");
        if (split_codes.length > 0) {
            config.codes = split_codes;
        }
    }

    if (Object.keys(config).length > 0) return {
        offset: config.offset || 0,
        limit: config.limit || 40,
        term: config.term || null,
        categories: config.categories || [],
        cas: config.cas || { 'ambient': { key: 'ambient', comp: "=", val: "true" } },
        tick: config.cas || 0,
        stock: config.stock || true,
        defined_sets: config.defined_sets || [],
        faves_only: config.faves_only || false,
        sort: config.sort || 'description',
        show_unlisted: config.show_unlisted || false,
        codes:  config.codes || [],
    };
    return null;
}

const paramsFromConfig = c => {
    let args = [];
    if ((c.offset || 0) > 0) {
        args.push(`offset=${c.offset}`);
    }
    if (c.limit) {
        args.push(`limit=${c.limit}`);
    }
    if ((c.categories || []).length > 0) {
        args.push(`categories=${c.categories.join("%2B")}`)
    }
    if (c.term && c.term !== '') {
        args.push(`term=${c.term}`)
    }
    if (c.cas) {
        args.push(`cas=${cas_into_args(c.cas)}`)
    }
    if (c.stock) {
        args.push(`stock=1`);
    }
    if ((c.defined_sets || []).length > 0) {
        args.push(`defined_sets=${c.defined_sets.join("%2B")}`)
    }
    if (c.faves_only) {
        args.push(`faves_only=1`)
    }
    if (c.sort) {
        args.push(`sort=${c.sort}`);
    }
    if (c.reverse) {
        args.push(`reverse=1`);
    }
    if (c.codes) {
        if (c.codes.length > 0 ) {
            args.push(`codes=${c.codes.join("%2B")}`)
        }
    }
    if (c.show_unlisted) {
        args.push(`show_unlisted=1`)
    }
    if (args.length === 0) {
        return ''
    }
    return `?${args.join("&")}`
}

const UserWrapStore = props => {
    const {token, customer, user, ws_session} = useContext(UserContext);
    return <StoreWrapper ws_session={ws_session} token={token} customer={customer} user={user} {...props} />
}

const StoreWrapper = props => {
    const { register } = useContext(IfEventContext);
    const { children, token, customer, user, ws_session } = props;
    const customer_id_ref = useRef(customer?.id);

    useEffect(() => {
        customer_id_ref.current = customer?.id;
    }, [customer?.id])

    const [products, set_products] = useState({});
    //DS
    const [defined_set_list, set_defined_set_list] = useState([]);
    const [front, set_front] = useState(null);
    const [defined_set_config, set_defined_set_config] = useState({
        offset:0,
        limit: 50
    });
    const [listing_more, set_listing_more] = useState(false);
    const [defined_sets, set_defined_sets] = useState({});

    const [viewing_product_offers, set_viewing_product_offers] = useState(null);
    const viewProductOffers = product => set_viewing_product_offers(product);
    const [did_init_from_params, set_did_init_from_params] = useState(false);

    const getFront = async() => {
        const res = await fetch(`${api_url}/front`);
        const body = await res.json();
        const f = body.data;
        set_front(f);
    }

    useEffect(() => {
        if (!front) {
            getFront();
        }
    }, [front]);

    const listDefinedSets = async () => {
        const res = await fetch(`${api_url}/c/store/defined_set?offset=${defined_set_config.offset}&limit=${defined_set_config.limit}`);
        const body = await res.json();
        set_defined_set_list(body.data.ids);
    }

    const loadDefinedSets = async (ids) => {
        ids = ids.filter(x => !(x in defined_sets));
        if (ids.length > 0) {
            const res = await fetch(`${api_url}/c/defined_set/get?ids=${ids.join('%2B')}`);
            const body = await res.json();
            const data = body.data.data;
            set_defined_sets({
                ...defined_sets,
                ...data
            })
        }
    }

    useEffect(() => {
        loadDefinedSets(defined_set_list);
    }, [defined_set_list])

    useEffect(() => {
        listDefinedSets();
    }, [defined_set_config])

    const [config, set_config] = useState({
        offset: 0,
        limit:40,
        term: null,
        categories: [],
        cas: { 'ambient': { key: 'ambient', comp: "=", val: "true" } },
        tick: 0,
        stock: true,
        defined_sets: [],
        faves_only: false,
        sort: 'description',
        show_unlisted: false,
        codes: []
    });

    const [listing, set_listing] = useState([]);
    const [viewing_product, set_viewing_product] = useState(null);
    const viewing_product_ref = useRef(viewing_product?.id);
    const [cat_data, set_cat_data] = useState({});
    const [categories, set_categories] = useState([]);
    const [count, set_count] = useState(0);
    const [viewing_filters, set_viewing_filters] = useState(false)
    const [faves, set_faves] = useState([]);
    const [fave_count, set_fave_count] = useState(0);

    useEffect(() => {
        viewing_product_ref.current = viewing_product?.id;
    }, [viewing_product?.id]);

    const cust_ref = useRef(null);
    const user_ref = useRef(null);
    const products_loading = useRef(false);

    const viewNext = id => {
        let index = listing.map(x => x[0]).indexOf(id);
        if (index >= (listing.length - 10)) {
            if (listing.length < count) {
                listMore();
            }
        }
        if (index !== -1) {
            if (index === listing.length - 1) {
                set_viewing_product(products[listing[0][0]]);
            } else {
                set_viewing_product(products[listing[index + 1][0]]);
            }
        }
    }

    const loadCatData = async () => {
        const res = await fetch(`${api_url}/c/store/category_data`).then(data => data.json());
        set_cat_data(res.data);
    }

    const setFave = async (product_id, is_fave) => {
        if (customer) {
            if (is_fave) {
                set_faves(faves.concat([product_id]))
            } else {
                set_faves(faves => faves.filter(x => x !== product_id));
            }
            const resp = await fetch(`${api_url}/c/faves/set`, {
                method: "POST",
                headers: {
                    "Content-Type" :"application/json",
                    "authorization": `Bearer ${token}`,
                    "X-CUSTOMER-ID": customer.id,
                    "X-WS-SESSION": ws_session || undefined
                },
                body: JSON.stringify({product_id, is_fave})
            });
        }
    }

    useEffect(() => {
        if (customer) {
            getFaves();
            getFaveCount();
        } else {
            set_faves([])
            set_fave_count(0);
        }
    }, [customer])

    const getFaveCount = async() => {
        if (customer) {
            const resp = await fetch(`${api_url}/c/faves/count`, {
                headers: {
                    authorization: `Bearer ${token}`,
                    'X-CUSTOMER-ID': customer.id
                }
            });
            if (resp.ok) {
                const body = await resp.json();
                const count = body.data;
                set_fave_count(count);
            }
        }
    }

    const getFaves = async() => {
        if (customer) {
            const resp = await fetch(`${api_url}/c/faves/get`, {
                headers: {
                    authorization: `Bearer ${token}`,
                    'X-CUSTOMER-ID': customer.id
                }
            });
            if (resp.ok) {
                const body = await resp.json();
                const faves = body.data;
                set_faves(faves);
            }
        }
    }

    useEffect(() => {
        loadCatData();
    }, [])

    useEffect(() => {
        if (did_init_from_params) {
            listStore()
        }
    }, [config])

    useEffect(() => {
        getRequiredProducts(listing.map(x => x[0]));
    }, [listing, customer]);


    useEffect(() => {
        if (user) {
            const need = Object.values(products).filter(x => (x?.offers || []).length === 0).map(x => x.id);
            for (let i=0; i < need.length; i += product_chunk_size) {
                const chunk = need.slice(i, i+product_chunk_size);
                getProducts(chunk)
            }
        }
    }, [user])

    const getRequiredProducts = (ids) => {
        let is_refresh = false;
        if (customer?.id !== cust_ref.current) {
            cust_ref.current = customer?.id;
            is_refresh = true;
        }
        if (user?.id !== user_ref.current) {
            user_ref.current = user?.id;
            is_refresh = true;
        }
        let need = [];
        if (!is_refresh) {
            need = ids.filter(x => !(x in products));
        } else {
            need = ids;
        }

        for (let i=0; i < need.length; i += product_chunk_size) {
            const chunk = need.slice(i, i + product_chunk_size);
            getProducts(chunk);
        }
    }

    const getProducts = async (ids) => {
        if (ids.length === 0) return;

        const url = `${api_url}/c/product?ids=${ids.join('%2B')}${customer ? '&with_offers=1' : ''}`;
        const res = await fetch(url, {
            headers: {
                'X-CUSTOMER-ID': customer?.id,
                'authorization': token ? `Bearer ${token}` : undefined
            }
        });

        if (res.ok) {
            const body = await res.json();
            const new_prods = body.data.data;
            addToCache(new_prods);
            return new_prods

        } else {
            return {};

        }
    }

    const addToCache = new_prods => {
        if (viewing_product_ref.current) {
            var prods = Object.values(new_prods);
            for (var i=0; i < prods.length; i++) {
                if (prods[i].id === viewing_product_ref.current) {
                    set_viewing_product(prods[i]);
                }
            }
        }
        set_products(products => ({
            ...products,
            ...new_prods
        }))
    }

    const listMore = async() => {
        set_listing_more(true);
        const params_text = paramsFromConfig({...config, offset: listing.length, limit: 24});
        const url = `${api_url}/c/store/list_by_offer${params_text}`;
        const res = await fetch(url, {
            method: "GET",
            headers: {
                "X-CUSTOMER-ID": customer ? customer.id : undefined,
                'authorization': `Bearer ${token}`
            }
        }).then(data => data.json());
        set_listing(listing.concat(res.data.ids));
        set_listing_more(false);
    }

    const listStore = async() => {
        const params_text = paramsFromConfig(config);
        const url = `${api_url}/c/store/list_by_offer${params_text}`;
        const res = await fetch(url, {
            method: "GET",
            headers: {
                "X-CUSTOMER-ID": customer?.id,
                'authorization': `Bearer ${token}`
            }
        }).then(data => data.json());

        set_count(res.data.count);
        set_listing(res.data.ids);
    }

    const getCategories = async(config) => {
        const res = await fetch(`${api_url}/c/store/categories${paramsFromConfig(config)}`, {
            headers: {
                'X-CUSTOMER-ID': customer?.id,
                'authorization': `Bearer ${token}`
            }
        }).then(data => data.json());
        console.log(res.data);
        set_categories(res.data);
    }

    const setStoreParams = (params) => {
        set_config({
            ...config,
            ...params
        })
    }

    const reset = () => set_config({
        offset: 0,
        limit: 40,
        term: null,
        categories: [],
        cas: { 'ambient': { key: 'ambient', comp: "=", val: "true" } },
        tick: 0,
        defined_sets: [],
        stock: true,
        faves_only: false,
        sort: 'description',
        show_unlisted: false,
        codes: []
    })

    const viewProd = (prod) => { 
        Keyboard.dismiss();
        set_viewing_product(prod); 
    }
    const viewFilters = open => { 
        Keyboard.dismiss();
        set_viewing_filters(open);
     }

     const getProduct = async (id) => {
        if (id in products) return products[id];
        const new_prod = await getProducts([id]);
        return new_prod[id] || null;
     }

     const refreshImage = async(id) => {
        const res = await fetch(`${api_url}/r/product/find_image?ids=${id}`, {
            method:"GET",
            headers: {
                "Accept": "application/json",
                authorization: `Bearer ${token}`
            }
        })
        if (res.ok) {
            const body = await res.json();
            const found = body.data;
            if (found) {
                return true
            }
        }
        return false;
     }

     const pushProduct = async instr => {
        if (!user?.is_rep) return
        register('push_product', instr);
        const resp = await fetch(`${api_url}/r/product/push`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${token}`
            },
            body: JSON.stringify(instr)
        });

        if (resp.ok) {
            const d = await resp.json();
            const new_prod = d.data;
           insertProductWithoutTouchingOffers(new_prod);
        } else {
            const t = await resp.text();
            console.log(t);
        }
     }

     const insertProductWithoutTouchingOffers = product => {
        if (product.id === viewing_product_ref.current) {
            set_viewing_product(product);
        }
        set_products(prods => {
            const id = product.id;
            const old_prod = prods[id] || {};

            const to_add = {
                ...product,
                offers: old_prod.offers || []
            };
            

            return {
                ...prods,
                [id]: to_add
            }
        })
     }

     const inject_faves = (customer_id, faves) => {
        if (customer_id === customer_id_ref.current) {
            set_faves(faves);
        }
    }

    return (
        <StoreContext.Provider value={{
            products,
            config,
            listing,
            viewing_product,
            cat_data,
            categories,
            count,
            viewing_filters,
            defined_set_list,
            front,
            defined_sets,
            listing_more,
            faves,
            fave_count: faves.length,
            viewing_product_offers,
            actions: {
                setFave,
                viewProductOffers,
                inject_faves,
                insertProductWithoutTouchingOffers,
                getProducts,
                listDefinedSets,
                listStore,
                getCategories,
                viewFilters,
                viewProd,
                reset,
                setStoreParams,
                listMore,
                getRequiredProducts,
                getProduct,
                refreshImage,
                viewNext,
                pushProduct,
                set_did_init_from_params
            }
        }}>
            {children}
        </StoreContext.Provider>
    )
}

export default UserWrapStore;